import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import accountmanager from '../assets/images/account-manager-icon.png';
import convenienceflexible from '../assets/images/convenience-flexibility.png';
import logntermleas from '../assets/images/long-term-leasing.png';
import trainingperiodic from '../assets/images/periodic-training.png';
import salesconsultant from '../assets/images/sales-consultant.png';
import vehiclemodified from '../assets/images/vehicular-modifications.png';

class KeyPointDollar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a mt-0">
              <div className="">
                <div className="text-center pad-30">
                  <div className="row" id="id2">
                    <div className="col-12">
                      <div className="pickup-location-box" id="id2">
                        <h5 className="font-bold">
                          Key points that could define Dollar Oman as your total mobility partner
                        </h5>
                        <span className="mt-4 mt-md-0">
                          Our vehicles are modern, reliable & adaptable, making us a wise choice for
                          businesses that involve transportation of expensive and perishable goods.
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={salesconsultant} alt="Sales Consultant" />
                      <h6>
                        <b>{t('Sales Consultants.1')}</b>
                      </h6>
                      <span>
                        Sales Consultants that support you with scalable vehicle acquisition and
                        management solutions ensuring significant cost savings, reduced
                        administrative hassles, increased productivity, and risk mitigation within
                        your organization.
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={convenienceflexible} alt="Convenience and Flexibility" />
                      <h6>
                        <b>{t('Convenience & Flexibility.1')}</b>
                      </h6>
                      <span>
                        Our corporate leasing works on a pay-for-usage model, rather than fleet
                        ownership. This allows our clients to use vehicles, based on their
                        requirements with simple straightforward lease rentals, while not having to
                        worry about the hassles of fleet management, servicing, insurance, damage
                        repair, registration rentals, replacement vehicles, and end-of-term sales.
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={vehiclemodified} alt={'Customizable Vehicular Modification'} />
                      <h6>
                        <b>Customizable vehicular modifications</b>
                      </h6>
                      <span>
                        Customizable vehicular modifications for business-specific requirements:
                        Based on the needs of a large number of our clients, we have expanded and
                        increased our focus on specialized vehicles, targeted towards the
                        transport/logistics/distribution companies and commercial fleet owners in
                        Oman. We now offer these vehicles on a long-term lease as per your
                        requirements.
                      </span>
                    </div>

                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={logntermleas} alt={'Long Term Leasing'} />
                      <h6>
                        <b>Long-term leasing</b>
                      </h6>
                      <span>Long-term leasing options of up to 5+ years.</span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={accountmanager} alt={'Dedicated Manager'} />
                      <h6>
                        <b>Dedicated Account Managers</b>
                      </h6>
                      <span>
                        Our team moves with your team, 24x7. Hassle-free interactions, no repeated
                        paperwork, priority agile approach.
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={trainingperiodic} alt={'Periodic Training'} />
                      <h6>
                        <b>Periodic training</b>
                      </h6>
                      <span>
                        Periodic training & frequent in-house workshops for vehicle drivers and
                        other involved human resources, conducted in all Oman.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(KeyPointDollar)), KeyPointDollar);
