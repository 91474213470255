import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import exploreFleetPart1 from '../assets/images/Business/1488301004.jfif';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import BrandSlider from '../Hire/BrandSlider';
import VehicleClassifications from '../Hire/VehicleClassifications';

class ExploreFleet extends Component {
  componentDidMount = () => {
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Oman Car Rental Fleets | Budget Car Hire at Dollaroman</title>
          <link rel="canonical" href="https://www.dollaroman.com/explore-fleet" />
          <meta
            name="description"
            content="Find the perfect car for your Oman trip! Our rental fleets include versatile options for all travel needs, ensuring a smooth and enjoyable experience."
          ></meta>
          <meta
            property="og:title"
            content="Oman Car Rental Fleets | Budget Car Hire at Dollaroman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/explore-fleet"></meta>
          <meta
            property="og:description"
            content="Find the perfect car for your Oman trip! Our rental fleets include versatile options for all travel needs, ensuring a smooth and enjoyable experience."
          ></meta>
        </Helmet>

        <ListingHeader />

        <div className="minH100 exploreFleet">
          <section className="py-5 bg-grey">
            <div className="container">
              <div className="border-titlec-content mb-5">
                <h2 className="mb-1">Our Fleet. Your Keys.</h2>
                <p className="font-18 text-grey">
                  Choose your wheels from Oman’s Largest & Widest Rental Fleet.
                </p>
              </div>
              <div className="pb-5">
                <div className="row align-items-center">
                  <div className="col-lg-5 col-12">
                    <img src={exploreFleetPart1} className="rounded-xl" alt={'Explore Fleet'}></img>
                  </div>
                  <div className="col-lg-7 col-12  mt-4 mt-lg-0">
                    <div className="oddEven-content">
                      <h3 className="font-24">
                        Experience only the best at your ultimate car rental destination
                      </h3>
                      <p className="font-18 text-grey pt-3">
                        {' '}
                        At Dollar Car Rental Oman, it is easy to find a rental car that suits your
                        budget and your style.{' '}
                      </p>
                      <p className="font-18 text-grey pt-3">
                        {' '}
                        Dollar offers a large selection of high-quality, well-maintained, and latest
                        models of rental vehicles designed to make getting to your destination
                        comfortable and fun. All vehicles in our fleet are non-smoking, and each
                        year we add more and more fuel-efficient vehicles, which means even more
                        savings for you. In Oman, Dollar Car Rental offers a wide range of great
                        rental cars that includes SUVs, sedans, hatchbacks, economy & luxury
                        rentals.{' '}
                      </p>
                      <p className="font-18 text-grey pt-3">
                        No matter what kind of vehicle fits your needs, Dollar has just what you’re
                        looking for at a great rate, backed by excellent service.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row pt-5  align-items-center">
                  <div className="col-lg-6 col-12 order-1 order-lg-0 mt-4 mt-lg-0">
                    <div className="oddEven-content">
                      <p className="font-18 text-grey">
                        Feel free to reach out to us for the season’s latest competitive rates,
                        offers, and deals from Dollar Car Rental Oman towards a vehicle of your
                        choice and budget.{' '}
                      </p>
                      <p className="font-18 text-grey pt-3">
                        Not sure which vehicle model to go for? Look for the detail indicators
                        against each vehicle listing to help understand its seating capacity,
                        luggage capacity, engine specs, auto/manual, and more. Nevertheless, our
                        representatives would be happy to assist you in finding your best
                        pocket-friendly fit.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <img
                      src={exploreFleetPart1}
                      className="rounded-xl"
                      alt="Dollar Explore Fleet"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <VehicleClassifications className="bg-grey" />
        </div>

        <br />

        <BrandSlider />

        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(ExploreFleet);
