import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import starRating from '../assets/images/star-rating.svg';

class MonthlyTestimonials extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="monthlyTestimonial-section padding-100">
          <div className="container">
            <div className="titleBorderWrap">
              <h2>Testimonials</h2>
              {/* <p></p> */}
            </div>
            <div className="monthly-card-blog-left-row card-blog-left-row">
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="monthlyTestimonial-Boxslider-wrap">
                    <div className="monthlyTestimonial-Boxslider">
                      <div className="starRating-wrap">
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                      </div>
                      <p>
                        Wonderful experience, love the vehicle super clean and neat. Had the best
                        time with my family a great rental experience.
                      </p>
                      <b>- Zara</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="monthlyTestimonial-Boxslider-wrap">
                    <div className="monthlyTestimonial-Boxslider">
                      <div className="starRating-wrap">
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                      </div>
                      <p>
                        Good value for money on long term car rental. Cars are nice and clean as
                        well as good after sales service. Keep it up!
                      </p>
                      <b>- Allan</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-blog-left-col">
                <div className="card-blog-left-box">
                  <div className="monthlyTestimonial-Boxslider-wrap">
                    <div className="monthlyTestimonial-Boxslider">
                      <div className="starRating-wrap">
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                        <img src={starRating} alt="Star" />
                      </div>
                      <p>
                        I recently had an incredible experience with Dollar Car Rental Oman during
                        my trip to Salalah. I traveled to Salalah and decided to book with Dollar,
                        and, it was fantastic from start to finish! The booking process was a
                        breeze, and when I picked up the car, I was thrilled to find out that it was
                        a brand-new model. Not only that, but the car was spotlessly clean and in
                        excellent condition. The team was incredibly friendly and helpful. After
                        such an amazing experience, I highly recommend giving Dollar Rental Car a
                        try for your upcoming trips.
                      </p>
                      <b>- Kaizer</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(MonthlyTestimonials);
