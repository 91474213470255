import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import LessFineNextArrow from '../Hire/LessFineNextArrow';
import LessFinePrevArrow from '../Hire/LessFinePrevArrow';
import '../assets/css/slick.css';
import { contentInstance } from '../axios/axiosInstance';
import NewsTile from './NewsTile';

class NewsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
    };
  }
  componentDidMount() {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    contentInstance
      .get(`news/allUi?countryCode=${code}`)
      .then((response) => {
        this.setState({
          newsList: response.data.result,
        });
      })
      .catch((error) => {});
  }

  onViewAllClick = () => {
    localStorage.setItem('newsid', 'null');
    this.props.history.push('/news');
  };

  onNewsClick = (newsId) => {
    localStorage.setItem('newsid', newsId);
    this.props.history.push(`/news/${newsId}`);
  };

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      centerPadding: '60px',
      centerMode: true,
      prevArrow: <LessFinePrevArrow />,
      nextArrow: <LessFineNextArrow />,
      rtl: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: '0px',
            centerMode: true,
          },
        },
      ],
    };

    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Best Vehicle Rental Offers</title>
          <meta
            name="description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com"></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta name="title" content="Dollar Car Rental Oman | Best Vehicle Rental Offers"></meta>
          <meta
            name="description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="twitter:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
        </Helmet>
        <section className="what-new-sectio ptb-80">
          <div className="container">
            <div className="new-oddEven-title-wrap">
              <div className="new-oddEven-title-left">
                <h2>{t('What’s New?.1')}</h2>
                <p>{t('All the latest from Oman’s favorite car rental agency')}</p>
              </div>
              <div className="new-oddEven-title-right">
                <a className="read-more-link" title="View More" onClick={this.onViewAllClick}>
                  {t('View All.1')}
                </a>
              </div>
            </div>
            <div className="what-new-wrap-new">
              <div className="row">
                {' '}
                {this.state.newsList.map((news) => {
                  return <NewsTile key={news.NewsID} news={news} />;
                })}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(NewsIndex)), NewsIndex);
