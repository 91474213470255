import React, { Component } from 'react';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import abudhabi from '../assets/images/abudhabi.png';
import dubailogo from '../assets/images/dubailogo.png';
import sideImgPartner from '../assets/images/partner.png';

class OurPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 0,
    };
  }

  onPartnerChange = (mode) => {
    this.setState({
      mode: mode,
    });
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.onPartnerChange(params.index);
  }

  render() {
    const isAirlinePartners = this.state.mode == 0;
    const isHotelPartners = this.state.mode == 1;
    return (
      <>
        <ListingHeader></ListingHeader>
        <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a ">
              <div className="">
                <div className="row flex-reversea">
                  <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                    <div className="pickup-location-box">
                      <h5 className="font-bold mb-3 mt-2 ">Our Partners</h5>
                      <div className="pickup-location-flex">
                        <div className="pickup-location-flex-content">
                          <h3 className="mb-4">Lorem Ipsum is that it has a more-or-less</h3>
                          <span>
                            Lorem Ipsum is that it has a more-or-less normal distribution of
                            letters, as opposed to using 'Content here, content here', making it
                            look like readable English.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                    <img src={sideImgPartner} alt="Our Partner" className="img-fluid rounded-xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="box-shadow-white-box-section  grey-bg py-4" id="partner-a">
          <div className="">
            <div className="container-fluid">
              <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className={this.state.mode == 0 ? 'nav-link active' : 'nav-link'}
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    onClick={() => this.onPartnerChange(0)}
                  >
                    Airline Partners
                  </a>
                </li>
                <li className="nav-item mr-4 ml-4">
                  <a
                    className={this.state.mode == 1 ? 'nav-link active' : 'nav-link'}
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    onClick={() => this.onPartnerChange(1)}
                  >
                    Hotel Partners
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={this.state.mode == 2 ? 'nav-link active' : 'nav-link'}
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#pills-contact"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Credit card partners
                  </a>
                </li>
                <li className="nav-item mr-4 ml-4">
                  <a
                    className={this.state.mode == 3 ? 'nav-link active' : 'nav-link'}
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#pills-contact1"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Others
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={this.state.mode == 4 ? 'nav-link active' : 'nav-link'}
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#pills-contact2"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Alliance Partners
                  </a>
                </li>
              </ul>
              <div className="pt-0 pb-2">
                <hr />
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="box-shadow-white-box-section">
                  <div className="container-fluid">
                    <div className="box-shadow-white-box">
                      {isAirlinePartners ? (
                        <>
                          <div className="row">
                            <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                              <div className="img-details-wrap card">
                                <img src={dubailogo} alt="Dollar Logo" height="140" width="" />
                              </div>
                            </div>

                            <div className="col-xl-9 col-md-6 col-sm-12 col-12 mt-md-0 mt-4">
                              <h5 className="font-bold mb-1 mt-3">Oman Airports</h5>

                              <p className=" mt-4 opacity-50 text-black">
                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                letters, as opposed to using 'Content here, content here', making it
                                look like readable English. Many desktop publishing packages and web
                                page editors now
                              </p>
                              <a href="#" className="linka">
                                More Information <i className="arrow right"></i>
                              </a>
                            </div>
                          </div>
                          <hr className="pt-2 pb-2" />
                        </>
                      ) : null}
                      {isHotelPartners ? (
                        <>
                          <div className="row">
                            <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                              <div className="img-details-wrap card">
                                <img src={abudhabi} alt="" height="140" width="" />
                              </div>
                            </div>

                            <div className="col-xl-9 col-md-6 col-sm-12 col-12 mt-md-0 mt-4">
                              <h5 className="font-bold mb-1 mt-3">Abu Dhabi Airport</h5>

                              <p className=" mt-4 opacity-50 text-black">
                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                letters, as opposed to using 'Content here, content here', making it
                                look like readable English. Many desktop publishing packages and web
                                page editors now
                              </p>
                              <a href="#" className="linka">
                                More Information <i className="arrow right"></i>
                              </a>
                            </div>
                          </div>
                          <hr className="pt-2 pb-2" />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ListingFooter></ListingFooter>
      </>
    );
  }
}

export default OurPartner;
