import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import Switch from 'react-input-switch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import { getCurrency, getCustomerId, getLanguage } from '../Common/Util';
import VehicleFeatureBoxVertical from '../Common/VehicleFeatureBoxVertical';
import RentalPolicies from '../ListingPage/RentalPolicies';
import ViewInsuranceDetails from '../ListingPage/ViewInsuranceDetails';
import dollarExpress from '../assets/images/DollarExpress1.png';
import protection from '../assets/images/choose-your-protection-icon.svg';
import documentattachments from '../assets/images/document-attachments-icon.svg';
import driverdetails from '../assets/images/driver-details-icon.svg';
import infothin from '../assets/images/iconmonstr-info-thin.svg';
import noResultImage from '../assets/images/icons8-spinner.gif';
import savetimeatcounter from '../assets/images/save-time-at-the-counter.svg';
import { bookingInstance, userInstance } from '../axios/axiosInstance';
import ViewExtrasDetails from './MonthlyViewExtrasDetails';
import ViewIncludeLongText from './MonthlyViewIncludeLongText';
var qs = require('qs');

class MonthlyListingPage3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceList: [],
      ancillaryList: [],
      countryList: [],
      allowedKm: '',
      currencyCode: '',
      firstTimeLoad: true,
      isChecked: false,
      seat: 1,
      selectedInsurnace: '',
      selectedAncillary: '',
      PAI: false,
      SCDW: false,
      CDW: false,
      showInsuranceViewDetails: false,
      showRentalDetails: false,
      showExtrasDetails: false,
      extrasDesriptionToShow: '',
      insuranceDesriptionToShow: '',
      selectedAncillaryList: [],
      includeSection: [],
      LongTextShow: '',
      RateType: '',
      RateTypeAncillary: '',
      reservationForBooking: '',
      showUpgrade: false,
      calculationDetails: [],
      loading: false,
      errorMessage: '',
      DriverDetails: [],
      dob: moment(Date())._d,
      CreatedOn: Date().toLocaleString(),
      isCheked: false,
      firstname: '',
      lastname: '',
      email: '',
      contactno: '',
      airlinename: '',
      flightnumber: '',
      showDocAttachement: 0,
      nationalitycode: '',
      nationalityName: '',
      selectedFile: null,
      AttachmentID: '',
      passportSelectedFile: null,
      PassportAttachmentID: '',
      loadingText: '',
      selectedAgeGreaterThan22: '',
      childSeat: '0',
      showSCDWDetails: false,
      allowToSubmit: false,
      isPayOnline: false,
      showUpgrade: false,
      hasUpgrade: false,
      isUpgrade: false,
      getSCDWInsuranceData: [],
      isDocumentUploadMandatory: false,
      isTerminationPolicyChecked: false,
      subscriptionTermsConditionsShortText: '',
      subscriptionTermsConditionsLongText: '',
      showSubscriptionTermsDetails: false,
      Shukran: '',
      bid: '',
      SDW: [],
      deleteSpinner: null,
      UpgradPlanId: null,
    };
  }

  async componentDidMount() {
    this.state.currencyCode = getCurrency();
    this.getCountryList();

    try {
      const result = await this.getReservationForBookingSection();

      if (this.props.searchParam?.ShukranId?.length === 16) {
        const Shukran = this.props.searchParam.ShukranId;
        this.setState({ Shukran });
        this.props.searchParam.ShukranId = '';
      }

      if (result) {
        Promise.all([
          this.getAllInsurance(),
          this.getAllAncillary(),
          this.getRateCalculation(
            this.state.PAI,
            this.state.CDW,
            this.state.SCDW,
            this.state.selectedAncillaryList,
          ),
          this.GetSubscriptionTermsCondition(),
        ]);
        this.state.selectedAgeGreaterThan22 = 0;
      }
    } catch (error) {}
  }

  getReservationForBookingSection = async () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    let searchParam = this.props.searchParam;
    searchParam.IPLocation = {
      IPAddress: this.props.iPAddress,
      Country_code: this.props.customerCountryCode,
      Country_name: this.props.customerCountryName,
    };

    searchParam.CheckInOn = '';
    try {
      const response = await bookingInstance.post(`forBooking?countryCode=${code}`, searchParam);

      const { data } = response.data.result;

      this.setState({
        reservationForBooking: data,
        includeSection: data.BookingIncluded,
        bid: data.Id,
      });

      if (
        this.state.reservationForBooking.Renter?.FirstName != null &&
        this.state.reservationForBooking.Renter?.FirstName != ''
      ) {
        // In case of login or existing reservation ID, we will get Renter details.
        this.state.firstname = this.state.reservationForBooking.Renter.FirstName;
        this.state.lastname = this.state.reservationForBooking.Renter.LastName;
        this.state.email = this.state.reservationForBooking.Renter.EmailId;
        this.state.contactno = this.state.reservationForBooking.Renter.ContactNo;
        this.state.nationalitycode =
          this.state.reservationForBooking.Renter.CountryState?.NationalityCode;
        this.state.nationalityName =
          this.state.reservationForBooking.Renter.CountryState?.NationalityName;
        this.state.dob = moment(this.state.reservationForBooking.Renter.BirthDate)._d;
        this.setState({ selectedAgeGreaterThan22: true });
        this.state.airlinename = this.state.reservationForBooking.AirlinesName;
      }

      this.props.searchParam.CheckInOn = this.state.reservationForBooking.InDetail.Timestamp;
      this.props.additionalParam.dropOffLocationName =
        this.state.reservationForBooking.OutDetail.Name;
      this.props.additionalParam.dropOffLocationBranchDescription =
        this.state.reservationForBooking.OutDetail.BranchDescription;
      this.props.additionalParam.dropOffLocationAddress =
        this.state.reservationForBooking.OutDetail.Address;
      this.props.additionalParam.dropOffLocationMessage =
        this.state.reservationForBooking.OutDetail.BranchMessage;

      this.props.additionalParam.pickupLocationName =
        this.state.reservationForBooking.InDetail.Name;
      this.props.additionalParam.pickupLocationBranchDescription =
        this.state.reservationForBooking.InDetail.BranchDescription;
      this.props.additionalParam.pickupLocationAddress =
        this.state.reservationForBooking.InDetail.Address;
      this.props.additionalParam.pickupLocationMessage =
        this.state.reservationForBooking.InDetail.BranchMessage;
      if (this.props.searchParam.ModifiedReservationID != '00000000-0000-0000-0000-000000000000') {
        // Get & Bind list of ancillaries & insurance selected in previous reservation.
        if (this.state.reservationForBooking.RentalIn.IsPAISelected == 1) {
          this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 0; // PAI value is 0
          this.state.PAI = true;
        }

        if (this.state.reservationForBooking.RentalIn.IsCDWSelected == 1) {
          this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 1; // CDW value is 0
          this.state.CDW = true;
        }

        if (this.state.reservationForBooking.RentalIn.IsSDWSelected == 1) {
          this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 2; // SCDW value is 0
          this.state.SCDW = true;
        }
        let ancillaryList = this.state.ancillaryList;

        this.state.reservationForBooking.RentalIn.AncillaryIn.map((ancillary) => {
          this.state.selectedAncillary = this.state.selectedAncillary + ',' + ancillary.AncillaryID;
          this.state.selectedAncillaryList.push({
            AncillaryID: ancillary.AncillaryID,
            Qty: ancillary.Qty,
          });
          this.setState({ selectedAncillary: this.state.selectedAncillary });
          this.setState({ selectedAncillaryList: this.state.selectedAncillaryList });
        });

        this.setState({ ancillaryList: ancillaryList });
      }
      return true;
    } catch (error) {
      if (error)
        this.setState({
          firstTimeLoad: false,
          errorMessage: 'Something went wrong. Please try again later.',
        });
    }
  };

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: LongText,
    });
  };

  getAllAncillary = () => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`ancillary/${this.state.bid}?countryCode=${code}`, searchParam)
      .then((response) => {
        const responseData = response.data.result.data;
        this.setState({
          ancillaryList: responseData,
        });

        let selectedAncillary = this.state.selectedAncillary;
        let selectedAncillaryList = this.state.selectedAncillaryList;

        responseData.forEach((item) => {
          if (item.SelectionType == 1 && item.IsCompulsoryOnRental == true) {
            selectedAncillary = selectedAncillary + ',' + item._id;
            selectedAncillaryList.push({ AncillaryID: item._id, Qty: 1, apiCALL: false });
          }
          if (item.SelectionType == 2 && item.IsCompulsoryOnRental == true) {
            selectedAncillaryList.push({ AncillaryID: item.item_id, Qty: 1, apiCALL: false });
          }
        });
        this.setState({ selectedAncillary: selectedAncillary });
        this.setState({ selectedAncillaryList: selectedAncillaryList });
        this.getRateCalculation(
          this.state.PAI,
          this.state.CDW,
          this.state.SCDW,
          selectedAncillaryList,
        );
      })
      .catch((error) => {});
  };

  getSelectedAgeGreaterThan22 = (e, type) => {
    if (this.state.selectedAgeGreaterThan22 == 1) {
      this.state.selectedAgeGreaterThan22 = 0;
      this.setState({ selectedAgeGreaterThan22: false });
    } else {
      this.state.selectedAgeGreaterThan22 = 1;
      this.setState({ selectedAgeGreaterThan22: true });
    }
  };

  getSelectedInsurance = (type) => {
    let selectedInsurnace = this.state.selectedInsurnace;
    if (!selectedInsurnace.includes(type)) {
      //Check
      selectedInsurnace = selectedInsurnace + ',' + type;
      this.setState({ selectedInsurnace: selectedInsurnace });

      //Call Get Rate Calculation
      let typeProt = '';
      if (type == 0) {
        typeProt = 'PAI';
        this.getRateCalculation(
          true,
          this.state.CDW,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 1) {
        typeProt = 'CDW';

        //Check if CDW is selected and uncheck it
        this.setState({ SCDW: false });

        let newSelectedInsurnaceArray = [];
        let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
          if (2 == item) {
          } else {
            newSelectedInsurnaceArray.push(item);
          }
        });
        let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
        this.setState({ selectedInsurnace: newSelectedInsurnace });
        this.getRateCalculation(this.state.PAI, true, false, this.state.selectedAncillaryList);
      } else if (type == 2) {
        typeProt = 'SCDW';

        //Check if CDW is selected and uncheck it
        this.setState({ CDW: false });

        let newSelectedInsurnaceArray = [];
        let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
          if (1 == item) {
          } else {
            newSelectedInsurnaceArray.push(item);
          }
        });
        let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
        this.setState({ selectedInsurnace: newSelectedInsurnace });
        this.getRateCalculation(this.state.PAI, false, true, this.state.selectedAncillaryList);
      }

      this.setState({
        [typeProt]: true,
      });
    } else {
      //Uncheck
      let typeProt = '';
      let newSelectedInsurnaceArray = [];
      let selectedInsurnaceArray = selectedInsurnace.split(',').forEach((item) => {
        if (item == type) {
        } else {
          newSelectedInsurnaceArray.push(item);
        }
      });
      let newSelectedInsurnace = newSelectedInsurnaceArray.join(',');
      this.setState({ selectedInsurnace: newSelectedInsurnace });

      if (type == 0) {
        typeProt = 'PAI';
        this.getRateCalculation(
          false,
          this.state.CDW,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 1) {
        typeProt = 'CDW';
        this.getRateCalculation(
          this.state.PAI,
          false,
          this.state.SCDW,
          this.state.selectedAncillaryList,
        );
      } else if (type == 2) {
        typeProt = 'SCDW';
        this.getRateCalculation(
          this.state.PAI,
          this.state.CDW,
          false,
          this.state.selectedAncillaryList,
        );
      }

      this.setState({
        [typeProt]: false,
      });
    }
  };

  getRateCalculation = (isPIA, isCDW, isSCDW, selectedAncillaryList) => {
    selectedAncillaryList = selectedAncillaryList.filter((item) => item.apiCALL !== false);
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    // searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.IsPAISelected = isPIA;
    searchParam.IsCDWSelected = isCDW;
    searchParam.IsSDWSelected = isSCDW;
    searchParam.AncillaryIn = selectedAncillaryList;
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`calculation/${this.state.bid}?countryCode=${code}`, searchParam)
      .then((response) => {
        this.setState({
          calculationDetails: response.data.result.data,
          deleteSpinner: null,
        });

        if (this.state.calculationDetails != null) {
          this.setState({
            firstTimeLoad: false,
            isDocumentUploadMandatory: this.state.calculationDetails.IsDocumentUploadMandatory,
          });
        }
      })
      .catch((error) => {});
  };

  getSelectedVehicleModelID = () => {
    if (
      this.props.additionalParam.originalVehicleModelID == '00000000-0000-0000-0000-000000000000'
    ) {
      return this.props.selectVehicleForBooking.VehicleModelID;
    } else {
      return this.props.additionalParam.originalVehicleModelID;
    }
  };

  getSelectedTarrifGroupID = () => {
    if (
      this.props.additionalParam.originalTariffGroupID == '00000000-0000-0000-0000-000000000000'
    ) {
      return this.props.selectVehicleForBooking.TariffGroup.TariffGroupID;
    } else {
      return this.props.additionalParam.originalTariffGroupID;
    }
  };

  getUpgradeVehicleModelID = () => {
    if (
      this.props.additionalParam.originalVehicleModelID == '00000000-0000-0000-0000-000000000000'
    ) {
      return this.props.additionalParam.originalVehicleModelID;
    } else {
      return this.props.selectVehicleForBooking.VehicleModelID;
    }
  };

  getAllInsurance = () => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.getSelectedVehicleModelID();
    searchParam.TariffGroupID = this.getSelectedTarrifGroupID();
    searchParam.CustomerID = getCustomerId();
    searchParam.UpgradeModelId = this.getUpgradeVehicleModelID();

    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`insurance/${this.state.bid}?countryCode=${code}`, searchParam)
      .then((response) => {
        this.setState(
          {
            insuranceList: response.data.result.data,
          },
          () => {
            const sdwInsurance = response.data.result.data.filter(
              (item) => item.ShortName?.toLowerCase() === 'sdw',
            );
            if (sdwInsurance.length > 0) {
              this.setState({ SDW: sdwInsurance });
            }
          },
        );
      })
      .catch((error) => {});
  };

  onChangeCountryCode = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    this.setState({
      nationalitycode: e.target.value,
      nationalityName: label,
    });
  };

  onChangeValue = (e) => {
    const isCheked = this.state.isCheked;
    this.setState({
      isCheked: !isCheked,
    });
    if (e.target.value == 'atcounter') {
      this.setState({ showDocAttachement: 0 });
    } else {
      this.setState({ showDocAttachement: 1 });
    }
  };

  onChangePickUpDate = (date) => {
    this.setState({ dob: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePhone = (value) => {
    this.setState({ contactno: value });
  };

  getSelectedAncillary = (id) => {
    let selectedAncillary = this.state.selectedAncillary;
    let selectedAncillaryList = this.state.selectedAncillaryList;

    if (!selectedAncillary.includes(id)) {
      //Check
      selectedAncillary = selectedAncillary + ',' + id;
      selectedAncillaryList.push({ AncillaryID: id, Qty: 1 });
      this.setState({ selectedAncillary: selectedAncillary });
      this.setState({ selectedAncillaryList: selectedAncillaryList });
      this.setState({
        [id]: true,
      });

      this.getRateCalculation(
        this.state.PAI,
        this.state.CDW,
        this.state.SCDW,
        selectedAncillaryList,
      );
    } else {
      //Uncheck

      let newSelectedAncillaryArray = [];

      let selectedAncillaryArray = selectedAncillary.split(',').forEach((item) => {
        if (item == id) {
        } else {
          newSelectedAncillaryArray.push(item);
        }
      });
      let newSelectedAncillary = newSelectedAncillaryArray.join(',');
      this.setState({ selectedAncillary: newSelectedAncillary });

      let newSelectedAncillaryList = [];
      this.state.selectedAncillaryList.forEach((item) => {
        if (item.AncillaryID == id) {
        } else {
          newSelectedAncillaryList.push(item);
        }
      });
      this.setState({ selectedAncillaryList: newSelectedAncillaryList });
      this.setState({
        [id]: false,
      });
      this.getRateCalculation(
        this.state.PAI,
        this.state.CDW,
        this.state.SCDW,
        newSelectedAncillaryList,
      );
    }
  };

  getSelectedTyp1Ancillary = (e) => {
    var selectedAncillaryList = this.state.selectedAncillaryList;
    var index = selectedAncillaryList.findIndex((an) => an.AncillaryID == e.target.id);
    const quantity = Number(e.target.value);

    if (index == -1 && quantity > 0) {
      selectedAncillaryList.push({
        AncillaryID: e.target.id,
        Qty: quantity,
      });
    } else {
      if (quantity == 0)
        // if qty is 0 then remove that from the selected list.
        selectedAncillaryList.splice(index);
      else selectedAncillaryList[index] = { AncillaryID: e.target.id, Qty: quantity };
    }

    this.setState({ selectedAncillaryList: selectedAncillaryList });
    this.getRateCalculation(
      this.state.PAI,
      this.state.CDW,
      this.state.SCDW,
      this.state.selectedAncillaryList,
    );
  };

  toogleViewExtrasPopUp = (showHide, extrasDesriptionToShow) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: extrasDesriptionToShow,
    });
  };

  ValidateDriverDetails = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });

    const valid = true;
    if (this.state.firstname == undefined || this.state.firstname === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the first name' });
      } else {
        //ar
        this.setState({
          errorMessage: 'يرجى ملء الاسم الأول',
        });
      }

      return false;
    }

    if (!this.state.selectedAgeGreaterThan22) {
      const errorMessage =
        lngParam === 'en' ? 'Age should be greater than 22' : 'يجب أن يكون العمر أكبر من 22';
      this.setState({ errorMessage });
      return false;
    }

    if (this.state.lastname == undefined || this.state.lastname === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the last name' });
      } else {
        //ar
        this.setState({
          errorMessage: 'الرجاء تعبئة اسم العائلة',
        });
      }

      return false;
    }
    if (this.state.contactno == undefined || this.state.contactno === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the contact no' });
      } else {
        //did
        this.setState({
          errorMessage: 'يرجى ملء أي اتصال',
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the email' });
      } else {
        //ar
        this.setState({
          errorMessage: 'الرجاء تعبئة البريد الإلكتروني',
        });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }
    if (this.state.dob == undefined || this.state.dob === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the date of birth' });
      } else {
        //ar
        this.setState({
          errorMessage: 'يرجى ملء دوب',
        });
      }

      return false;
    }
    if (this.state.showDocAttachement) {
      if (this.state.selectedFile == null || this.state.selectedFile.name == '') {
        this.setState({ errorMessage: 'Please upload driver`s documents' });
        return false;
      }
      if (this.state.passportSelectedFile == null || this.state.passportSelectedFile.name == '') {
        this.setState({ errorMessage: 'Please upload Drivers Passport' });
        return false;
      }
    }
    if (
      this.state.nationalityName == null ||
      this.state.nationalityName == '' ||
      this.state.nationalityName == 'Select' ||
      this.state.nationalityName == 'SELECT'
    ) {
      this.setState({ errorMessage: 'Please select nationality.' });
      return false;
    }
    if (!this.state.isTerminationPolicyChecked) {
      this.setState({ errorMessage: 'Please read and accept the Termination Policy' });
      return false;
    }

    this.setState({ errorMessage: '' });
    return valid;
  };

  getReservationObjet = (paymentStatus) => {
    if (this.state.selectedAgeGreaterThan22) {
      var dob = new Date();
      this.state.dob = new Date(dob.setFullYear(dob.getFullYear() - 23));
    } else {
      this.state.dob = new Date();
    }
    let data = {
      Id: this.state.bid,
      RentalIn: {
        CheckOutOn: this.props.searchParam.CheckOutOn,
        CheckOutBranchID: this.props.searchParam.CheckOutBranchID,
        DeliveryDetails: {
          Address: this.props.searchParam.DeliveryDetails.Address,
          StateName: this.props.searchParam.DeliveryDetails.StateName,
          StateCode: this.props.searchParam.DeliveryDetails.StateCode,
        },
        ShukranId: this.state.Shukran,
        IPLocation: {
          IPAddress: this.props.iPAddress,
          Country_code: this.props.customerCountryCode,
          Country_name: this.props.customerCountryName,
        },
        Daily: this.props.searchParam.Daily,
        VehicleModelID: this.props.searchParam.VehicleModelID,
        UpgradeModelId: this.getUpgradeVehicleModelID(),
        CustomerID: this.props.searchParam.CustomerID,
        ModifiedReservationID: this.props.searchParam.ModifiedReservationID,
        PaymentStatus: paymentStatus,
        IsCDWSelected: this.props.searchParam.IsCDWSelected,
        IsSDWSelected: this.state.SCDW,
        IsPAISelected: this.props.searchParam.IsPAISelected,
        AncillaryIn: this.props.searchParam.AncillaryIn,
        DriverAge: this.props.searchParam.DriverAge,
        UpgradPlanId: null,
      },
      Renter: {
        Id: this.props.searchParam.CustomerID,
        FirstName: this.state.firstname,
        LastName: this.state.lastname,
        ContactNo: `${this.state.contactno}`,
        EmailId: this.state.email,
        NationalityCode: this.state.nationalitycode,
        NationalityName: this.state.nationalityName,
        DrivingLicense: this.state.AttachmentID,
        Passport: this.state.PassportAttachmentID,
      },
      TotalTax: this.state.calculationDetails.TotalTax,
      TotalAmount: this.state.calculationDetails.TotalAmount,
    };

    return data;
  };

  toogleViewSCDWPopUp = () => {
    this.setState({
      showSCDWDetails: true,
    });
  };

  toogleViewSCDWHidePopUp = () => {
    this.setState({
      showSCDWDetails: false,
    });
  };

  onWithOutSCDWClick = () => {
    this.state.allowToSubmit = true;
    this.state.SCDW = false;
    if (this.state.isPayOnline) {
      this.onBookPayNowClick();
    } else {
      this.onBookPayLaterClick();
    }
  };

  onWithSCDWClick = () => {
    this.state.allowToSubmit = true;
    this.state.SCDW = true;
    this.state.selectedInsurnace = this.state.selectedInsurnace + ',' + 2;
    this.getRateCalculation(
      this.state.PAI,
      this.state.CDW,
      this.state.SCDW,
      this.state.selectedAncillaryList,
    );
    document.getElementById('BookingDetails').scrollIntoView({ behavior: 'smooth' });
  };

  onBookPayLaterClick = () => {
    if (this.ValidateDriverDetails()) {
      this.state.isPayOnline = false;
      if (
        this.state.allowToSubmit == false &&
        this.state.SCDW == false &&
        this.state.CDW == false
      ) {
        this.toogleViewSCDWPopUp();
        return;
      }

      this.setState({
        loading: true,
        loadingText: 'Creating the reservation',
      });

      var postData = this.getReservationObjet(0);
      bookingInstance
        .post('/create', postData)
        .then((response) => {
          const data = response.data.result.data;
          this.setState({
            DriverDetails: response.data,
          });
          this.setState({ loading: false, loadingText: '' });

          if (data.IsValid == true) this.props.history.push(`/reservationconfirm/${data.ID}/1`);
          else
            this.setState({
              errorMessage: 'Please try again or contact our customer care at 800 Dollar',
            });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            loadingText: '',
            errorMessage: 'Please try again or contact our customer care at 800 Dollar',
          });
        });
    } else {
      this.setState({ loading: false, loadingText: '' });
    }
  };

  onBookPayNowClick = () => {
    if (this.ValidateDriverDetails()) {
      this.state.isPayOnline = true;
      if (
        this.state.allowToSubmit == false &&
        this.state.SCDW == false &&
        this.state.CDW == false
      ) {
        this.toogleViewSCDWPopUp();
        return;
      }

      this.setState({
        loading: true,
        loadingText: 'Creating the reservation',
      });
      var data = this.getReservationObjet(1);
      bookingInstance
        .post('/create', data)
        .then((response) => {
          const data = response.data.result.data;

          if (data.IsValid == true) {
            if (data.PaymentUrl != undefined && data.PaymentUrl != null) {
              window.location = data.PaymentUrl;
            } else {
              this.props.history.push(`/reservationconfirm/${data.ID}/1`);
            }
          } else {
            this.setState({
              loading: false,
              loadingText: '',
              errorMessage: 'Please try again or contact our customer care at 800 Dollar',
            });
            this.setState({
              errorMessage: 'Please try again or contact our customer care at 800 Dollar',
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false, loadingText: '' });
        });
    } else {
      this.setState({ loading: false, loadingText: '' });
    }
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  //     File Upload
  onFileChange = (e) => {
    // Update the state
    this.setState({ selectedFile: e.target.files[0] });
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    bookingInstance
      .post('upload/ui', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.setState({ AttachmentID: response.data.ID });
      })
      .catch((error) => {});
  };

  onPassportFileChange = (e) => {
    // Update the state
    this.setState({ passportSelectedFile: e.target.files[0] });
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    userInstance
      .post('upload/ui', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.setState({ PassportAttachmentID: response.data.ID });
      })
      .catch((error) => {});
  };

  onPayNowSelected = () => {
    this.props.searchParam.PaymentStatus = 1;
    this.getRateCalculation();
  };

  toogleViewInsurancePopUp = (showHide, insuranceDescriptionToShow) => {
    this.setState({
      showInsuranceViewDetails: showHide,
    });
    this.setState({
      insuranceDesriptionToShow: insuranceDescriptionToShow,
    });
  };

  getCountryList = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .get(`countries?countryCode=${code}`)
      .then((response) => {
        this.setState({
          countryList: response.data.result,
        });
      })
      .catch((error) => {});
  };

  removeItem = (itemID, addProtection) => {
    this.setState({ deleteSpinner: itemID });
    const selectedAncillaryList = this.state.selectedAncillaryList.filter(
      (item) => item.AncillaryID !== itemID,
    );

    this.setState({ selectedAncillaryList });
    const protectionMapping = { pai: 'PAI', cdw: 'CDW', sdw: 'SCDW' };

    const protectionKey = protectionMapping[addProtection.toLowerCase()];

    if (protectionKey) {
      this.setState({ [protectionKey]: false }, () => {
        let newselectedInsurnace = '';
        if (this.state.PAI) {
          newselectedInsurnace = newselectedInsurnace + ',' + 0;
        } else if (this.state.CDW) {
          newselectedInsurnace = newselectedInsurnace + ',' + 1;
        } else if (this.state.SCDW) {
          newselectedInsurnace = newselectedInsurnace + ',' + 2;
        }

        this.setState({ selectedInsurnace: newselectedInsurnace });

        this.getRateCalculation(
          this.state.PAI,
          this.state.CDW,
          this.state.SCDW,
          selectedAncillaryList,
        );
        return;
      });
    }

    let { selectedAncillary } = this.state;
    let selectedArray = selectedAncillary.split(',');
    selectedArray = selectedArray.filter((item) => item !== itemID);
    selectedAncillary = selectedArray.join(',');
    if (selectedAncillary) {
      selectedAncillary = ',' + selectedAncillary + ',';
    }

    this.setState({ selectedAncillary });
    this.getRateCalculation(this.state.PAI, this.state.CDW, this.state.SCDW, selectedAncillaryList);
  };

  onTerminationPolicyClick = () => {
    this.setState({
      showSubscriptionTermsDetails: true,
    });
  };

  onTerminationPolicyChange = (e) => {
    let isChecked = this.state.isTerminationPolicyChecked;
    if (!isChecked) this.setState({ isTerminationPolicyChecked: !isChecked });
  };

  GetSubscriptionTermsCondition = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance.get(`termsConditions?countryCode=${code}&isDaily=false`).then((response) => {
      response = response.data.result;
      if (response.data != null) {
        this.setState({ subscriptionTermsConditionsShortText: response.data.ShortText });
        this.setState({
          subscriptionTermsConditionsLongText: response.data.TermsAndConditions,
        });
      }
    });
  };

  subscriptionTermsAndSubscription = (showHide) => {
    this.setState({
      showExtrasDetails: showHide,
      showSubscriptionTermsDetails: false,
    });
  };

  toogleUpgradeOptionPopup = (showHide) => {
    this.setState({ showUpgrade: showHide });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.firstTimeLoad ? (
          <div
            className="nodata-for-rent-section"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '75vh', // or 100% if the parent has a defined height
            }}
          >
            <h3>
              {t("Hold on, we're checking out all the great add-ons available for your")}
              <br />
              {t("selected vehicle. Let's see what's in store for you!")}
            </h3>

            <br></br>
            <br></br>
            <img
              src={noResultImage}
              alt={t('Searching for Best rates for you.1')}
              style={{ width: '100px', height: '100px' }}
            />
          </div>
        ) : (
          <div>
            <section className="box-shadow-white-box-section">
              <div className="container-fluid">
                <div className="box-shadow-white-box">
                  <div className="row">
                    <div className="col-xl-6 col-12">
                      <h5 className="font-bold mb-1">{this.props.selectVehicleForBooking.Name}</h5>
                      <p className="text-16 opacity-50 text-black">
                        {t('or Similar.1')} |{' '}
                        {this.props.selectVehicleForBooking.VehicleClassificationName}
                        {this.props.selectVehicleForBooking.ModelYear != null ? (
                          <>
                            {' '}
                            | <b> Model Year - {this.props.selectVehicleForBooking.ModelYear} </b>
                          </>
                        ) : null}
                      </p>
                      <div className="row">
                        <div className="col-sm-8 col-12">
                          <div className="img-details-wrap">
                            <Carousel
                              showStatus={false}
                              dynamicHeight={false}
                              autoPlay
                              interval={10000}
                            >
                              {this.props.selectVehicleForBooking.VehicleImages.map(
                                (image, index) => (
                                  <div key={index}>
                                    <img src={image} alt="" />
                                  </div>
                                ),
                              )}
                            </Carousel>
                          </div>
                        </div>
                        <div className="col-sm-4 col-12 mobile-view-list">
                          <h5 className="text-16 text-red font-bold mb-3">{t('Features.1')}</h5>
                          <VehicleFeatureBoxVertical model={this.props.selectVehicleForBooking} />
                        </div>
                      </div>
                      <div className="included-wrapper-featured mt-0 pt-0">
                        {this.props.selectVehicleForBooking.Features.length > 0 ? (
                          <p className="vehicle-featuress-content">
                            {t('Vehicle Features.1')}
                            <br></br>
                            {this.props.selectVehicleForBooking.Features.map((feature, index) => {
                              return (
                                <label key={feature.Name} style={{ color: 'grey' }}>
                                  {(index ? ', ' : '') + feature.Name}
                                </label>
                              );
                            })}
                          </p>
                        ) : null}
                      </div>
                      {this.props.selectVehicleForBooking.Description.length > 0 ? (
                        <div className="included-wrapper-featured">
                          <p className="vehicle-featuress-content">
                            {t('Vehicle Description.1')}
                            <br></br>

                            <label style={{ color: 'grey' }}>
                              {this.props.selectVehicleForBooking.Description}
                            </label>
                          </p>
                        </div>
                      ) : null}
                      <div className="vehicle-box-detail-left">
                        <ul className="check-box-listing mt-4">
                          {this.state.includeSection != null
                            ? this.state.includeSection.map((include) => {
                                return (
                                  <li>
                                    <span>
                                      {include.ShortText}
                                      <a
                                        className="text-16 text-red font-bold d-block"
                                        onClick={() =>
                                          this.toogleViewIncludeLongTextPopUp(
                                            true,
                                            include.LongText,
                                          )
                                        }
                                      >
                                        <img alt="Sold-Out-Transparent" src={infothin} />
                                      </a>
                                    </span>
                                  </li>
                                );
                              })
                            : ''}
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-12 border-xl-left pt-4 pt-xl-0 mt-4 mt-xl-0">
                      <div className="booking-detail-wrap pl-xl-4">
                        <div className="d-flex flex-wrap justify-content-between  mb-2 pb-1 align-items-center border-bottom">
                          <h3 className="booking-details-listing-left text-18 font-hel-bold">
                            {this.props.searchParam.SubscriptionMonth > 0 ? (
                              <> {t('Total Months.1')}</>
                            ) : (
                              <> {t('Total Month.1')}</>
                            )}
                          </h3>
                          <div className="booking-details-listing-right  ml-auto">
                            <span>
                              {this.props.searchParam.SubscriptionMonth > 1 ? (
                                <>
                                  {this.props.searchParam.SubscriptionMonth} {t('Months.1')}
                                </>
                              ) : (
                                <>
                                  {this.props.searchParam.SubscriptionMonth} {t('Month.1')}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between  mb-2 pb-1 align-items-center border-bottom">
                          <h3 className="booking-details-listing-left text-18 font-hel-bold">
                            {t('Allowed KM.1')}
                          </h3>
                          <div className="booking-details-listing-right  ml-auto">
                            <span>
                              <>
                                {this.props.searchParam.SubscriptionKm} {t('KMS.1')} |{' '}
                                {t('Month.1')}
                              </>
                            </span>
                          </div>
                        </div>

                        <h3 className="text-16 text-red mb-3">{t('Booking Details.1')}</h3>
                        <ul className="booking-details-listing">
                          {this.state.calculationDetails.RentalRateDetail != null
                            ? this.state.calculationDetails.RentalRateDetail.map((model, index) => {
                                return (
                                  <li className="mb-3" key={index}>
                                    <div className="booking-details-listing-left text-18 font-regular">
                                      {model.Item}{' '}
                                      <span className="text-16 d-block opacity-50 mt-1 font-regular">
                                        {model.Description}
                                      </span>
                                    </div>
                                    <div className="booking-details-listing-right ml-auto">
                                      {this.state.currencyCode}{' '}
                                      <span>{model.SubTotal?.toFixed(2)}</span>
                                    </div>
                                    <div className="booking-details-listing-trash text-18 font-regular">
                                      {model.CanBeDeleted == true ? (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.removeItem(model.ItemID, model.chargeCode)
                                          }
                                        >
                                          {this.state.deleteSpinner === model.ItemID ? (
                                            <span aria-hidden="true">
                                              <i className="fa fa-spinner fa-pulse"></i>
                                            </span>
                                          ) : (
                                            <span aria-hidden="true">
                                              <i className="fa fa-trash-o"></i>
                                            </span>
                                          )}
                                          <span className="sr-only">{t('Close.1')}</span>
                                        </button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </li>
                                );
                              })
                            : ''}

                          <li className="mb-3 pt-3 border-top">
                            <div className="booking-details-listing-left text-18 font-hel-bold">
                              {t('Sub Total.1')}{' '}
                              <span className="text-16 d-block opacity-50 mt-1 font-regular"></span>
                            </div>
                            <div className="booking-details-listing-right ml-auto">
                              {this.state.currencyCode}{' '}
                              <span>
                                {this.state.calculationDetails.SubTotal != null
                                  ? this.state.calculationDetails.SubTotal.toFixed(2)
                                  : null}
                              </span>
                            </div>
                            <div className="booking-details-listing-trash text-18 font-regular"></div>
                          </li>

                          <li className="mb-3">
                            <div className="booking-details-listing-left text-18 font-hel-bold">
                              {t('Tax Total.1')}{' '}
                              <span className="text-16 d-block opacity-50 mt-1 font-regular"></span>
                            </div>
                            <div className="booking-details-listing-right ml-auto">
                              {this.state.currencyCode}{' '}
                              <span>
                                {this.state.calculationDetails.TotalTax != null
                                  ? this.state.calculationDetails.TotalTax.toFixed(2)
                                  : null}
                              </span>
                            </div>
                            <div className="booking-details-listing-trash text-18 font-regular"></div>
                          </li>

                          <li className="mt-3 pt-2  border-top">
                            <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                              {t('Grand Total.1')}
                            </div>
                            <div className="booking-details-listing-right text-red ml-auto">
                              {this.state.currencyCode}{' '}
                              <span className="h4">
                                {this.state.calculationDetails.TotalAmount != null
                                  ? this.state.calculationDetails.TotalAmount.toFixed(2)
                                  : null}
                              </span>
                            </div>
                            <div className="booking-details-listing-trash text-18 font-regular"></div>
                          </li>

                          {this.state.calculationDetails.ModificationCharge != null &&
                          this.state.calculationDetails.ModificationCharge.AmountPaid > 0 ? (
                            <ul className="booking-details-listing">
                              <li className="mt-3">
                                <div className="booking-details-listing-left text-18 font-hel-bold text-red ">
                                  {t('Paid Amount.1')}
                                </div>
                                <div className="booking-details-listing-right text-red  ml-auto">
                                  {this.state.currencyCode}{' '}
                                  <span className="h4">
                                    {this.state.calculationDetails.ModificationCharge.AmountPaid.toFixed(
                                      2,
                                    )}
                                  </span>
                                </div>
                                <div className="booking-details-listing-trash text-18 font-regular"></div>
                              </li>
                              <li className="mt-3">
                                <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                                  {this.state.calculationDetails.ModificationCharge != null &&
                                  this.state.calculationDetails.ModificationCharge.AmountToPay > 0
                                    ? 'Pay at Counter'
                                    : 'Refund Amount'}
                                </div>

                                <div className="booking-details-listing-right text-red  ml-auto">
                                  {this.state.currencyCode}{' '}
                                  <span className="h4">
                                    {this.state.calculationDetails.ModificationCharge != null
                                      ? this.state.calculationDetails.ModificationCharge
                                          .AmountToPay > 0
                                        ? this.state.calculationDetails.ModificationCharge.AmountToPay.toFixed(
                                            2,
                                          )
                                        : this.state.calculationDetails.ModificationCharge.AmountToRefund.toFixed(
                                            2,
                                          )
                                      : null}
                                  </span>
                                </div>
                                <div className="booking-details-listing-trash text-18 font-regular"></div>
                              </li>
                            </ul>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="box-shadow-white-box-section">
              <div className="container-fluid">
                <div className="box-shadow-white-box">
                  <div className="pickup-location-box--wrap">
                    <div className="row">
                      <div className="col-sm-6 col-12">
                        <div className="pickup-location-box">
                          <span className="pickup-sm-title">{t('Pickup Location.1')}</span>
                          <div className="pickup-location-flex">
                            <div className="pickup-location-flex-content StationDetails__wrapper p-0 minH-auto">
                              <h3 className="StationDetails__headline w-100">
                                {this.props.additionalParam.dropOffLocationName}
                                <a
                                  className="text-red"
                                  onClick={() =>
                                    this.toogleViewInsurancePopUp(
                                      true,
                                      this.props.additionalParam.dropOffLocationBranchDescription,
                                    )
                                  }
                                >
                                  {t('More Info.1')} <i className="fa fa-angle-right"></i>
                                </a>
                              </h3>
                              <p className="StationDetails__subline">
                                {this.props.additionalParam.dropOffLocationAddress}
                              </p>
                              <p className="StationDetails__subline">
                                {this.props.additionalParam.dropOffLocationMessage}
                              </p>
                              {this.props.searchParam.DeliveryDetails.Address != null &&
                              this.props.searchParam.DeliveryDetails.StateName != null &&
                              this.props.searchParam.DeliveryDetails.Address != undefined &&
                              this.props.searchParam.DeliveryDetails.StateName != undefined &&
                              this.props.searchParam.DeliveryDetails.Address != '' &&
                              this.props.searchParam.DeliveryDetails.StateName != '' ? (
                                <span className="delivery-pickup-box">
                                  {t('Delivery.1')} :{' '}
                                  <span>
                                    {this.props.searchParam.DeliveryDetails.Address +
                                      ', ' +
                                      this.props.searchParam.DeliveryDetails.StateName}
                                  </span>
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-12 mt-4 mt-sm-0">
                        <div className="pickup-location-box">
                          <div className="pickup-location-flex-content StationDetails__wrapper p-0 minH-auto">
                            <span></span>
                            <span className="pickup-sm-title">{t('Pickup Date & Time.1')}</span>
                            <p className="StationDetails__subline">
                              {moment(
                                this.props.searchParam.CheckOutOn,
                                'DD MMMM YYYY, h:mmA',
                              ).format('DD MMMM YYYY, HH:mm')}
                            </p>
                            <br></br>
                            <span className="pickup-sm-title">{t('Return Date & Time.1')}</span>
                            <p className="StationDetails__subline">
                              {moment(this.props.searchParam.CheckInOn).format('DD MMM YYYY HH:mm')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {
              <section className="box-shadow-white-box-section">
                <div className="container-fluid">
                  {this.props.searchParam.ShukranId != null &&
                  this.props.searchParam.ShukranId != '' ? (
                    <div className="row justify-content-end my-4">
                      <div className="col-auto">
                        <div className="promocode-applied-box">
                          {t('Shukran Id applied')}: <span>{this.props.searchParam.ShukranId}</span>{' '}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </section>
            }

            <section className="box-shadow-white-box-section">
              <div className="container-fluid">
                <div className="box-shadow-white-box">
                  <div className="box-title-icon-border d-flex align-items-center mb-0 mb-sm-4 mt-0">
                    <span className="box-title-icon-rounded mt-0">
                      <img src={protection} alt="Dollar Protection" width="13" height="16" />
                    </span>
                    <h4 className="text-16 text-red">{t('Add Protection.1')}</h4>
                  </div>
                  <div className="row">
                    {this.state.insuranceList.map((insurance) => {
                      return (
                        <div
                          className="col-xl-3 col-md-4 col-12 border-right mt-4 mt-md-0"
                          key={insurance.InsuranceType}
                        >
                          <div className="view-details-box-wrap custom-switch custom-control w-100 px-3">
                            <label
                              className="custom-control-label1 w-100"
                              htmlFor="personal-accident-insurance"
                            >
                              <h5 className="text-18">{insurance.Name}</h5>
                              <span className="text-16 font-bold opacity-50 d-block my-1">
                                {this.state.currencyCode} {' ' + insurance.Rate}{' '}
                                {insurance.RateType == 3
                                  ? '/Rental'
                                  : insurance.RateType == 0
                                    ? '/Day'
                                    : insurance.RateType == 1
                                      ? '/Week'
                                      : insurance.RateType == 2
                                        ? '/Month'
                                        : ''}
                              </span>
                              <a
                                title="View Details"
                                className="text-16 text-red font-bold d-block"
                                onClick={() =>
                                  this.toogleViewInsurancePopUp(true, insurance.Description)
                                }
                              >
                                {t('View Details.1')}
                              </a>
                            </label>
                            <Switch
                              className={
                                this.state.selectedInsurnace.includes(insurance.InsuranceType)
                                  ? 'custom-switch-wrap custom-switch-active-wrap'
                                  : 'custom-switch-wrap'
                              }
                              value={
                                this.state.selectedInsurnace.includes(insurance.InsuranceType)
                                  ? insurance.InsuranceType
                                  : ''
                              }
                              on={insurance.InsuranceType}
                              onChange={() => this.getSelectedInsurance(insurance.InsuranceType)}
                            />
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-xl-2 col-12 ml-auto">
                      <div className="text-16 text-gray text-right d-flex align-items-center justify-content-end mt-3 mt-xl-0">
                        {this.state.currencyCode}&nbsp;
                        <span className="font-bold h4 text-black">
                          {this.state.calculationDetails.InsuranceTotal != null
                            ? this.state.calculationDetails.InsuranceTotal.toFixed(2)
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="box-shadow-white-box-section" id="BookingDetails">
              <div className="container-fluid">
                <div className="box-shadow-white-box">
                  <div className="box-title-icon-border d-flex align-items-center mb-4 mt-0">
                    <span className="box-title-icon-rounded mt-0">
                      <img
                        src={protection}
                        alt={t('Dollar Rental Add-Ons.1')}
                        width="13"
                        height="16"
                      />
                    </span>
                    <h4 className="text-16 text-red">{t('Rental Add-Ons.1')}</h4>
                  </div>
                  <div className="row">
                    {this.state.ancillaryList?.map((ancillary) => {
                      return (
                        <>
                          <div className="col-xl-5 col-md-6 col-12 mb-3" key={ancillary._id}>
                            <div className="extra-box-range-wrap ">
                              <div className="extra-box-range-box mr-3 mb-2 mb-sm-0">
                                <img
                                  src={ancillary.Attachment.ImageString}
                                  width="27"
                                  alt="Dollar Free"
                                />
                              </div>
                              <div className="custom-control-label1 w-100">
                                <h5 className="text-18">
                                  {ancillary.Name}
                                  {ancillary.FreeDays > 0 ? (
                                    <span className="free-tag-monthly">{t('Free Days.1')}</span>
                                  ) : null}
                                </h5>
                                <span className="text-16 font-bold opacity-50 d-block my-1">
                                  {this.state.currencyCode} {' ' + ancillary.Rate}{' '}
                                  {ancillary.RateType == 3
                                    ? '/Rental'
                                    : ancillary.RateType == 0
                                      ? '/Day'
                                      : ancillary.RateType == 1
                                        ? '/Week'
                                        : ancillary.RateType == 2
                                          ? '/Month'
                                          : ''}
                                </span>
                                <a
                                  title="View Details"
                                  className="text-16 text-red font-bold d-block"
                                  onClick={() =>
                                    this.toogleViewExtrasPopUp(true, ancillary.Description)
                                  }
                                >
                                  {t('View Details.1')}
                                </a>
                              </div>
                              <div className="input-quantity ml-auto">
                                {ancillary.SelectionType == 1 ? (
                                  <Switch
                                    className={
                                      this.state.selectedAncillary.includes(ancillary._id)
                                        ? 'custom-switch-wrap custom-switch-active-wrap'
                                        : 'custom-switch-wrap'
                                    }
                                    value={
                                      this.state.selectedAncillary.includes(ancillary._id)
                                        ? ancillary._id
                                        : ''
                                    }
                                    on={ancillary._id}
                                    onChange={(e) => this.getSelectedAncillary(ancillary._id)}
                                    disabled={ancillary.IsCompulsoryOnRental}
                                    title={t("You can't change this ancillary.1")}
                                  />
                                ) : (
                                  <select
                                    name="ancillary-type"
                                    value={
                                      this.state.selectedAncillaryList.findIndex(
                                        (an) => an.AncillaryID == ancillary._id,
                                      ) > 0
                                        ? this.state.selectedAncillaryList.find(
                                            (x) => x.AncillaryID == ancillary._id,
                                          ).Qty
                                        : 0
                                    }
                                    className="baby-seat-select-box"
                                    id={ancillary._id}
                                    onChange={(e) => this.getSelectedTyp1Ancillary(e)}
                                  >
                                    {ancillary.IsCompulsoryOnRental == false ? (
                                      <option value="0">0</option>
                                    ) : null}
                                    <option value="1">{t('1.1')}</option>
                                    <option value="2">{t('2.1')}</option>
                                    <option value="3">{t('3.1')}</option>
                                  </select>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="col-xl-2 col-12 ml-auto">
                      <div className="text-16 text-gray mt-3 mt-xl-0 d-flex align-items-center justify-content-end">
                        {this.state.currencyCode}&nbsp;
                        <span className="font-bold h4 text-black">
                          {this.state.calculationDetails.AncillaryTotal != null
                            ? this.state.calculationDetails.AncillaryTotal.toFixed(2)
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* ListingUpgradeOption */}
            {/* {
              <section className="box-shadow-white-box-section">
                <div className="container-fluid">
                  {this.state.hasUpgrade ? (
                    <div className="box-shadow-white-box">
                      <div className="">
                        <div className="row">
                          <div className="col-sm-12 col-12 mt-4 mt-sm-0">
                            <ListingUpgradeOption
                              model={this.state.VehicleModel}
                              searchParam={this.props.searchParam}
                              BookUpgrade={this.props.onUpgradeModelSelected}
                              CloseUpgradePopup={this.toogleUpgradeOptionPopup}
                              onUpdateCalculation={this.onUpdateCalculation}
                              bid={this.state.bid}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.props.searchParam.ShukranId != null &&
                    this.props.searchParam.ShukranId != '' ? (
                    <div className="row justify-content-end my-4">
                      <div className="col-auto">
                        <div className="ShukranId-applied-box">
                          {t('Promo code applied.1')}: <span>{this.props.searchParam.ShukranId}</span>{' '}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.reservationForBooking != null ? (
                    this.state.reservationForBooking.UpgradeText != null ? (
                      <div className="row justify-content-end my-4">
                        <div className="col-auto">
                          <div className="ShukranId-applied-box">
                            <span>{this.state.reservationForBooking.UpgradeText}</span>{' '}
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </section>
            } */}

            <section className="box-shadow-white-box-section">
              <div className="container-fluid">
                <div className="box-shadow-white-box">
                  <div className="box-title-icon-border d-flex align-items-center mb-4 mt-0">
                    <span className="box-title-icon-rounded mt-0">
                      <img src={driverdetails} alt="Dollar Driver Details" width="16" height="16" />
                    </span>
                    <h4 className="text-16 text-red">{t('Driver Details.1')}</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="first-name">{t('First Name.1')}</label>
                        <input
                          type="text"
                          placeholder={t('Enter First Name.1')}
                          id="first-name"
                          name="firstname"
                          onChange={this.onChange}
                          value={this.state.firstname}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-4">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="last-name">{t('Last Name.1')}</label>
                        <input
                          type="text"
                          placeholder={t('Enter Last Name.1')}
                          id="last-name"
                          name="lastname"
                          onChange={this.onChange}
                          value={this.state.lastname}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mt-lg-0 mt-4">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="contact-number">{t('Contact Number.1')}</label>
                        <PhoneInput
                          country={'us'}
                          onChange={this.onChangePhone}
                          value={this.state.contactno}
                          inputProps={{
                            name: 'contactno',
                            required: true,
                            autoFocus: true,
                          }}
                          placeholder={t('Enter Contact Number.1')}
                          containerStyle={{ width: '100%' }}
                          inputStyle={{ width: '100%', height: '100%' }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mt-4">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="first-name">{t('Email Address.1')}</label>
                        <input
                          type="text"
                          placeholder={t('Enter Email Address.1')}
                          id="email"
                          name="email"
                          onChange={this.onChange}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mt-4">
                      <div className="custom-input-type-wrap">
                        <label htmlFor="contact-number">{t('Nationality.1')}</label>

                        <select
                          className="filter-box"
                          onChange={this.onChangeCountryCode}
                          value={this.state.nationalitycode}
                        >
                          <option value="SELECT">{t('SELECT.1')}</option>
                          {this.state.countryList.map((CountryDetail) => (
                            <option value={CountryDetail.code}>{CountryDetail.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mt-4">
                      <div className="custom-input-type-wrap pickup-dropoff-date-wrap1 h-100">
                        <div className="extra-box-range-wrap h-100 flex-nowrap">
                          <div className="custom-control-label1 w-100">
                            <h5 className="text-18">
                              <label
                                className="text-16 font-regular d-block my-1"
                                htmlFor="date-of-birth"
                              >
                                {'Drivers age is above 22 years?'}
                              </label>
                            </h5>
                            <span className="text-16 font-bold opacity-50 d-block my-1">
                              <label
                                className="text-16 font-regular d-block my-1"
                                htmlFor="date-of-birth"
                              >
                                {'I here by confirm that the driver age is above 22 years'}
                              </label>
                            </span>
                          </div>
                          <div className="input-quantity ml-auto">
                            <Switch
                              className={
                                this.state.selectedAgeGreaterThan22 == true
                                  ? 'custom-switch-wrap custom-switch-active-wrap'
                                  : 'custom-switch-wrap'
                              }
                              value={
                                this.state.selectedAgeGreaterThan22
                                  ? this.state.selectedAgeGreaterThan22
                                  : ''
                              }
                              on={this.state.selectedAgeGreaterThan22}
                              onChange={(e) =>
                                this.getSelectedAgeGreaterThan22(
                                  e,
                                  this.state.selectedAgeGreaterThan22,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-sm-8 col-12 mt-4">
                      <div className="custom-input-type-wrap custom-input-select-wrap">
                        <label htmlFor="airline-name">{t('Airline Details.1')}</label>
                        <input
                          type="text"
                          placeholder="Enter airline details"
                          id="airlinename"
                          name="airlinename"
                          onChange={this.onChange}
                          value={this.state.airlinename}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="box-shadow-white-box-section">
              <div className="container-fluid">
                <div className="box-shadow-grey-box">
                  <div className="row align-items-center">
                    <div className="col-md-6 col-12">
                      <div className="media align-items-center save-time-info">
                        <img
                          src={savetimeatcounter}
                          className="mr-md-4 mr-2"
                          alt="Dollar Save Time"
                          width="41"
                          height="41"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 mb-1 text-18 font-bold">
                            <span className="xbressLogo-wrap mr-4">
                              <img src={dollarExpress} alt="Dollar Express" />
                            </span>
                            {t('Save Time At The Counter.1')}
                          </h5>
                          <p className="text-15">
                            {t(
                              'Skip lengthy paper works & queues to get on the road faster with our one-time online verification system. Easily upload the requested documents and rest assured, the process is compliant with our privacy and security systems.1',
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-4">
                      <div className="featured-rent-price-radio mt-0 pt-0 mb-0">
                        <div className="featured-rent-radio">
                          <input
                            type="radio"
                            id="paynow"
                            value="notatcounter"
                            name="featured-rent-price-radio"
                            checked={this.state.isCheked === true}
                            onChange={this.onChangeValue}
                          />
                          <label htmlFor="paynow">
                            <span className="text-16">{t('Provide Documents Now.1')}</span>
                            <p className="mt-1">{t('Upload Drivers License & ID.1')}</p>
                          </label>

                          <input
                            type="radio"
                            id="payletter"
                            value="atcounter"
                            name="featured-rent-price-radio"
                            checked={this.state.isCheked === false}
                            disabled={this.state.isDocumentUploadMandatory}
                            onChange={this.onChangeValue}
                          />
                          <label htmlFor="payletter" className="mt-4 mt-sm-0">
                            <span className="text-16">{t('Provide Documents Later.1')}</span>
                            <p className="mt-1">{t('Submit Drivers License & ID at Counter.1')}</p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showDocAttachement == 1 ? (
                  <div className="box-shadow-white-box  mt-4">
                    <div className="box-title-icon-border d-flex align-items-center mb-4 mb-0 mt-0">
                      <span className="box-title-icon-rounded mt-0">
                        <img
                          src={documentattachments}
                          alt="Dollar Verification"
                          width="14"
                          height=""
                        />
                      </span>
                      <h4 className="text-16 text-red">{t('Upload Verification Documents.1')}</h4>
                    </div>
                    <div className="row justify-content-lg-start justify-content-center align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="custom-input-type-file-wrap">
                          <label>
                            {t('Drivers License.1')}
                            <input type="file" placeholder="Upload" onChange={this.onFileChange} />

                            <span>{t('Upload.1')}</span>
                            <br />
                            <label>
                              {this.state.selectedFile != null ? this.state.selectedFile.name : ''}
                            </label>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                        <div className="custom-input-type-file-wrap">
                          <label>
                            {t('Drivers Passport.1')}

                            <input
                              type="file"
                              placeholder="Upload"
                              onChange={this.onPassportFileChange}
                            />

                            <span>{t('Upload.1')}</span>
                            <br />
                            <label>
                              {this.state.passportSelectedFile != null
                                ? this.state.passportSelectedFile.name
                                : ''}
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </section>

            <RentalPolicies rentalType={3}></RentalPolicies>
            <section className="box-shadow-white-box-section pb-4">
              <div className="container-fluid">
                <div className="box-shadow-white-box mt-0 mb-0">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="terminationPolicyCheck"
                      name="example1"
                      checked={this.state.isTerminationPolicyChecked}
                      onChange={this.onTerminationPolicyChange}
                    />
                    <label className="custom-control-label" for="terminationPolicyCheck">
                      {t(this.state.subscriptionTermsConditionsShortText)} &nbsp;
                      <a className="text-red" onClick={this.onTerminationPolicyClick}>
                        {t('Read More.1')}
                      </a>{' '}
                    </label>
                  </div>
                </div>
              </div>
            </section>
            <section className="mt-2 mb-5">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-md-12 text-xl-center text-md-right text-center">
                    {this.state.loading == false ? (
                      // This means its Pay Online only.
                      this.state.reservationForBooking.VehicleModel != null &&
                      this.state.reservationForBooking.VehicleModel.PaymentMode == 1 ? (
                        <>
                          {
                            <a
                              className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                              title="Book & Pay Online"
                              onClick={this.onBookPayNowClick}
                            >
                              {t('Book & Pay Online.1')}
                            </a>
                          }
                        </>
                      ) : // This means its Pay at Counter only.&& this.state.reservationForBooking.VehicleModel.PaymentMode == 0
                      this.state.reservationForBooking.VehicleModel != null &&
                        this.state.reservationForBooking.VehicleModel.PaymentMode == 0 ? (
                        <>
                          {
                            <a
                              className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                              title="Book & Pay Later"
                              onClick={this.onBookPayLaterClick}
                            >
                              {t('Book & Pay Later.1')}
                            </a>
                          }
                        </>
                      ) : // This means user selected Pay at Counter & both pay online and pay at counter is allowed from Admin
                      this.props.searchParam.PaymentStatus == 0 &&
                        this.state.calculationDetails.PayNowDiscAmount != null &&
                        this.state.calculationDetails.PayNowDiscAmount.toFixed(2) > 0 ? (
                        <>
                          {
                            <div>
                              <a
                                title="Pay Now"
                                className="rounded-bttn rounded-bttn-transparent"
                                onClick={this.onBookPayNowClick}
                              >
                                {t('Pay Now & Save.1')} {this.state.currencyCode}{' '}
                                {this.state.calculationDetails.PayNowDiscAmount.toFixed(2)}{' '}
                                Instantly
                              </a>
                              <a
                                className="rounded-bttn ml-sm-3 mt-3 mt-sm-0"
                                title="Book & Pay Later"
                                onClick={this.onBookPayLaterClick}
                              >
                                {t('Book & Pay Later.1')}
                              </a>
                            </div>
                          }
                        </>
                      ) : this.props.searchParam.PaymentStatus == 1 ? (
                        <a
                          className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                          title="Book & Pay Online"
                          onClick={this.onBookPayNowClick}
                        >
                          {t('Book & Pay Online.1')}
                        </a>
                      ) : (
                        <a
                          className="rounded-bttn rounded-bttn-transparent ml-sm-3 mt-3 mt-sm-0"
                          title="Book & Pay Later"
                          onClick={this.onBookPayLaterClick}
                        >
                          {t('Book & Pay Later.1')}
                        </a>
                      )
                    ) : null}

                    {this.state.loading == true ? (
                      <div style={{ verticalAlign: 'center' }}>
                        <Spinner animation="border" />
                        &nbsp;{this.state.loadingText}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-3">
                    {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                      <Dialog
                        open={this.state.errorMessage.length > 0}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {this.state.errorMessage}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.handleClose} color="primary">
                            {t('OK.1')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
            {this.state.showInsuranceViewDetails ? (
              <div>
                <div className="modal-dialog modal-xl modal-dialog-centered ">
                  <Modal
                    show={this.state.showInsuranceViewDetails}
                    onHide={() => this.toogleViewInsurancePopUp(false)}
                    dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                  >
                    <ViewInsuranceDetails
                      insuranceDesriptionToShow={this.state.insuranceDesriptionToShow}
                    />
                  </Modal>
                </div>
              </div>
            ) : null}
            {this.state.showExtrasDetails ? (
              <div>
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <Modal
                    show={this.state.showExtrasDetails}
                    onHide={() => this.toogleViewExtrasPopUp(false)}
                    dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                  >
                    <ViewExtrasDetails descriptionToShow={this.state.extrasDesriptionToShow} />
                  </Modal>
                </div>
              </div>
            ) : null}
            {this.state.showExtrasDetails ? (
              <div>
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <Modal
                    show={this.state.showExtrasDetails}
                    onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                    dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                  >
                    <ViewIncludeLongText
                      LongTextShow={this.state.LongTextShow}
                      IsTextWithHtml={0}
                    />
                  </Modal>
                </div>
              </div>
            ) : null}

            {this.state.allowToSubmit == false ? (
              <div>
                <div className="modal-dialog modal-lg modal-dialog-centered full-coverage-modal-wrap">
                  <Modal
                    show={this.state.showSCDWDetails}
                    onHide={() => this.toogleViewSCDWHidePopUp(false)}
                    dialogClassName="modal-dialog modal-lg modal-dialog-centered view-details-wrap"
                  >
                    <div className="modal-header ">
                      <div className="row justify-content-between w-100 align-items-center">
                        <div className="col-md-auto col-12">
                          <h4 className="text-red text-center text-md-left">
                            <span className="box-title-icon-rounded mt-0">
                              <img src={protection} alt="" width="13" height="16" />
                            </span>
                            {t('Choose Full Coverage and relax.1')}
                          </h4>
                        </div>
                        <div className="col-md-auto col-12 mt-3 mt-md-0">
                          {this.state.getSCDWInsuranceData != null ? (
                            <p>
                              <b>
                                {t('Just.1')} {this.state.SDW[0]?.Rate} {t('OMR.1')}
                              </b>{' '}
                              {t('per month.1')}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-body">
                      {this.state.getSCDWInsuranceData != null ? (
                        <p>{this.state.getSCDWInsuranceData.FullInsuranceNotOptedText}</p>
                      ) : (
                        ''
                      )}
                      <div className="table-responsive mt-4">
                        <table className="table">
                          <thead class="bg-grey">
                            <tr>
                              <th className="no-wrap">
                                <b>{t('What is covered.1')}</b>
                              </th>
                              <th className="text-center">
                                <b>{t('No protection.1')}</b>
                              </th>
                              <th className="text-center">
                                <b>{t('Full protection.1')}</b>
                              </th>
                            </tr>
                          </thead>
                          <tr>
                            <th>Repair Cost</th>
                            <td className="text-center">
                              <span className="text-red">╳</span>
                            </td>
                            <td className="text-center">
                              <span className="text-success">✓</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Save Time</th>
                            <td className="text-center">
                              <span className="text-red">╳</span>
                            </td>
                            <td className="text-center">
                              <span className="text-success">✓</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <a onClick={this.onWithOutSCDWClick} title="" className="rounded-bttn ">
                        {t('Book without protection.1')}
                      </a>
                      <a
                        onClick={this.onWithSCDWClick}
                        title=""
                        className="rounded-bttn ml-sm-2 rounded-bttn-transparent"
                      >
                        {t('Book with full protection.1')}
                      </a>
                    </div>
                  </Modal>
                </div>
              </div>
            ) : null}

            {this.state.showSubscriptionTermsDetails ? (
              <div>
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <Modal
                    show={this.state.showSubscriptionTermsDetails}
                    onHide={() => this.subscriptionTermsAndSubscription(false)}
                    dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
                  >
                    <ViewIncludeLongText
                      LongTextShow={this.state.subscriptionTermsConditionsLongText}
                      IsTextWithHtml={1}
                    />
                  </Modal>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(MonthlyListingPage3));
