import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

class FindLocation extends Component {
  onFindLocationClick = () => {
    localStorage.setItem('locationid', 'null');
    this.props.history.push('/locations');
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Best Vehicle Rental Offers</title>
          <meta
            name="description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com"></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaroman.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta name="title" content="Dollar Car Rental Oman | Best Vehicle Rental Offers"></meta>
          <meta
            name="description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta
            property="og:image"
            content="https://admin.dollaroman.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="twitter:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta
            property="twitter:image"
            content="https://admin.dollaroman.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
        </Helmet>
        <section
          className={
            this.props.isMobileView == false ? 'find-location-section' : 'bg-grey-location'
          }
        >
          <div className="container">
            <div className="location-wrap text-center text-lg-left">
              <div className="location-wrap-left">
                <h2 className="border-botom">{t('Your Ultimate Car Rental Destination.1')}</h2>
                <h5>6+ Locations across Oman.</h5>
                <p className="mt-2">Muscat, Salalah and Sohar.</p>
              </div>
              <div className="location-wrap-right mt-4 mt-xl-0">
                <a
                  onClick={this.onFindLocationClick}
                  title="Find A Location"
                  className="find-location-bttn"
                >
                  Find a Location
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(withRouter(FindLocation));
