import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import VehicleClassifications from '../Hire/VehicleClassifications';
import dollarPrestigeBookYourWheelPart2 from '../assets/images/Business/1906374694.jpg';
import dollarPrestigeBookYourWheelPart1 from '../assets/images/Business/221715247.jfif';
import dollarPrestigeBookYourWheelPart3 from '../assets/images/Business/593599952.jpg';
import dollarprestigecars from '../assets/images/Business/Dollar Prestige Page.jpg';
import { bookingInstance, branchInstance } from '../axios/axiosInstance';

class DollarPrestige extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicalModels: [],
      PickUpDate: Date().toLocaleString(),
      ReturnDate: Date().toLocaleString(),
      BranchId: '',
      VehicleClassificationID: '',
      Name: '',
      ImageString: '',
      Address: '',
      Message: '',
      Description: '',
      iPAddress: '127.0.0.1',
      customerCountryCode: 'OMN',
      customerCountryName: 'United_Arab_Emirate',
    };
  }

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  componentDidMount = () => {
    this.getIPAddress();
    this.getAllBranches();
    scroll.scrollToTop();
    setTimeout(() => {
      this.getVehicalModels();
    }, 1000);
  };

  getIPAddress = () => {
    var config = {
      method: 'get',
      url: 'https://geolocation-db.com/json/',
      headers: {},
    };

    axios(config)
      .then((res) => {
        this.setState({
          iPAddress: res.data.IPv4,
          customerCountryCode: res.data.country_code,
          customerCountryName: res.data.country_name,
        });
      })
      .catch((error) => {});
  };

  getAllBranches = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance
      .get(`all?countryCode=${code}`)
      .then((response) => {
        const allBranches = response.data.result;
        if (allBranches.length > 0) {
          this.setState({
            BranchId: allBranches[0]?._id,
            Name: allBranches[0].Name,
            ImageString: allBranches[0].Attachment.ImageString,
            Address: allBranches[0].Address,
            Message: allBranches[0].Message,
            Description: allBranches[0].Description,
          });
          this.getHoursToReady(allBranches[0]?._id);
        }
      })
      .catch((error) => {});
  };

  getHoursToReady = (timeId) => {
    var isDeliver = this.state.Address?.length > 0 || false;
    branchInstance
      .get(`/hrsToGetReady?branchId=${timeId}&isDeliverToMe=${isDeliver}`)
      .then((response) => {
        const timeReady = response.data.result;

        let pickupDate = moment
          .tz(timeReady.PickupOn, 'Asia/Muscat')
          .format('MM/DD/YYYY  h:mm:ss A');
        let returnDate = moment
          .tz(timeReady.ReturnOn, 'Asia/Muscat')
          .format('MM/DD/YYYY  h:mm:ss A');
        this.setState({ PickUpDate: pickupDate });
        this.setState({ ReturnDate: returnDate });
      })
      .catch((error) => {});
  };

  getVehicalModels = () => {
    let searchParam = {
      Classifications: [process.env.REACT_APP_DOLLAR_PRESTIGE],
    };

    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`classificationReservation?countryCode=${countryCode}`, searchParam)
      .then((response) => {
        const { data } = response.data.result;
        this.setState({ vehicalModels: data });
      })
      .catch((error) => {});
  };

  onBookNowHandler = () => {
    let pickupDate = moment(this.state.PickUpDate).format('DD MMM YYYY, h:mm A');
    let returnDate = moment(this.state.ReturnDate).format('DD MMM YYYY, h:mm A');
    const { BranchId, Name, Address, Message, Description } = this.state;

    var BookingInfo = [
      {
        CheckOutLocationId: BranchId,
        CheckInLocationId: BranchId,
        PickUpDate: pickupDate,
        ReturnDate: returnDate,
        pickupLocationName: Name,
        dropOffLocationName: Name,
        pickupLocationAddress: Address,
        dropOffLocationAddress: Address,
        pickupLocationMessage: Message,
        dropOffLocationMessage: Message,
        pickupLocationBranchDescription: Description,
        dropOffLocationBranchDescription: Description,
        deliveryState: '',
        deliveryCode: '',
        deliveryAddress: '',
        returnState: '',
        returnCode: '',
        returnAddress: '',
        promocode: '',
        ModifiedReservationID: '',
        RentalDays: 3,
        Daily: true,
        IPLocation: {
          IPAddress: this.state.iPAddress,
          Country_code: this.state.customerCountryCode,
          Country_name: this.state.customerCountryName,
        },
      },
    ];

    localStorage.setItem('VehicleTypeParam', process.env.REACT_APP_DOLLAR_PRESTIGE);
    localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
    window.location = '/booking';
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Arrive in Style with the Ultimate Premium Range of Vehicles with Dollar Car Rental Oman
          </title>
          <meta
            name="description"
            content="Elevate your travel experience and explore a range of premium cars. Cruise around with the best-in-class vehicles. Rent your dream car now. "
          ></meta>
          <meta
            property="og:title"
            content="Arrive in Style with the Ultimate Premium Range of Vehicles with Dollar Car Rental Oman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/dollar-prestige"></meta>
          <meta
            property="og:description"
            content="Elevate your travel experience and explore a range of premium cars. Cruise around with the best-in-class vehicles. Rent your dream car now. "
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea align-items-center">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">Dollar Prestige</h2>
                            <p>Luxury Vehicles without the Luxury Price Tags. </p>
                            <p className="text_p text-black my-4">
                              Here’s your choice of vehicles for driving thrills on a winding
                              highway. Make your selection on great vehicle dynamics, improved
                              performance count & unrivaled street appeal for your wheels through
                              the attractions within the world’s top cities!
                            </p>

                            <div className="oddEven-content pr-sm-5 mt-4">
                              <h3 className="font-24">Perfect for</h3>
                              {/* <p className="font-18 text-grey pt-3">We’re talking;</p> */}
                              <ul className="check-box-listing round-checkmark-wrap">
                                <li className="align-items-center">
                                  <p className="font-18 text-black">Date Nights</p>
                                </li>
                                <li className="align-items-center mt-3">
                                  <p className="font-18 text-black">Birthdays and Anniversaries</p>
                                </li>
                                <li className="align-items-center mt-3">
                                  <p className="font-18 text-black">
                                    Weddings, Receptions & Bachelor/ette Parties
                                  </p>
                                </li>
                                <li className="align-items-center mt-3">
                                  <p className="font-18 text-black">
                                    Concert Events, Theaters, Museums & Night Tours
                                  </p>
                                </li>
                                <li className="align-items-center mt-3">
                                  <p className="font-18 text-black">
                                    Luxury & Entertainment agencies
                                  </p>
                                </li>
                                <li className="align-items-center mt-3">
                                  <p className="font-18 text-black">
                                    Corporate, Celebrity, VIP Clientele
                                  </p>
                                </li>
                                <li className="align-items-center mt-3">
                                  <p className="font-18 text-black">All-day Business Trips</p>
                                </li>
                              </ul>
                            </div>

                            <a
                              onClick={() => this.onMenuLinkClick('/StartaReservation')}
                              className="primary-bttn primary-invert-bttn mt-4"
                              title="   Book Now"
                            >
                              Start the Reservation
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={dollarprestigecars}
                        alt={t('Dollar Reservation.1')}
                        className="img-fluid rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row  flex-reversea align-items-center">
                    <div className="col-lg-4">
                      <div className="pickup-location-box pr-0">
                        <h5 className="font-bold mb-3 text-red">Ready to travel in style?</h5>
                        <p>Say hello to our signature collection!</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content ">
                            <p className="text_p text-black">
                              {' '}
                              With Dollar Prestige, you can drive away in a luxury car at an
                              affordable rate. No matter what you have planned for your next trip –
                              a getaway with friends, a monthly business trip, or an annual family
                              vacation – you can now elevate your travel with one of the luxury
                              models from the Dollar Oman Prestige Collection.{' '}
                            </p>
                            <p className="text_p mt-4 text-black">
                              {' '}
                              We’re talking incredible cars on incredible roads. Luxury Car Rentals
                              is as easy as it is with any other car class we offer! The only
                              specific requirement for our Dollar Prestige Collection is that you
                              are at least 22 years or older.
                            </p>
                            <p className="text_p mt-4 text-black">
                              Dollar Prestige’s Fleet Portfolio boasts a curated collection of
                              latest luxury models, that are available for rent all throughout our
                              locations in Oman. One gets to experience the best of these thrilling
                              rides at the most affordable and best prices in Oman. Gone are the
                              days of longterm financial commitments, lengthy paperworks and
                              expensive vehicle service maintenance. Today, with Dollar Prestige
                              Luxury Rides, you can experience multiple luxury models for a fraction
                              of their ownership hassles.
                            </p>
                            <p className="text_p mt-4 text-black">
                              {' '}
                              Contact us today to discover the Dollar Prestige Collection of exotic,
                              luxury and super cars for rent in Oman.{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6  col-md-12 col-sm-12 col-12 mt-4 mt-sm-0">
                              <img src={carrental} alt="" className="img-fluid" />
                           </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="offer-box-section pb-4 grey-bg">
            <div className="container-fluid">
              <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                {this.state.vehicalModels.map((model, index) => {
                  return (
                    <div key={index} className="vehicle-box-design">
                      <h3>{model.Name}</h3>
                      <span className="similar-text">or Similar | Dollar Prestige</span>
                      <div className="vehicle-box-design-img-wrap">
                        <img
                          src={model.VehicleImages[0]}
                          alt={model.Name}
                          className="vehicle-image-main"
                        />
                      </div>
                      {/* {this.props.hideInclude == 0 ? <VehicleFeatureBox model={this.props.model} /> : ""} */}
                      {/*  <div className="prestige-car-box-content">
                                <p>{model.Description}</p>
                              </div> */}

                      <div className="prestige-car-box-bttn text-center w-100">
                        <a
                          className="vehicle-box-design-book"
                          title="Book Now"
                          onClick={this.onBookNowHandler}
                        >
                          {' '}
                          {t('Book Now.1')}{' '}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section className="subscriber-red-section">
            <div className="container">
              <div className="other-products-news-wrap">
                <h2>BOOK YOUR WHEELS WITH DOLLAR</h2>
                <div className="row other-products-news-row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="other-products-news-box">
                      <div className="other-products-news-image">
                        <img src={dollarPrestigeBookYourWheelPart1} alt="First Article" />
                        <div className="other-products-news-content">
                          <h3>Join Dollar RED Community</h3>
                          <p>Exclusive Flash Deals, Surprise Giveaways.</p>
                        </div>
                      </div>
                      <div className="mt-3 text-center">
                        <a
                          className="vehicle-box-design-book"
                          onClick={() => this.onMenuLinkClick('/register')}
                        >
                          {t('SIGN UP.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
                    <div className="other-products-news-box">
                      <div className="other-products-news-image">
                        <img src={dollarPrestigeBookYourWheelPart2} alt="Second Article" />
                        <div className="other-products-news-content">
                          <h3>{t('Monthly Rentals.1')}</h3>
                          <p>{t('Save more & Benefits.1')}</p>
                        </div>
                      </div>
                      <div className="mt-3 text-center">
                        <a
                          className="vehicle-box-design-book"
                          onClick={() => this.onMenuLinkClick('/monthlyrentals')}
                        >
                          {t('BOOK NOW.1')}{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                    <div className="other-products-news-box">
                      <div className="other-products-news-image">
                        <img src={dollarPrestigeBookYourWheelPart3} alt="Third Article" />
                        <div className="other-products-news-content">
                          <h3>{t('Dollar Hunt.1')}</h3>
                          <p>365 days of offers & surprise deals.</p>
                        </div>
                      </div>
                      <div className="mt-3 text-center">
                        <a
                          className="vehicle-box-design-book"
                          onClick={() => this.onMenuLinkClick('/offers')}
                        >
                          {t('SHOW OFFERS.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <VehicleClassifications />
        </div>
        <br />
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(DollarPrestige)), DollarPrestige);
// export default DollarPrestige;
