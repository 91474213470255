import React, { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import Header from '../Common/ListingHeader';
import BannerSection from './BannerSection';
const SpecialOffersIndex = React.lazy(() => import('../SpecialOffer/SpecialOffersIndex'));
const LessfineSection = React.lazy(() => import('./LessFineSection'));
const Subscribe = React.lazy(() => import('./Subscribe'));
const NewsIndex = React.lazy(() => import('../News/NewsIndex'));
const Footer = React.lazy(() => import('../Common/Footer'));
const CopyRightFooter = React.lazy(() => import('../Common/CopyRightFooter'));
const FindLocation = React.lazy(() => import('./FindLocation'));

class Hire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: 1024,
      isMobileView: false,
      headerActive: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
    scroll.scrollToTop();
  }

  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
    this.setState({ isMobileView: false });
    if (window.innerWidth < 770) {
      this.setState({ isMobileView: true });
    }
  };

  handleHeaderActive = (value) => {
    if (this.state.windowSize > 553) {
      this.setState({ headerActive: value });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Best Vehicle Rental Offers</title>
          <link rel="canonical" href="www.dollaroman.com" />
          <meta
            name="description"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers in Oman"
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers in Oman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com"></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta name="title" content="Dollar Car Rental Oman | Best Vehicle Rental Offers"></meta>
          <meta
            name="description"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers in Oman"
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers in Oman"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="twitter:description"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers in Oman"
          ></meta>
        </Helmet>

        {this.state.headerActive && <Header />}
        <BannerSection
          isMobileView={this.state.isMobileView}
          handleHeaderActive={this.handleHeaderActive}
        />

        <Suspense fallback={<div>Loading Special Offers...</div>}>
          <SpecialOffersIndex isMobileView={this.state.isMobileView} />
        </Suspense>

        {this.state.isMobileView === false ? (
          <Suspense fallback={<div>Loading Booking Section...</div>}>
            <LessfineSection />
          </Suspense>
        ) : null}

        {this.state.isMobileView === false ? (
          <Suspense fallback={<div>Loading Subscribe Section...</div>}>
            <Subscribe />
          </Suspense>
        ) : null}

        {this.state.isMobileView === false ? (
          <Suspense fallback={<div>Loading News...</div>}>
            <NewsIndex />
          </Suspense>
        ) : null}

        <Suspense fallback={<div>Loading Find Location...</div>}>
          <FindLocation isMobileView={this.state.isMobileView} />
        </Suspense>

        {this.state.isMobileView === false ? (
          <Suspense fallback={<div>Loading Footer...</div>}>
            <Footer />
          </Suspense>
        ) : null}

        {this.state.isMobileView === true ? (
          <Suspense fallback={<div>Loading Footer...</div>}>
            <CopyRightFooter isMobileView={this.state.isMobileView} />
          </Suspense>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(Hire);
