import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider1 from 'react-slick';
import hyundai from '../assets/images/brands/hyundai.png';
import kia from '../assets/images/brands/Kia.png';
import mazda from '../assets/images/brands/Mazda.png';
import mitsubishi from '../assets/images/brands/Mitsubishi.png';
import nissan from '../assets/images/brands/nissan.png';
import toyota from '../assets/images/brands/toyota.png';

class BrandSlider extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var settings = {
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1480,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="slider-brands-section ptb-50 bg-grey slider-arrows">
        <div className="container">
          <h2 className="border-botom">Trusted Brands</h2>
          <p className="mb-50">Only the best from your favourite car rental.</p>
          <Slider1 {...settings}>
            <div
              className="slider-brands slick-slide slick-cloned"
              tabIndex="-1"
              data-slick-index="-3"
              id=""
              aria-hidden="true"
            >
              <div className="slider-brands-logo">
                <img src={toyota} alt="Toyota" />
              </div>
              <span>Toyota Rental in Oman</span>
            </div>
            <div
              className="slider-brands slick-slide slick-cloned"
              tabIndex="-1"
              data-slick-index="-2"
              id=""
              aria-hidden="true"
            >
              <div className="slider-brands-logo">
                <img src={nissan} alt="Nissan" />
              </div>
              <span>Nissan Rental in Oman</span>
            </div>
            <div
              className="slider-brands slick-slide slick-cloned"
              tabIndex="-1"
              data-slick-index="-1"
              id=""
              aria-hidden="true"
            >
              <div className="slider-brands-logo">
                <img src={hyundai} alt="Hyundai" />
              </div>
              <span>Hundai Rental in Oman</span>
            </div>
            <div
              className="slider-brands slick-slide slick-active"
              tabIndex="0"
              data-slick-index="1"
              aria-hidden="false"
            >
              <div className="slider-brands-logo">
                <img src={kia} alt="Kia" />
              </div>
              <span>Kia Rental in Oman</span>
            </div>
            <div
              className="slider-brands slick-slide slick-active"
              tabIndex="0"
              data-slick-index="2"
              aria-hidden="false"
            >
              <div className="slider-brands-logo">
                <img src={mazda} alt="Mazda" />
              </div>
              <span>Mazda Rental in Oman</span>
            </div>
            <div
              className="slider-brands slick-slide slick-active"
              tabIndex="-1"
              data-slick-index="4"
              aria-hidden="true"
            >
              <div className="slider-brands-logo">
                <img src={mitsubishi} alt="Mitsubishi" />
              </div>
              <span>Mitsubishi Rental in Oman</span>
            </div>
          </Slider1>
        </div>
      </section>
    );
  }
}

export default withTranslation()(withRouter(BrandSlider));
