import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ListingCard from '../ListingPage/ListingCard';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';
import '../MonthlySubscription/Tabs/tabsStyle.css';
import { bookingInstance, productInstance } from '../axios/axiosInstance';
import OtherVehicleType from './OtherVehicleType';

class VehicleTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicleTypesModel: {},
      otherVehicleTypesModel: [],
      vehicalModels: [],
      PickUpDate: Date().toLocaleString(),
      ReturnDate: Date().toLocaleString(),
      BranchId: '',
      VehicleClassificationID: '',
      Name: '',
      showVehicleType: false,
      ImageString: '',
      Address: '',
      Message: '',
      Description: '',
      searchParam: {
        Classifications: [],
        VehicleMakeID: '00000000-0000-0000-0000-000000000000',
        VehicleModelID: '00000000-0000-0000-0000-000000000000',
        SpecialOfferID: '00000000-0000-0000-0000-000000000000',
        ModelYear: 0,
        Passengers: 0,
        AutoTransmission: 0,
        CheckOutOn: '2024-09-20T15:51:00',
        CheckInOn: '2024-09-23T15:50:00',
        CheckOutBranchID: '',
        CheckInBranchID: '',
        DeliveryDetails: {
          Address: '',
          AddressJson: '',
        },
        PickupDetails: {
          Address: '',
          AddressJson: '',
        },

        CheckOutBranchID: '6665be277d3fb0a6b31d077c',
        CheckInBranchID: '6665be277d3fb0a6b31d077c',
        PromoCodeID: '00000000-0000-0000-0000-000000000000',
        PromoCode: '',
        DriverAge: 21,
        MinRentalRate: 0,
        MaxRentalRate: 0,
        RentalDays: 0,
        NoOfRecords: 0,
        TariffGroupID: '00000000-0000-0000-0000-000000000000',
      },
    };
  }

  componentDidMount = () => {
    scroll.scrollToTop();
    const {
      match: { params },
    } = this.props;

    if (
      localStorage.getItem('vehicleClassificationID') != 'null' &&
      localStorage.getItem('vehicleClassificationID') != undefined
    ) {
      params.index = localStorage.getItem('vehicleClassificationID');
      localStorage.setItem('vehicleClassificationID', '');
    }

    if (params.index == undefined) {
      this.setState({ VehicleClassificationID: -1 });
      this.getVehicleTypes(-1);
      this.getVehicalModels(-1);
    } else {
      this.setState({ VehicleClassificationID: params.index });
      this.getVehicleTypes(params.index);
      this.getVehicalModels(params.index);
    }
  };

  toogleVehicleType = (val) => {
    this.setState({
      showVehicleType: val,
    });
  };

  getVehicleTypes = (VehicleClassificationID) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      let selectedVehicleTypesModel = {};
      let otherVehicleTypesModel = [];

      vehicleTypes.forEach((type) => {
        if (type.VehicleClassificationID == VehicleClassificationID) {
          selectedVehicleTypesModel = type;
        } else {
          otherVehicleTypesModel.push(type);
        }
      });

      this.setState({ selectedVehicleTypesModel: selectedVehicleTypesModel });
      this.setState({ otherVehicleTypesModel: otherVehicleTypesModel });
    });
  };

  onImageClick = (Id) => {
    localStorage.setItem('vehicleClassificationID', Id);
    window.location = `/vehicletypes/${Id}`;
  };

  getVehicalModels = (VehicleClassificationID) => {
    let searchParam = {
      Classifications: [VehicleClassificationID],
    };

    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`classificationReservation?countryCode=${countryCode}`, searchParam)
      .then((response) => {
        const { data } = response.data.result;
        this.setState({ vehicalModels: data });
      })
      .catch((error) => {});
  };

  onSelectedVehicleIndexChange = (index, selectedVehicle) => {
    //Do not remove we need this
  };

  onBookNowHandler = () => {
    this.toogleVehicleType(true);
  };

  handleBanner = (val) => {};

  handleHeaderActive = (va) => {};

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Explore Our Complete Range of Vehicles - Economy, SUV, Standard, Compact</title>
          <meta
            name="description"
            content="With vehicles ranging from Sedans to SUVs, we have it all and more. Explore a range of top of the class vehicles and rent in style. "
          ></meta>
          <meta
            property="og:title"
            content="Explore Our Complete Range of Vehicles - Economy, SUV, Standard, Compact"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/vehicletypes"></meta>
          <meta
            property="og:description"
            content="With vehicles ranging from Sedans to SUVs, we have it all and more. Explore a range of top of the class vehicles and rent in style. "
          ></meta>
        </Helmet>
        <ListingHeader />
        {this.state.VehicleClassificationID != -1 ? (
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h2 className="border-botom">
                          {this.state.selectedVehicleTypesModel.Name}
                        </h2>
                        {/* <h5 className="font-bold mb-3 mt-2 ">{this.state.selectedVehicleTypesModel.Name}</h5> */}
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <p class="font-18 text-grey">
                              {this.state.selectedVehicleTypesModel.Description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={
                          this.state.selectedVehicleTypesModel.Attachment != null
                            ? this.state.selectedVehicleTypesModel.Attachment.ImageString
                            : ''
                        }
                        alt={t('Vehicle Types.1')}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {this.state.VehicleClassificationID != -1 ? (
          <div>
            <section className="offer-box-section grey-bg pb-4">
              <div className="container-fluid">
                <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                  {this.state.vehicalModels.map((model, index) => {
                    return (
                      <ListingCard
                        key={model.VehicleModelID}
                        model={model}
                        hideInclude={0}
                        hideUpgradeOptions={1}
                        showDiffPrice={0}
                        searchParam={this.state.searchParam}
                        vehicleIndex={index + 1}
                        showBookNow={1}
                        onBookNowClick={this.onBookNowHandler}
                        onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                        selectedVehicleIndex={9999}
                      />
                    );
                  })}
                </div>
              </div>
            </section>
            <OtherVehicleType
              otherVehicleTypesModel={this.state.otherVehicleTypesModel}
              onImageClick={this.onImageClick}
            />
          </div>
        ) : (
          <div>
            <OtherVehicleType
              otherVehicleTypesModel={this.state.otherVehicleTypesModel}
              onImageClick={this.onImageClick}
            />
            <section className="offer-box-section grey-bg pb-4">
              <div className="container-fluid">
                <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                  {this.state.vehicalModels.map((model, index) => {
                    return (
                      <ListingCard
                        key={model.VehicleModelID}
                        model={model}
                        hideInclude={0}
                        hideUpgradeOptions={1}
                        showDiffPrice={0}
                        searchParam={this.state.searchParam}
                        vehicleIndex={index + 1}
                        showBookNow={1}
                        onBookNowClick={this.onBookNowHandler}
                        onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                        selectedVehicleIndex={9999}
                      />
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        )}

        {this.state.showVehicleType ? (
          <div className="modal-dialog modal-xl modal-dialog-centered border-transparent-bg">
            <Modal
              className=""
              show={this.state.showVehicleType}
              onHide={() => this.toogleVehicleType(false)}
              dialogClassName="modal-dialog modal-xl border-transparent-bg"
            >
              {/* <SearchWidgetNew page={"H"} /> */}
              <section className="sort-c1 modal-c">
                <div className="inner-wrapper" style={{ background: 'white' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="tab-content " id="nav-tabContent">
                          <StartBooking
                            manageBanner={this.handleBanner}
                            handleHeaderActive={this.handleHeaderActive}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal>
          </div>
        ) : null}
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(VehicleTypes));
