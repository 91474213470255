import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import privacypolicybanner from '../assets/images/corporate-leasing.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import LeasingInquiry from './LeasingInquiry';
class CorporateLeasing extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h2 className="border-botom">
                          Corporate Leasing & Fleet Management Services
                        </h2>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <span>
                              <ul className="mb-0">
                                <li> Commercial vehicles. </li>
                                <li> Specialized (business) vehicles. </li>
                                <li> Cars for field staff </li>
                                <li> Cars for senior managers </li>
                                <li> Personal lease </li>
                              </ul>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={privacypolicybanner}
                        alt="Dollar Corporate Leasing"
                        className="img-fluid rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <p className="text_p mt-4">
                      We believe, no road is long with the right company, And that is why, at Dollar
                      Car Rental, we aim to be the leading and innovative provider of mobility &amp;
                      transportation solutions in Oman.
                    </p>
                    <br />
                    <p className="text_p mt-4">
                      We host Oman’s largest &amp; widest fleet, for all your transportation needs
                      Dollar Car Rental Oman takes pride in being associated with leading brands in
                      Oman as their total transportation solutions partner, by helping them achieve
                      reduced fleet management costs, improved fleet compliance, customized
                      vehicle-builds, and other business-specific goals.
                    </p>
                    <br />
                    <p className="text_p mt-3">
                      Let us look at some of the advantages of long-term personal car leasing,
                      especially in the UA
                    </p>
                    <br />

                    <h5 className="font-weight-bold">
                      Key points that could define Dollar Oman as your total mobility partner
                    </h5>
                    <p className="text_p mt-4">
                      Our vehicles are modern, reliable &amp; adaptable, making us a wise choice for
                      businesses that involve transportation of expensive and perishable goods.
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Sales Consultants</h5>
                    <p className="text_p mt-4">
                      that support you with scalable vehicle acquisition and management solutions
                      ensuring significant cost savings, reduced administrative hassles, increased
                      productivity, and risk mitigation within your organization.
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Convenience &amp; Flexibility</h5>
                    <p className="text_p mt-4">
                      Our corporate leasing works on a pay-for-usage model, rather than fleet
                      ownership. This allows our clients to use vehicles, based on their
                      requirements with simple straightforward lease rentals, while not having to
                      worry about the hassles of fleet management, servicing, insurance, damage
                      repair, registration rentals, replacement vehicles, and end-of-term sales
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Long-term leasing</h5>
                    <p className="text_p mt-4">Long-term leasing options of up to 5+ years.</p>
                    <br />

                    <h5 className="font-weight-bold">Dedicated Account Managers</h5>
                    <p className="text_p mt-4">
                      Our team moves with your team, 24x7. Hassle-free interactions, no repeated
                      paperwork, priority agile approach.
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Periodic training </h5>
                    <p className="text_p mt-4">
                      Periodic training &amp; frequent in-house workshops for vehicle drivers and
                      other involved human resources, conducted in all Oman
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Customizable vehicular modifications</h5>
                    <p className="text_p mt-4">
                      for business-specific requirements: Based on the needs of a large number of
                      our clients, we have expanded and increased our focus on specialized vehicles,
                      targeted towards the transport/logistics/distribution companies and commercial
                      fleet owners in Oman. We now offer these vehicles on a long-term lease as per
                      your requirements. To know further on how you can manage your fleet more
                      efficiently to instead focus more on your core business, contact us today.
                    </p>
                    <br />
                    <p className="text_p mt-4">We look forward to serving you.</p>
                  </div>
                  <div className="col-lg-5" id="book">
                    <LeasingInquiry />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(CorporateLeasing);
