import Slider from '@material-ui/core/Slider';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ContactPopup from '../Common/ContactPopup';
import { productInstance } from '../axios/axiosInstance';

class CorporateLeaseBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }
  componentDidMount = () => {
    this.getVehicleTypes();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });
      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedQuoteRequestType: 0,
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section grey-bg py-2" id="partner-a">
          <div className="">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="box-shadow-white-box-section" id="inquiry" name="inquiry">
                  <div className="container-fluid">
                    <div className="box-shadow-white-box bg-grey">
                      <div className="lease-wrapper row justify-content-between">
                        <div className="lease-left">
                          <h3>Business Leasing</h3>
                          <p>
                            Dollar Car Rental is a globally established brand when it comes to
                            helping companies move their business on road. We take pride in being
                            associated with the leading brands in Oman as their total
                            transportations solutions partner.
                          </p>
                          <p>
                            Our industrially acclaimed sales consultants guide you with expert
                            inputs regarding your logistics, fleet management costs, compliances,
                            optimal customized vehicular builds, distribution, supply chain
                            management, and, other business-specific goals.
                          </p>
                          <p>
                            Our range of fleet is one of the largest and widest in Oman, making us a
                            wise reliable choice for businesses that involve transportation of
                            expensive and perishable goods - with long-term leasing options up to 5+
                            years.
                          </p>
                          <p>Start your journey with us today.</p>
                          {/* <a onClick={this.onKnowMoreClick} className="primary-bttn primary-invert-bttn" title="Read More">{t('Know More.1')}</a> */}
                        </div>
                        <div className="lease-right mt-lg-0 mt-sm-5 mt-2">
                          <div className="form-group ">
                            <div id="buttoncss">
                              {
                                <p className="mb-2 font-14">
                                  <b>{t('Vehicles Type')}</b>
                                </p>
                              }

                              <ul className="nos-vehicle-list">
                                {this.state.vehicleTypes.map((vehicleType) => (
                                  <li key={vehicleType.VehicleClassificationID}>
                                    <input
                                      type="radio"
                                      id={vehicleType.Name}
                                      name="noofveicles"
                                      value={vehicleType.Name}
                                      checked={this.state.selectedOption === vehicleType.Name}
                                      onChange={this.onChange}
                                    />
                                    <label htmlFor={vehicleType.Name}>
                                      <img
                                        src={vehicleType.Icon.ImageString}
                                        alt={vehicleType.Name}
                                      />
                                      <p>{vehicleType.Name}</p>
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <br />
                            <div id="buttoncss" className="min-129">
                              <p className="mb-2 font-14 mt-2">
                                <b>Lease tenure</b>
                              </p>
                              <ul className="nos-vehicle-list lease-tenure-radio">
                                <li>
                                  <input
                                    type="radio"
                                    id="oneyear"
                                    name="noofveicles1"
                                    value={12}
                                    checked={this.state.selectedYear === '12'}
                                    onChange={this.onChangeYear}
                                  />
                                  <label htmlFor="oneyear">
                                    <p>1 {t('Year.1')}</p>
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    id="twoyear"
                                    name="noofveicles1"
                                    value={24}
                                    checked={this.state.selectedYear === '24'}
                                    onChange={this.onChangeYear}
                                  />
                                  <label htmlFor="twoyear">
                                    <p>2 {t('Year.1')}</p>
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    id="threeyear"
                                    name="noofveicles1"
                                    value={36}
                                    checked={this.state.selectedYear === '36'}
                                    onChange={this.onChangeYear}
                                  />
                                  <label htmlFor="threeyear">
                                    <p>3 {t('Year.1')}</p>
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    id="fouryear"
                                    name="noofveicles1"
                                    value={48}
                                    checked={this.state.selectedYear === '48'}
                                    onChange={this.onChangeYear}
                                  />
                                  <label htmlFor="fouryear">
                                    <p>4 {t('Year.1')}</p>
                                  </label>
                                </li>
                              </ul>
                            </div>

                            <label className="book-text text-black  d-flex justify-content-between">
                              <p className="mt-5 mb-2 font-14">
                                <b>{t('Number of Vehicles')}</b>
                              </p>
                              <h6 className="font-weight-bold mt-5">
                                {this.state.selectedVehicals}
                              </h6>
                            </label>
                            <div className="range-slider txt-blue">
                              <Slider
                                color="secondary"
                                max={this.state.maxVehicles}
                                step={1}
                                onChange={this.handleSliderChange}
                              />
                              <div className="d-flex justify-content-between pr-4">
                                <p>0</p>
                                <p>{this.state.maxVehicles}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group ">
                            <a
                              title="Submit Lease Enquiry"
                              className="submit-lease-enquiry"
                              onClick={this.onClick}
                            >
                              {t('Submit Lease Enquiry.1')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={this.state.showContact}
          onHide={() => this.toogleContactPopup(false)}
          dialogClassName="modal-dialog modal-xl modal-dialog-centered"
        >
          <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
        </Modal>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(CorporateLeaseBox)), CorporateLeaseBox);
