import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import award3 from '../assets/images/awards/3.jpg';
import isoCertificate from '../assets/images/awards/ISO-9001-2015-logo-1.png';
import largestwidest from '../assets/images/Business/1282773739.jfif';
import redCarpet from '../assets/images/Business/442582000.jfif';
import awardwinnig from '../assets/images/Business/450103363.jfif';
import dollarredCommunity from '../assets/images/Business/523670155.jfif';
import dollarHunt from '../assets/images/Business/705421648.jfif';
import globalpartner from '../assets/images/Business/know_us1.jfif';
import valueIcon from '../assets/images/core-values.png';
import missionIcon from '../assets/images/mission-icon.png';
import visionIcon from '../assets/images/vision-icon.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ContactUsBox from './ContactUsBox';
import SocialMediaBox from './SocialMediaBox';
var qs = require('qs');
class KnowUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    //alert(params.path);
    if (params.path != 'top') {
      scroller.scrollTo(params.path, {
        smooth: 'easeInOutQuint',
      });
    } else {
      scroll.scrollToTop();
    }
    this.setState({ name: params.path });
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        {this.state.name == 'top' ? (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Dollar Car Rental Oman | About Us</title>
              <meta
                name="description"
                content="Everyday, hundreds of tourists & residents in Oman choose Dollar Car Rental. We also takes pride in being associated with leading brands as their total transportation solutions partner. "
              ></meta>
              <meta property="og:title" content="Dollar Car Rental Oman | About Us"></meta>
              <meta property="og:url" content="https://www.dollaroman.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="Everyday, hundreds of tourists & residents in Oman choose Dollar Car Rental. We also takes pride in being associated with leading brands as their total transportation solutions partner. "
              ></meta>
            </Helmet>
          </>
        ) : this.state.name == 'vision' ? (
          <>
            <Helmet>
              <title>Dollar Car Rental Oman | Our Vision</title>
              <meta
                name="description"
                content="Dollar Car Rental Oman is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures.  "
              ></meta>
              <meta property="og:title" content="Dollar Car Rental Oman | Our Vision"></meta>
              <meta property="og:url" content="https://www.dollaroman.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="Dollar Car Rental Oman is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures.  "
              ></meta>
            </Helmet>
          </>
        ) : this.state.name == 'whydollar' ? (
          <>
            <Helmet>
              <title>Dollar Car Rental Oman | Why Choose Dollar? </title>
              <meta
                name="description"
                content="Dollar Car Rental Oman is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures!  "
              ></meta>
              <meta
                property="og:title"
                content="Dollar Car Rental Oman | Why Choose Dollar? "
              ></meta>
              <meta property="og:url" content="https://www.dollaroman.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="Dollar Car Rental Oman is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures!  "
              ></meta>
            </Helmet>
          </>
        ) : this.state.name == 'awardreco' ? (
          <>
            <Helmet>
              <title>Dollar Car Rental Oman | Awards and Recognition </title>
              <meta
                name="description"
                content="We pride ourselves in being a customer-centric brand, a committed employer and a responsible corporation. Have a look at our recognitions across the industry where we continue to leave an imprint."
              ></meta>
              <meta
                property="og:title"
                content="Dollar Car Rental Oman | Awards and Recognition "
              ></meta>
              <meta property="og:url" content="https://www.dollaroman.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="We pride ourselves in being a customer-centric brand, a committed employer and a responsible corporation. Have a look at our recognitions across the industry where we continue to leave an imprint."
              ></meta>
            </Helmet>
          </>
        ) : null}
        <ListingHeader />
        <div className="know-us-page py-md-5 py-3  bg-grey">
          <div className="container">
            <div className="border-titlec-content mb-5">
              <h2 className="mb-1">Dollar Car Rental Oman</h2>
              <p className="font-18 text-grey">
                A customer-centric brand, a committed employer and a responsible corporation.
              </p>
            </div>
            <div className="row pb-5">
              {/* <div className="col-lg-4 col-md-8 col-12 mx-auto mx-lg-0">
                            <img src={knowbanner} className="rounded-xl"></img>
                        </div> */}
              <div className="col-lg-12 col-12">
                <div className="pl-lg-4 pt-4 pt-lg-0">
                  <p className="font-18 text-grey">
                    A globally established brand - over 640 worldwide locations across 53 countries,
                    and a fleet size of approximately 200,000 vehicles - the foundation of Dollar
                    Car Rental is built on a philosophy of exceptional customer service. Our
                    attention to customer experience and our high quality fleet of rental vehicles
                    helps make both leisure vacation travel and business travel easier for our
                    customers.
                  </p>
                  <p className="font-18 text-grey mt-4">
                    In Oman, Dollar is a franchised operation owned by the prestigious{' '}
                    <a href="https://www.aaagroup.com/" target="_blank" className="text-red">
                      A.A.Al Moosa Enterprises,
                    </a>{' '}
                    under the license of Corniche Cars LLC, since 2007. Today, with over 6+
                    locations across the Oman, Dollar Car Rental is one of the region’s favorite and
                    leading car rental agency, with multiple awards & recognitions to its name.
                  </p>
                  <p className="font-18 text-grey mt-4">
                    Everyday, hundreds of tourists & residents in Oman choose Dollar Car Rental for
                    their mobility needs. Dollar Car Rental Oman also takes pride in being
                    associated with leading brands in Oman as their total transportation solutions
                    partner, by helping them achieve reduced fleet management costs, improved fleet
                    compliance, customized vehicle-builds, and other business-specific goals.
                  </p>
                  <p className="font-18 text-grey mt-4">
                    Our special rental car offers and low everyday rates assist you in keeping your
                    travel plans budget-friendly. Dollar Car Rental has convenient national and
                    worldwide car rental locations to serve you. In addition, our Dollar Hunt
                    program offers 365 days of assured deals, seasonal benefits & surprise rewards -
                    both online & offline!
                  </p>
                  <p className="font-18 text-grey mt-4">
                    Dollar Car Rental Oman has neighborhood and airport car rental locations in
                    Muscat, Salalah &amp; Sohar. To know your nearest location,{' '}
                    <a onClick={() => this.onMenuLinkClick('/locations')} className="text-red">
                      click here.
                    </a>
                  </p>
                  <p className="font-18 text-grey mt-4">
                    As an employer, our work culture amongst as a team remains the same as towards
                    our clients - one that of ensuring meaningful experiences, authenticity and
                    joyful relationships. We are an equal opportunity employer and promote
                    innovation, creativity, talent and collective growth. To know about the latest
                    job opportunities in Oman,{' '}
                    <a onClick={() => this.onMenuLinkClick('/jobposting')} className="text-red">
                      click here.
                    </a>
                  </p>
                  <p className="font-18 text-grey mt-4">We look forwarding to serving you soon.</p>
                </div>
              </div>
            </div>
          </div>
          <section className="bg-grey py-5" id="vision">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="knowUs-featured-wrap">
                    <div className="knowUs-featured-image">
                      <img src={visionIcon} alt={'Vision'}></img>
                    </div>
                    <div className="knowUs-featured-content">
                      <h4>Vision</h4>
                      <p>
                        Our vision is to become a customer centric company with focus on creating a
                        consistent and sustainable business growth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                  <div className="knowUs-featured-wrap">
                    <div className="knowUs-featured-image">
                      <img src={missionIcon} alt={'Mission'}></img>
                    </div>
                    <div className="knowUs-featured-content">
                      <h4>Mission</h4>
                      <p>
                        Our mission is to provide hassle free renting experience to our customers
                        through constant innovation and consistent delivery of quality product and a
                        great customer experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                  <div className="knowUs-featured-wrap">
                    <div className="knowUs-featured-image">
                      <img src={valueIcon} alt={'Core Values'}></img>
                    </div>
                    <div className="knowUs-featured-content">
                      <h4>Core Values</h4>
                      <p className="text-left">
                        <span className="text-red">●</span> Committed to give customer a delightful
                        renting experience <br></br>
                        <span className="text-red">●</span> Keep it easy and simple <br></br>
                        <span className="text-red">●</span> Integrity <br></br>
                        <span className="text-red">●</span> Innovative<br></br>
                        <span className="text-red">●</span> Humble
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5" id="whydollar">
            <div className="container">
              <h2 className="width-50">
                What makes Dollar your ultimate{' '}
                <span className="text-red" style={{ display: 'inline' }}>
                  R
                </span>
                ental{' '}
                <span className="text-red" style={{ display: 'inline' }}>
                  E
                </span>
                xperience{' '}
                <span className="text-red" style={{ display: 'inline' }}>
                  D
                </span>
                estination?
              </h2>
              <div className="width-75 pt-md-5 pt-4 pb-0 mx-auto">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={dollarHunt} className="rounded-xl" alt={'Global Road Trip'}></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">We are your Global Road Trip Partner!</h3>
                      <p className="font-18 text-grey pt-3">
                        Dollar Car Rental is a Global Brand with a Global Presence. Thousands of
                        people choose us everyday for their own everyday adventures. Be it business
                        trips, a squad on the road, family staycations or even a long drive getaway,
                        our wheels has seen it all.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-8 col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-lg-5 pr-0">
                      <h3 className="font-24">Award-winning Customer Experience</h3>
                      <p className="font-18 text-grey pt-3">We’re talking;</p>
                      <ul className="check-box-listing round-checkmark-wrap">
                        <li className="align-items-center">
                          <p className="font-18 text-black">
                            24*7 Customer Support including Call Centre, Customer & Reservations
                            Services.
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            Delivery & Pickup of your bookings at your time & location convenience.
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            Roadside Breakdown & Accidental Assistance.
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            Multiple locations around Oman for Ease of Access.
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            Dedicated Relationship Manager for our Corporate Clientele.
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            Trusted & relied by major brands in Oman as their mobility partner,
                            every single day.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={awardwinnig} className="rounded-xl" alt={'Award Winning'}></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={largestwidest} className="rounded-xl" alt={'Largest Widest'}></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">Largest & Widest</h3>
                      <p className="font-18 text-grey pt-3">
                        You get to choose from one of Oman’s largest and widest fleet. Be it the
                        choice of wheels or quick & easy availability, Dollar Car Rental is your
                        one-stop rental destination. So go ahead, plan that last-minute adventure -
                        and don’t you worry about those wheels no more!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-8 col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-lg-5 pr-0">
                      <h3 className="font-24">Dollar RED Carpet Services</h3>
                      <p className="font-18 text-grey pt-3">
                        Premium services for the celebrity you! Experience our award-winning range
                        of services promising convenience. With doorstep delivery & pickup services,
                        flexible locations of your choice, wide range of fleet options, dedicated
                        customer support managers and multiple counter solutions, a hassle-free
                        seamless car renting is now all yours!
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={redCarpet}
                      className="rounded-xl"
                      alt={'Dollar RED Carpet Service'}
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={globalpartner} className="rounded-xl" alt={'Dollar Hunt'}></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">Dollar Hunt</h3>
                      <p className="font-18 text-grey pt-3">
                        The best adventures are often the ones planned last minute.. And to enjoy
                        savings while at it? The definite cherry on top! Dollar Hunt is our
                        signature 365 days of offers and surprise deals! It’s your go-to destination
                        for hunting Oman’s finest car rental discount programs and promotions. Look
                        for our #dollarhunt flash sales & ‘offer of the day' to enjoy savings on the
                        road! Or simply ask our counter staff for your personalised surprise deal!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-8 col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-lg-5 pr-0">
                      <h3 className="font-24">Dollar RED Community</h3>
                      <p className="font-18 text-grey pt-3">
                        You get to be part of our Dollar Red Community. You, us, dollar employees,
                        our stakeholders, our corporate clientele, all of us - The Dollar Red
                        Community. With us, our clients rent wheels to their best road trips &
                        destinations. As team dollar, we strive to provide our best, with your
                        constant feedback & support. As a business organization, we venture out &
                        explore opportunities on the road together. We make things happen, together.
                        We connect. We move. We grow. Each other & in the process, ourselves.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={dollarredCommunity}
                      className="rounded-xl"
                      alt={'Dollar RED Community'}
                    ></img>
                  </div>
                </div>
                {/* <div className="row align-items-center pt-5">
                                <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                                    <img src={redPostcard}></img>
                                </div>
                                <div className="col-lg-8 col-md-7 col-12">
                                    <div className="oddEven-content pl-lg-5 pl-0">
                                        <h3 className="font-24">Dollar RED Postcard</h3>
                                        <p className="font-18 text-grey pt-3">You get to enjoy Oman’s most souvenir-worthy loyalty program every time you Dollar Car Rental Oman! Don’t forget to look for your Dollar RED Postcard when you step inside your next dollar rental car. These beautifully designed postcards are a mark of your loyalty and promises you assured offers, deep discounts or at times, even a surprise deal!</p>
                                        <p className="font-18 text-grey pt-3">For Press/Media/Analysis, <a onClick={() => this.onMenuLinkClick('/news')} className="text-red">click here.</a></p>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </section>
          <section className="bg-grey py-5" id="awardreco ">
            <div className="container">
              <div className="text-center">
                <h2>Awards & Recognitions</h2>
                <p className="font-18 text-grey mt-3">
                  Our achievements as a customer-centric brand, a committed employer & a responsible
                  corporation.
                </p>
              </div>
              <div className="row mt-5">
                {/* <div className="col-md-4 col-12">
                                <div className="awards-content text-center">
                                    <a href={award2} target="_blank">
                                        <img src={award1}></img>
                                    </a>
                                </div>
                            </div> */}
                {/* <div className="col-md-6 col-12 mt-5 mt-md-0">
                                <div className="awards-content text-center">
                                    <a href={documentfile1} target="_blank">
                                        <img src={document1}></img>
                                    </a>
                                </div>
                            </div> */}
                <div className="col-md-12 col-12 mt-5 mt-md-0">
                  <div className="awards-content text-center">
                    <a href={award3} target="_blank">
                      <img src={isoCertificate} alt={'Ios Certificate'}></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SocialMediaBox></SocialMediaBox>
          <ContactUsBox subHeading="" heading="Do you have further Question?"></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(KnowUs)), KnowUs);
