import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll, scroller } from 'react-scroll';
import ContactPopup from '../Common/ContactPopup';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import carrental from '../assets/images/Business/Car Rental Program For Businesses.jpg';
import { productInstance } from '../axios/axiosInstance';
import BusinessSolutionHeader from './BusinessSolutionHeader';
import ContactUsBox from './ContactUsBox';
import CorporateLeaseBox from './CorporateLeaseBox';
import KeyPointDollar from './KeyPointDollar';
import TransportFleetMenuBox from './TransportFleetMenuBox';
import VehicleByVehicleTypes from './VehicleByVehicleTypes';
class BusinessSolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }
  componentDidMount = () => {
    var hasMatchProperty = this.props.hasOwnProperty('match');
    if (hasMatchProperty) {
      const {
        match: { params },
      } = this.props;
      if (params.path != 'top') {
        scroller.scrollTo(params.path, {
          smooth: 'easeInOutQuint',
        });
      } else {
        scroll.scrollToTop();
      }
      if (params.path != 'top') {
        scroller.scrollTo(params.path, {
          smooth: 'easeInOutQuint',
        });
      } else {
        scroll.scrollToTop();
      }
    }

    this.getVehicleTypes();

    scroll.scrollToTop();
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });
      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onLeasOptionClick = (maxVehicles) => {
    this.setState({ maxVehicles: maxVehicles });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mobility & Transportation Solutions for All Your Business' Needs</title>
          <meta
            name="description"
            content="Explore a range of commercial vehicles tailored to meet your need. We proudly host Oman's largest & widest transportation fleet. "
          ></meta>
          <meta
            property="og:title"
            content="Mobility & Transportation Solutions for All Your Business' Needs"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/business-solutions"></meta>
          <meta
            property="og:description"
            content="Explore a range of commercial vehicles tailored to meet your need. We proudly host Oman’s largest & widest transportation fleet."
          ></meta>
        </Helmet>
        <ListingHeader></ListingHeader>
        <div className="minH100 bg-grey">
          <BusinessSolutionHeader></BusinessSolutionHeader>

          <TransportFleetMenuBox></TransportFleetMenuBox>

          <KeyPointDollar></KeyPointDollar>

          <CorporateLeaseBox></CorporateLeaseBox>

          <section className="box-shadow-white-box-section bg-grey py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a mt-0">
                <div className="">
                  <div className="row  flex-reversea">
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 ">
                      <div className="pickup-location-box bottomAlignContent">
                        <h5 className="font-bold mb-3">{t('Car Rental Program For Businesses')}</h5>
                        <p class="text_p mt-3">
                          Enjoy discounted corporate rates when you choose Dollar Car Rental Oman.
                          With locations across Oman, including all major airports, we promise a
                          hassle-free travel & uncompromising convenience. Choose from a wide range
                          of fleet portfolio, custom add on personalizations & get easy access to
                          tracking solutions.
                        </p>
                        <p class="text_p mt-3 mb-3">
                          Our corporate leasing works on a pay-for-usage model, rather than fleet
                          ownership. This allows our clients to use vehicles, based on their
                          requirements with simple straightforward lease rentals, while not having
                          to worry about the hassles of fleet management, servicing, insurance,
                          damage repair, registration rentals, replacement vehicles, and end-of-term
                          sales. Contact us today to know more.
                        </p>
                        <a
                          onClick={this.onClick}
                          className="primary-bttn primary-invert-bttn text-center mt-xl-auto mt-3"
                          title="   Book Now"
                        >
                          {t('Submit Enquiry')}
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 mt-4 mt-sm-0">
                      <img src={carrental} className="img-fluid rounded-xl" />
                    </div>
                    <Modal
                      show={this.state.showContact}
                      onHide={() => this.toogleContactPopup(false)}
                      dialogClassName="modal-dialog modal-xl modal-dialog-centered"
                    >
                      <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <VehicleByVehicleTypes vehicleClassificationID="f2a4bbb4-bfd8-4285-bbfc-df5b00567571"></VehicleByVehicleTypes>
          <div className="container-fluid mb-4">
            <div className="text-center">
              <a
                onClick={this.onClick}
                className="primary-bttn primary-invert-bttn text-center mt-xl-auto mt-3"
                title="   Book Now"
              >
                {t('Submit Inquiry.1')}
              </a>
            </div>
          </div>
          <ContactUsBox
            subHeading="To know further on how you can manage your fleet more efficiently and other business solutions,
contact us today."
            heading="We look forward to serving you."
          ></ContactUsBox>
        </div>
        <ListingFooter></ListingFooter>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(BusinessSolutions), BusinessSolutions);
