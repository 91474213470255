import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import Login from '../Account/LoginPopup';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import TrueImage from '../assets/images/True.svg';
import email2 from '../assets/images/email2.svg';
import phonealt3 from '../assets/images/phone-alt3.svg';
import { contentInstance, userInstance } from '../axios/axiosInstance';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BannerData: [],
      email: '',
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      contactNo: '',
      loading: false,
      errorMessage: '',
      isChecked: false,
      isSubscribe: true,
    };
  }
  onTermsClick = () => {
    // this.props.history.push("terms");
    window.open('/termsandconditions');
  };

  componentDidMount = () => {
    this.setState({ loading: false });
    this.getBanner();
  };

  onSubscribe = (e) => {
    let isSubscribe = this.state.isSubscribe;
    this.setState({ isSubscribe: !isSubscribe });
  };

  onTermChange = (e) => {
    let isChecked = this.state.isChecked;
    this.setState({ isChecked: !isChecked });
  };
  getBanner = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    contentInstance
      .get(`banners/allUi?countryCode=${code}&placement=${2}`)
      .then((response) => {
        this.setState({ BannerData: response.data.result });
      })
      .catch((error) => {});
  };

  validateRegisterInfo = () => {
    let lngParam = getLanguage();

    const valid = true;
    if (this.state.firstName == undefined || this.state.firstName === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The username field is required.' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل اسم المستخدم',
        });
      }

      return false;
    }
    if (this.state.contactNo == undefined || this.state.contactNo === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The contact number field is required.' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب مجال ContactNo',
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The email is required.' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }

      return false;
    }

    // if (this.state.password == undefined || this.state.password === '') {

    //   if (lngParam == "en") {
    //     this.setState({ errorMessage: 'The password is required' });
    //   }
    //   else {
    //     //ar
    //     this.setState({
    //       errorMessage: "مطلوب حقل كلمة المرور"
    //     });
    //   }
    //   return false;
    // }

    if (this.state.isChecked == false) {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please accept the terms and condition' });
      } else {
        //ar
        this.setState({
          errorMessage: 'يرجى قبول الشروط والأحكام',
        });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }

    this.setState({ errorMessage: '' });
    return valid;
  };

  registerClickHandler = () => {
    if (this.validateRegisterInfo()) {
      this.setState({ loading: true });
      const { firstName, lastName, email, contactNo, isSubscribe } = this.state;
      userInstance
        .post('ui', {
          FirstName: firstName,
          LastName: lastName,
          EmailId: email,
          ContactNo: contactNo,
          IsSubscribe: isSubscribe,
        })
        .then((response) => {
          if (response.data.result != null && response.data.message != null) {
            this.handleRedirect(response.data.message);
          }
        })
        .catch((error) => {
          this.handleRedirect(error.response.data.message);
        });
    }
  };

  handleRedirect = (message) => {
    localStorage.setItem('thanksmessage', message);
    this.props.history.push('/thankyou');
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  toogleLoginNowPopup = (showHide) => {
    this.setState({
      showLoginNow: showHide,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader></ListingHeader>
        <div className="minH100 register-form-page bg-grey">
          <div className="container-fluid" id="LoginFormPopup">
            <div className="register-container">
              <div className="row  register-first-row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 register-card">
                  <div className="form-register">
                    <div className="">
                      <h2 className="border-botom">{t('Register.1')}</h2>
                      <p className="mb-3">{t('Just enter your email below.1')}.</p>

                      <label className="d-flex">
                        <label>
                          <img
                            src={TrueImage}
                            className="img-size mt-0"
                            alt="Dollar User"
                            style={{ width: '15px!important' }}
                          />
                        </label>
                        {t(
                          'View your booking history & manage your personal information for all dollar services.1',
                        )}
                      </label>
                    </div>

                    <div className="form-group">
                      <div className="d-flex nameSelection">
                        <div className="mr-3">
                          <label htmlFor="exampleInputUsername">{t('First Name.1')}</label>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="firtnameHelp"
                            placeholder={t('Enter First Name.1')}
                            id="firstName"
                            name="firstName"
                            onChange={this.onChange}
                            value={this.state.firstName}
                          />
                        </div>
                        <div>
                          <label htmlFor="exampleInputUsername">{t('Last Name.1')}</label>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="lastnameHelp"
                            placeholder={t('Enter Last Name.1')}
                            id="lastName"
                            name="lastName"
                            onChange={this.onChange}
                            value={this.state.lastName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputcontactNo">{t('Contact No.1')}.</label>
                      <div className="position-relative">
                        <img src={phonealt3} className="img-size" alt="Dollar User Contact" />
                        <input
                          type="tel"
                          className="contact-icon form-control"
                          placeholder={t('Enter Contact no..1')}
                          id="contactNo"
                          name="contactNo"
                          onChange={this.onChange}
                          value={this.state.contactNo}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t('Email Address.1')}</label>
                      <div className="position-relative">
                        <img src={email2} className="img-size" alt="Dollar User Email" />
                        <input
                          type="email"
                          className="email-icon  form-control"
                          aria-describedby="emailHelp"
                          placeholder={t('Enter email.1')}
                          id="email"
                          name="email"
                          onChange={this.onChange}
                          value={this.state.email}
                        />
                      </div>{' '}
                    </div>

                    {/* <div className="form-group mb-0">
                    <label htmlFor="exampleInputPassword">{t('Password.1')}</label>
                    <div className="position-relative">
                      <img src={padlock} className="img-size" />
                      <input type="password" className="form-control " aria-describedby="emailHelp" placeholder={t('Enter Password.1')} id="password" name="password" onChange={this.onChange} value={this.state.password} />
                    </div>  </div>
                  <br></br> */}

                    {/* <h6 style={{ color: '#C8102E' }}>Terms</h6> */}
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck"
                        name="example1"
                        checked={this.state.isSubscribe}
                        onChange={this.onSubscribe}
                      />
                      <label className="custom-control-label text-grey" htmlFor="customCheck">
                        {' '}
                        {t('Sign up for Dollar Special offers & newsletters.1')}
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox ">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customChecka"
                        name="example1"
                        checked={this.state.isChecked}
                        onChange={this.onTermChange}
                      />
                      <label className="custom-control-label text-grey" htmlFor="customChecka">
                        {t('I have read & accept.1')}{' '}
                        <a className="text-red" onClick={this.onTermsClick}>
                          {t('Dollar Terms & Conditions.1')}
                        </a>{' '}
                      </label>
                    </div>

                    <br></br>
                    <div className="form-row justify-content-between">
                      <div className="col-auto">
                        {this.state.loading == false ? (
                          <button
                            type="submit"
                            className="primary-bttn primary-invert-bttn"
                            onClick={this.registerClickHandler}
                          >
                            {t('Register.1')}
                          </button>
                        ) : null}
                      </div>
                      <div className="col-auto">
                        <button class="primary-bttn" onClick={() => this.toogleLoginNowPopup(true)}>
                          Sign in
                        </button>
                      </div>
                    </div>
                    {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                      <Dialog
                        open={this.state.errorMessage.length > 0}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {this.state.errorMessage}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.handleClose} color="primary">
                            {t('OK.1')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    ) : null}
                    <div className="col-md-10">
                      {this.state.loading == true ? (
                        <div style={{ verticalAlign: 'center' }}>
                          <Spinner animation="border" />
                          &nbsp;{t('Registering user.1')}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="row mt-4">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 span-notreg">
                      <span className="or-login-with-text span-already-have-account-login">{t('Or register with.1')}</span>
                    </div>

                  </div> */}

                    {/* <div className="row socialbtn-row mt-2">
                    <div className="col-6 col-lg-16 col-md-6 col-sm-6">
                      <div className="socialbtn fb-btn">
                        <img src={FaceBook} alt="" />  <a href="#">Facebook</a>
                      </div>
                    </div>

                    <div className="col-6 col-lg-16 col-md-6 col-sm-6">
                      <div className="socialbtn gl-btn">
                        <img src={Google} alt="" />
                        <a href="#">{t('Google.1')}</a>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 reg-main-img">
                  <div className="d-none d-sm-none d-md-block d-lg-block">
                    {this.state.BannerData[0] != null ? (
                      <img
                        src={this.state.BannerData[0].BannerAttachment.ImageString}
                        alt="Dollar Banner"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoginNow ? (
          <div className="modal-content">
            <Modal
              show={this.state.showLoginNow}
              dialogClassName="modal-dialog  modal-md"
              onHide={() => this.toogleLoginNowPopup(false)}
            >
              <Login showHeader={true} />
            </Modal>
          </div>
        ) : null}
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(Register)), Register);
