import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getCurrency } from '../Common/Util';
import VehicleFeatureBox from '../Common/VehicleFeatureBox';
import UpgradeOption from './UpgradeOption';

class ListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpgrade: false,
      hideUpgradeOption: false,
      currencyCode: '',
      imageList: [],
      currentIndex: 0,
      manageSlide: true,
    };
  }

  componentDidMount() {
    this.setState({ currencyCode: getCurrency(), imageList: this.props.model.VehicleImages });
  }

  onBookNowClick = () => {
    this.props.onBookNowClick();
  };

  toogleUpgradeOptionPopup = (showHide) => {
    this.setState({
      showUpgrade: showHide,
    });
  };

  toogleIncludeInline = (showHide1) => {
    if (this.props.hideInclude == 0) {
      if (this.props.vehicleIndex == this.props.selectedVehicleIndex) {
        this.props.onVehicleIndexChange(9999, {});
      } else {
        this.props.onVehicleIndexChange(this.props.vehicleIndex, this.props.model);
      }
    }
  };

  onBookShowHandler = (paySelection) => {
    this.props.onVehicleChange(this.props.model, paySelection);

    this.setState({
      showUpgrade: false,
    });
  };

  onBookUpgradeHandler = (
    upgradeModel,
    originalVehicleModelID,
    originalTariffGroupID,
    originalTariffRateID,
  ) => {
    this.props.onUpgradeModelSelected(
      upgradeModel,
      originalVehicleModelID,
      originalTariffGroupID,
      originalTariffRateID,
    );
    this.setState({
      showUpgrade: false,
    });
  };

  onBookNowClickHandler = (paySectionValue) => {
    // Set the paySectionValue
    this.setState(
      {
        paySectionValue: paySectionValue,
      },
      () => {
        // Callback function after setting state
        // Call onVehicleChange with the updated paySectionValue
        this.props.onVehicleChange(this.props.model, this.state.paySectionValue);
      },
    );
  };

  handleNextImage = () => {
    const { currentIndex, imageList } = this.state;
    const nextIndex = (currentIndex + 1) % imageList.length;
    this.setState({ currentIndex: nextIndex, manageSlide: true });
  };

  handlePrevImage = () => {
    const { currentIndex, imageList } = this.state;
    const prevIndex = (currentIndex - 1 + imageList.length) % imageList.length;
    this.setState({ currentIndex: prevIndex, manageSlide: false });
  };

  render() {
    const { t, shouldRenderBtn } = this.props;

    const PayNowTotal = (this.props.model.RentalCharges.PayNowTotal * 1.05).toFixed(2);
    const RackRateTotal = (this.props.model.RentalCharges.RackRateTotal * 1.05).toFixed(2);
    // let chargeP = (

    //     <p style={{ textDecoration: 'line-through', color: 'black' }}>{t('OMR.1')} <span style={{ textDecoration: 'line-through', color: '#006FF7' }}>{this.props.model.RentalCharges.RackRate}</span>/{t('day.1')}</p>
    // );
    return (
      <>
        <div
          className={
            this.props.vehicleIndex == this.props.selectedVehicleIndex
              ? this.props.model.IsSoldOut
                ? 'vehicle-box-design selected vehicle-sold-out'
                : this.props.model.IsOriginalModelWhileModify
                  ? 'vehicle-box-design selected vehilce-current-selected'
                  : 'vehicle-box-design selected'
              : this.props.model.IsSoldOut
                ? 'vehicle-box-design vehicle-sold-out'
                : this.props.model.IsOriginalModelWhileModify
                  ? 'vehicle-box-design vehilce-current-selected'
                  : 'vehicle-box-design'
          }
        >
          <h3 style={{ textAlign: 'left' }}>{this.props.model.Name}</h3>
          <span className="similar-text">
            {t('or Similar.1')} | {this.props.model.VehicleClassificationName}
          </span>
          {this.props.model.FavouriteNote.length > 0 && this.props.hideUpgradeOptions == 0 ? (
            <div className="important-info-start-bg-stick">
              <i className="fa fa-star"></i>
              {this.props.model.FavouriteNote}
            </div>
          ) : (
            ''
          )}

          {this.props.model.IsOriginalModelWhileModify == true ? (
            <div className="current-selection-box">{t('Current.1')}</div>
          ) : (
            ''
          )}

          <div className="vehicle-box-design-img-wrap img-details-wrap">
            <img src={this.state.imageList[0]} alt={this.props.model.Name} />
          </div>

          <div className="charges-option-up">
            {this.props.showDiffPrice == 1 ? (
              this.props.rateDifference == 0 ? (
                <p>
                  {' '}
                  <span className="free-upgrade-span">{t('Free Upgrade.1')}</span>{' '}
                </p>
              ) : (
                <p>
                  {this.state.currencyCode} <span>{this.props.rateDifference}</span>
                  {t('Extra.1')}
                </p>
              )
            ) : this.props.model.RentalCharges.PayNowTotal == 0 ? null : (
              <>
                {/* <p>{this.state.currencyCode} <span>{PayNowTotal}</span> | {this.props.days} {t('days.1')}</p> */}
                {/* <p>For {this.props.days} { this.props.days === 1 ? t('day.1') : t('days.1')}</p> */}
              </>
            )}

            {/* {this.props.hideUpgradeOptions == 0  ? (this.props.model.SuggestedUpgradeModelIDs.length != 0) ?
                            <div className="important-info-start-bg-stick">
                                <a href="" title={t('Upgrade Options.1')} data-toggle="modal" data-target="#exampleModal" onClick={() => this.toogleUpgradeOptionPopup(true)}>{t('Upgrade Options.1')}</a></div> : ""
                            
                            : null
                        } */}
          </div>

          {this.props.hideInclude == 0 && this.props.model.IsRestrictBooking == false ? (
            <VehicleFeatureBox model={this.props.model} />
          ) : (
            ''
          )}

          {this.props.showBookNow == 1 && this.props.model.IsRestrictBooking == false ? (
            <div style={{ width: '100%' }}>
              <div className="text-center mt-4 prestige-car-box-bttn">
                <a
                  onClick={() => {
                    this.onBookNowClick();
                  }}
                  title={t('Book Now.1')}
                  className="vehicle-box-design-book"
                  tabIndex="0"
                >
                  {t('Book Now.1')}
                </a>
              </div>{' '}
            </div>
          ) : null}

          {shouldRenderBtn && (
            <div className="book-now-all-bttns-wrap book-now-all-bttns-cr mt-3">
              {this.props.model.IsSoldOut ? (
                this.props.model.IsCallToBook ? (
                  <a
                    title="Book Now"
                    className="pay-button pay-now-button"
                    style={{ cursor: 'not-allowed' }}
                  >
                    {t('Call Now.1')} - 800 4710
                  </a>
                ) : (
                  <>
                    <button
                      className="pay-button pay-now-button"
                      // disabled={!this.state.isCheked}
                      onClick={() => this.onBookNowClickHandler('paynow')}
                    >
                      <span className="button-text">{t('Pay Now.1')}</span>
                      <span className="button-amount">
                        {this.state.currencyCode} {PayNowTotal} | {this.props.days}{' '}
                        {this.props.days === 1 ? t('day.1') : t('days.1')}{' '}
                      </span>
                    </button>
                    <button
                      className="pay-button pay-later-button"
                      // disabled={this.state.isCheked}
                      onClick={() => this.onBookNowClickHandler('payletter')}
                    >
                      <span className="button-text">{t('Pay Later.1')}</span>
                      <span className="button-amount">
                        {this.state.currencyCode} {RackRateTotal} | {this.props.days}{' '}
                        {this.props.days === 1 ? t('day.1') : t('days.1')}
                      </span>
                    </button>
                  </>
                )
              ) : this.props.model.IsCallToBook ? (
                <a title="Book Now" className="pay-button pay-now-button" href={'tel:+97143365065'}>
                  {t('Call Now.1')} - +971 4 336 5065
                </a>
              ) : (
                <>
                  {this.props.showPayNowLaterBtn === true ? (
                    <button
                      className="pay-button pay-now-button"
                      // disabled={!this.state.isCheked}
                      onClick={() => this.onBookNowClickHandler('paynow')}
                    >
                      <span className="button-text">{t('Pay Now.1')}</span>
                      <span className="button-amount">
                        {' '}
                        {PayNowTotal} | {this.props.days}{' '}
                        {this.props.days === 1 ? t('day.1') : t('days.1')}{' '}
                      </span>
                    </button>
                  ) : null}
                  <button
                    className="pay-button pay-later-button"
                    // disabled={this.state.isCheked}
                    onClick={() => this.onBookNowClickHandler('payletter')}
                  >
                    <span className="button-text">{t('Pay Later.1')}</span>
                    <span className="button-amount">
                      {' '}
                      {RackRateTotal} | {this.props.days}{' '}
                      {this.props.days === 1 ? t('day.1') : t('days.1')}
                    </span>
                  </button>
                </>
              )}
            </div>
          )}

          <p className="tax-included">Prices are inclusive of VAT.</p>
        </div>

        <Modal
          show={this.state.showUpgrade}
          onHide={() => this.toogleUpgradeOptionPopup(false)}
          dialogClassName="modal-dialog modal-xl modal-dialog-centered"
        >
          <UpgradeOption
            model={this.props.model}
            searchParam={this.props.searchParam}
            BookShow={() => this.onBookShowHandler()}
            BookUpgrade={this.onBookUpgradeHandler}
            CloseUpgradePopup={this.toogleUpgradeOptionPopup}
          />
        </Modal>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(ListingCard), ListingCard);
