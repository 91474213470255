import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ZeroImage from '../assets/images/Business/394268698.jfif';
import Group73 from '../assets/images/Business/431848417.jfif';
import Truck from '../assets/images/Business/Toyota hiace 3-1.jpg';

class BusinessMobilityBox extends Component {
  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a mt-3" id="partner">
              <div className="pad-30">
                <div className="row">
                  <div className="col-12">
                    <div className="pickup-location-box">
                      <h5 className="font-bold mb-3">
                        <center>Budget-friendly Business Mobility</center>
                      </h5>

                      <h6>
                        <center>Your keys to efficiency, savings & growth.</center>
                      </h6>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4" id="truckrental" name="truckrental">
                    <div className="fotter-link-box">
                      <img src={Truck} className="rounded-xl" alt={'Fleet Managment Solutions'} />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-2 mt-2 w-100">Fleet Management Solutions</h3>
                        <span>
                          Dollar Car Rental is a leading Fleet Management Solutions provider in
                          Oman. We take pride in serving major brands in the country, who choose to
                          trust us every single day with their mobility needs. This includes using
                          our up-to-date expert guidance on the management of their fleet -
                          acquisition, resale, maintenance, vehicle tracking, fuel, accident
                          management & more.
                        </span>
                      </div>
                      <a
                        className="red-link-btn mt-auto"
                        alt="View More"
                        onClick={() => this.onMenuLinkClick('/fleetmanagementsolutions')}
                      >
                        View More<i class="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                    <div className="fotter-link-box">
                      <img src={ZeroImage} className="rounded-xl" alt={'Monthly Rentals'} />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-2 mt-2 w-100">Monthly Rentals</h3>
                        <span>
                          Save more when you choose monthly car rentals over daily & weekly rentals.
                          With our easy & flexible payment modes, enjoy monthly prices cheaper than
                          conventional car purchasing budgets.
                        </span>
                        <span>
                          Book Now or Contact us to know more or better yet visit any of our
                          neighborhood locations to experience Dollar today.
                        </span>
                      </div>
                      <a
                        className="red-link-btn mt-auto"
                        onClick={() => this.onMenuLinkClick('/monthlyrentals')}
                      >
                        View More<i class="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                    <div className="fotter-link-box">
                      <img src={Group73} className="rounded-xl" alt={'Business Solutions'} />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-2 mt-2 w-100">Business Solutions</h3>
                        <span>
                          Let our experts support you with scalable vehicle acquisitions and fleet
                          management solutions to ensure significant cost savings, reduced
                          administrative hassles, increased productivity, and risk mitigation within
                          your business. Get personalized assessments & guidance on corporate
                          rentals, fleet acquisition, resale, maintenance, vehicle tracking, fuel,
                          accident management & more.
                        </span>
                      </div>
                      <a
                        className="red-link-btn mt-auto"
                        onClick={() => this.onMenuLinkClick('/business')}
                      >
                        View More<i class="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(withRouter(BusinessMobilityBox)),
  BusinessMobilityBox,
);
