import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import commercialVehicle from '../assets/images/Business/CommercialVehicle3.png';
import FleetManagementVehicle from '../assets/images/Business/FleetManagmentVehicle3.jpg';
import speciallizedVehicle from '../assets/images/Business/SpeciallizedVehicle3.jpg';

class TransportFleetMenuBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a mt-0" id="partner">
              <div className="pad-30">
                <div className="row">
                  <div className="col-12">
                    <div className="pickup-location-box">
                      <h5 className="font-bold mb-3 text-center">
                        We host Oman’s largest & widest fleet, for all your transportation needs
                      </h5>

                      <p className="text-center font-18 text-grey">
                        Dollar Car Rental Oman takes pride in being associated with leading brands
                        in Oman as their total transportation solutions partner, by helping them
                        achieve reduced fleet management costs, improved fleet compliance,
                        customized vehicle-builds, and other business-specific goals.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4" id="truckrental" name="truckrental">
                    <img
                      src={commercialVehicle}
                      className="rounded-xl imageAdjust"
                      alt={'Commercial Vehicles'}
                    />
                    <div className="pickup-location-flex-content">
                      <div className="mb-4 mt-2 d-flex align-items-center flex-wrap justify-content-between">
                        <h3 className="text-sm-center">Commercial Vehicles</h3>
                        <a
                          className="red-link-btn"
                          onClick={() => this.onMenuLinkClick('/explorecorporatefleet')}
                        >
                          View More<i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <img
                      src={speciallizedVehicle}
                      className="rounded-xl imageAdjust"
                      alt={'Specialized Vehicles'}
                    />
                    <div className="pickup-location-flex-content">
                      <div className="mb-4 mt-2  d-flex align-items-center flex-wrap justify-content-between">
                        <h3 className="text-sm-center">Specialized Business Vehicles</h3>
                        <a
                          className="red-link-btn"
                          onClick={() => this.onMenuLinkClick('/specializedvehicles')}
                        >
                          View More<i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-12 col-lg-6">
                              <img src={PersonaLeaseVehicle}  className="rounded-xl imageAdjust"/>
                              <div className="pickup-location-flex-content">
                                 <div className="mb-4 mt-2  d-flex align-items-center flex-wrap justify-content-between">
                                    <h3 className="text-sm-center">Personal lease.</h3>
                                    <a className="red-link-btn" onClick={() => this.onMenuLinkClick('/personalleasing')}>View More<i className="fa fa-angle-right"></i></a>
                                 </div>
                              </div>
                           </div> */}
                  <div className="col-sm-12 col-lg-4">
                    <img
                      src={FleetManagementVehicle}
                      className="rounded-xl imageAdjust"
                      alt={'Fleet Management Solutions'}
                    />
                    <div className="pickup-location-flex-content">
                      <div className="mb-4 mt-2  d-flex align-items-center flex-wrap justify-content-between">
                        <h3 className="text-sm-center">Fleet Management Solutions</h3>
                        <a
                          className="red-link-btn"
                          onClick={() => this.onMenuLinkClick('/fleetmanagementsolutions')}
                        >
                          View More<i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal show={this.state.showContact} onHide={() => this.toogleContactPopup(false)} dialogClassName="modal-dialog modal-xl modal-dialog-centered">
                    <ContactPopup leasingEnquiry = {this.state.leasingEnquiry} />
                </Modal> */}
        </section>
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(withRouter(TransportFleetMenuBox)),
  TransportFleetMenuBox,
);
