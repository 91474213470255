import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import rentalpolicy from '../assets/images/rental-policies-icon.svg';
import ViewIncludeLongText from './ViewIncludeLongText';

var qs = require('qs');
class RentalPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExtrasDetails: false,
      loadingText: '',
      LongTextShow: '',
    };
  }

  componentDidMount = () => {};

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: LongText,
    });
  };
  toogleViewExtrasPopUp = (showHide, extrasDesriptionToShow) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: extrasDesriptionToShow,
    });
  };

  onAdditionalDetails = (type) => {
    let textToShow = '';
    this.setState({
      showExtrasDetails: true,
    });

    if (type == 'DCR') {
      textToShow += "<div class='tab-term-content'>";
      textToShow +=
        '<p>All renters must be able to provide a valid driver’s license, passport copy with visa page and authorized credit card under the main Driver Name.</p>';
      textToShow += '<p>All drivers must carry a locally accepted driving license.</p>';
      textToShow += '<h5>Oman RESIDENTS</h5>';
      textToShow +=
        '<p>A valid Oman driving license is a must. Please note that driving without a Valid Oman driving license when on residence or employment visa is a violation of the LAW.</p>';
      textToShow += '<h5>NON-RESIDENTS</h5>';
      textToShow +=
        '<p>Visitors are encouraged to have an International Driving License/Permit prior from arrival to Oman. Customers from the following countries can drive in Oman without an International Driving Permit (IDP); however, must hold the passport and driver’s license from the same country: <b>GCC Countries</b>: Saudi Arabia, Oman, Bahrain, Kuwait, Qatar  <b>European Countries</b>: Germany, Italy, Switzerland, Poland, Slovakia, Finland, Spain, Netherlands, Greece, Sweden, Belgium, Ireland, Turkey, Denmark, Portugal, Austria, France, United Kingdom, Norway, Romania, Luxemburg, Latvia, Serbia, Lithuania, Bulgaria <b>North American Countries</b>: United States of America, Quebec State in Canada. <b>African Country</b>: South Africa.</p>';
      textToShow +=
        'International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.';
      textToShow += '</div>';
    } else if (type == 'ADD') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Additional Driver</h5>';
      textToShow +=
        '<p>The hirer may put on an extra driver with additional cost of RO 3.15, provided that he fulfills all necessary requirements.</p>';
      textToShow += '</div>';
    } else if (type == 'INS') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Insurance Excess</h5>';
      textToShow +=
        '-<p>Deductible amount charged where hirer is at fault or provided a police report under hit and run case:</p>';
      textToShow += '<p><p><strong>RO 131.25 & RO 157.50</strong> Depends on Car Category</p>';
      textToShow +=
        '<p><strong>Value Added Tax (VAT)</strong> Dollar Rent A Car reserves the right to add VAT as confirmed and implemented by Sultanate Oman Government with effect on April 2021. This is applicable for all bookings for pick-up from 16 April 2021</p>';
      textToShow += '</div>';
    } else if (type == 'MAL') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Age Restrictions</h5>';
      textToShow += '<p>Minimum age requirement is 22 years.</p>';
      textToShow += '</div>';
    } else if (type == 'FP') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Fuel Policy</h5>';
      textToShow +=
        '<p>The hirer should return the vehicle with the same fuel level as given by the company. If not, the hirer will be charged the cost of fuel plus a service charge.</p>';
      textToShow += '</div>';
    } else if (type == 'ST') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Salik (Toll Fee)</h5>';
      textToShow +=
        '<p>•	All of our cars are installed with SALIK sticker which will be OMR 5 Excl. VAT per passage at the SALIK toll gates.</p>';
      textToShow += '</div>';
    } else if (type == 'ORC') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Off-Road Liability/Charges</h5>';
      textToShow +=
        '<p>Insurance does not cover off road usage of the vehicle and the customer will be liable for damage caused and charges arising out of such use.</p>';
      textToShow += '</div>';
    } else if (type == 'TV') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Traffic Violations</h5>';
      textToShow +=
        '<p>Drivers are expected to abide with the traffic rules and regulations. For every traffic fine incurred will be charged to the hirer with additional 10% service charge.</p>';
      textToShow += '</div>';
    } else if (type == 'DCC') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Delivery and Collection</h5>';
      textToShow +=
        '<p>Not to put you through inconvenience we can deliver and collect vehicles right at your doorsteps for a mild charge per transaction in Muscat City or Salalah City limit between 08:30am to 05:00pm.</p>';
      textToShow += '<p>One way Service :-Domestic :</p>';
      textToShow += 'One way drop off charges from  Muscat to Salalah: RO 157.50/-</p>';
      textToShow += 'One way drop off charges from Muscat to Sohar: RO 52.50/-</p>';
      textToShow += 'One way drop off charges from Sohar to Muscat: RO 52.50/-</p>';
      textToShow += 'One way drop off charges from Salalah to Muscat: RO 157.50/-</p>';
      textToShow += 'One way drop off charges from Salalah to Sohar: RO 210/-</p>';
      textToShow += 'One way drop off charges from Sohar to Salalah: RO 210/-</p>';
      textToShow += 'One way drop off charges for Nizwa: RO 42/-</p>';
      textToShow += 'Other places on request.</p>';
      textToShow += 'One way Service :   (Delivery into other country)</p>';
      textToShow += 'Muscat/Salalah OMR one way drop off charges RO 147/-</p>';
      textToShow += 'Abu Dhabi - OMR one way drop off charges RO 183.75/-</p>';
      textToShow += '</div>';
    } else if (type == 'LR') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Late Return</h5>';
      textToShow +=
        '<p>Only 1 hour grace period is allowed. Beyond 1 hour, full-day charges as per the tariff card will be charged directly from the hirer.</p>';
      textToShow += '</div>';
    } else if (type == 'M') {
      if (this.props.rentalType == 3) {
        textToShow += "<div class='tab-term-content'>";
        textToShow += '<h5>Mileage Restriction</h5>';
        textToShow += '<p><strong>Standard Vehicles :</strong> RO 0.042 for every extra km</p>';
        textToShow += '<p><strong>2WD/4WD Vehicles :</strong> RO 0.063 for every extra km</p>';
        textToShow += '</div>';
      } else {
        textToShow += "<div class='tab-term-content'>";
        textToShow += '<h5>Mileage Restriction</h5>';
        textToShow +=
          '<p><strong>Standard Vehicles :</strong> Daily: 200km/day; Weekly: 1400km/week; Monthly: 4000km/month. RO 0.042 for every extra km</p>';
        textToShow +=
          '<p><strong>2WD/4WD Vehicles :</strong> Daily: 200km/day; Weekly: 1400km/week; Monthly: 4000km/month. RO 0.063 for every extra km</p>';
        textToShow += '</div>';
      }
    } else if (type == 'CP') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Cancellation:</h5>';
      textToShow +=
        '<p>1. Customers may cancel a pre-paid booking with an administration fee of OMR : 5.000 if Dollar Rent A Car OMAN is advised 48 hours prior to pick up date.</p>';
      textToShow +=
        '<p>2. For cancellations of rentals with less than 48 hours’ notice, Dollar Rent A Car will charge one day rental of the reservation and an Administration fee of OMR : 5.000.</p>';
      textToShow +=
        '<p>3. Refunds will be made to the credit card that the original booking was made on within 21 working days</p>';
      textToShow +=
        "<p>4. Any booking that is cancelled on the same day of pick-up due to invalid documents, etc., will be considered as cancelled less than 48 hours' notice. Hence, Dollar Rent A Car will charge one day rental of the reservation and an Administration fee of OMR : 5.000</p>";
      textToShow += '</div>';
    } else if (type == 'ER') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Early Return/ No Shows</h5>';
      textToShow +=
        '<p>1. There will be no refund for unused car rental days on a prepaid rental, once the rental has started.</p>';
      textToShow +=
        '<p>2. There will be no refund if the customer fails to show up for a rental that has not been cancelled prior to the pickup date.</p>';
      textToShow += '</div>';
    } else if (type == 'ET') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Early Termination </h5>';
      textToShow +=
        '<p>1. Incase of Early Termination, a Penalty Fee equivalent to a 1 Month Rental will be applied.</p>';
      textToShow +=
        '<p>2. There will be no refund for unused car rental days on a Prepaid/Pay on Arrival rentals, once the rental has started.</p>';
      textToShow += '</div>';
    } else if (type == 'BP') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Breakdown Procedure</h5>';
      textToShow +=
        '<p>Please try to park the vehicle in safe and secure place. Try to know exactly where you are. Contact the nearest Dollar Car Rental Oman office or Toll Free 800-4710 (800 Dollar) and provide the details of breakdown type. A replacement vehicle will be sent. A Service Fee will apply for replacement of the spare tyre.</p>';
      textToShow += '</div>';
    } else if (type == 'VAT') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Value Added Tax (VAT)</h5>';
      textToShow +=
        '<p>Dollar Car Rental Oman reserves the right to add 5% VAT as confirmed and implemented by Oman Government with effect on April 2021.</p>';
      textToShow += '</div>';
    } else if (type == 'SD') {
      textToShow += "<div class='tab-term-content'>";
      textToShow +=
        '<p>A pre-authorization is taken from the hirer’s credit card at the time of rental. Pre-authorization amount is minimum RO 150.00 depending on the vehicle type. We keep pre-authorization amount up to 21 days until we receive late traffic fines updates from Oman traffic department.</p>';
      textToShow +=
        '<p>An open credit card imprint of the hirer is required. If the driver does not have a credit card in his/her name, Dollar Rent A Car reserves the right to refuse the rental.</p>';
      textToShow += '<h5>Method of Payment</h5>';
      textToShow +=
        '<p>We accept all major Credit Cards (MASTER, VISA and AMERICAN EXPRESS). Credit card should be valid for a minimum of 3 months from the day of rental and must be physically available at time of vehicle collection.</p>';

      textToShow += '</div>';
    } else if (type == 'OI') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Oman Insurance</h5>';
      textToShow +=
        '<p>Driving to Oman territory is allowed with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. <strong>Oman Insurance charges are as follows (these rates are excluding the 5% VAT):</strong></p>';
      textToShow +=
        '<p>Note: <strong>Abu Dhabi Rentals Can Issue Oman insurance up to 31st of December only.</strong></p>';
      textToShow += "<table className='mt-4'>";
      textToShow += '<tr>';
      textToShow += "<th width='30%'>Car Type or Similar</th>";
      textToShow += '<th>1-3 days</th>';
      textToShow += '<th>7 days</th>';
      textToShow += '<th>7+ days</th>';
      textToShow += '<th>30 days</th>';
      textToShow += '<th>Extra KM Charged</th>';
      textToShow += '</tr>';
      textToShow += '<tr>';
      textToShow +=
        '<td>▪ Nissan Sunny ▪ Nissan Micra ▪ Kia Picanto ▪ Chevrolet Spark ▪ Hyundai Elantra ▪ Toyota Yaris ▪ Honda Civic ▪ Toyota Corolla ▪ Nissan Altima ▪ Toyota Camry ▪ Mazda 6 ▪ Kia Optima ▪ Nissan Kicks ▪ Hyundai Creta.</td>';
      textToShow +=
        '<td>350 Dhs/-<br></br> (with 600 km for 3 days).<br></br>( Each Day extra + 75 Dhs/- ).</td>';
      textToShow += '<td>600 Dhs/-<br></br> ( 250 Km per day ).</td>';
      textToShow += '<td>75 Dhs /-</td>';
      textToShow += '<td>1000 Dhs /-</td>';
      textToShow += '<td>1 OMR</td>';
      textToShow += '</tr>';
      textToShow += '<tr>';
      textToShow +=
        '<td>▪ Toyota Previa ▪ Toyota Fortuner ▪ Mazda CX-9 ▪ Mazda CX-5 ▪ Nissan X-Trail.</td>';
      textToShow +=
        '<td>450 Dhs/- <br></br>(with 600 km for 3 days). <br></br> (Each Day extra 100 Dhs/-).</td>';
      textToShow += '<td>750 Dhs/- <br></br>( 350 Km per day ).</td>';
      textToShow += '<td>100 Dhs/-</td>';
      textToShow += '<td>1500 Dhs/-</td>';
      textToShow += '<td>1 OMR</td>';
      textToShow += '</tr>';
      // textToShow += "<tr>";
      // textToShow += "<td>Jeep Wrangler Sport	</td>";
      // textToShow += "<td>500 Dhs/- <br></br>(with 600 km for 3 days) <br></br> (Each Day extra 150 Dhs/-)</td>";
      // textToShow += "<td>850 Dhs/- <br></br>(250 km per day )</td>";
      // textToShow += "<td>150 Dhs/-</td>";
      // textToShow += "<td>N/A</td>";
      // textToShow += "<td>1 OMR</td>";
      // textToShow += "</tr>";
      textToShow += '</table>';
      textToShow +=
        "<p className='my-4'>LIMITED MILEAGE provided when driving the car in Oman. Following vehicles are <strong><u>NOT</u></strong> allowed to be driven to Oman: Dodge Charger, BMW, VW Golf GTI, Mercedes C200, Lexus, Audi A6, Audi A8, Nissan Patrol, Dodge Challenger and Jeep Wrangler. </p>";
      textToShow += '</div>';
    } else if (type == 'VLF') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Vehicle License Fee</h5>';
      textToShow +=
        '<p>A vehicle monitoring device fee also known as the Vehicle License Fee (VRF) will be charged as implemented/mandated by the Roads and Transport Authority of Muscat. <strong>Daily:</strong> OMR 5.25; <strong>Weekly:</strong> OMR 31.50; <strong>Monthly:</strong> OMR 78.75.</p>';
      textToShow += '</div>';
    } else if (type == 'VAA') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Value Added Assets</h5>';
      textToShow +=
        '<p>Child Seats are available on request only and are subject to availability. Cost: RO 3.15/- per day</p>';
      textToShow +=
        '<p>GPS are available on request only and are subject to availability. Cost: RO 3.15/- per day</p>';
      textToShow += '</div>';
    } else if (type == 'RC') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>1-	Airport Return Charges</h5>';
      textToShow +=
        '<p>All vehicles off hired/returned in any of our airport counters will be charged an airport drop off fees. If you wish to avoid this drop off fees, rented vehicles can be returned to any non-airport branch locations between 08:00H to 17:30H (Sunday to Thursday). Presently the drop off fees is as follows: Salalah Airport - OMR 5.250, Muscat Airport OMR 5.250.</p>';
      textToShow += '<h5>2-	Non Airport Return</h5>';
      textToShow +=
        '<p>Drop-offs at any Non-Airport locations will be free of cost based on the working hours as mentioned on Locations.</p>';
      textToShow += '<h5>3-	OMR Off hire charges/Return charges</h5>';
      textToShow += '<p>Muscat/Salalah: OMR 147; Abu Dhabi: OMR 183.750.</p>';

      textToShow += '</div>';
    } else if (type == 'OWF') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>One Way Fees</h5>';
      textToShow +=
        '<p>If you arrange to pick the car up in one location and drop it off in different Emirate One Way Fee will apply as below.</p>';
      textToShow +=
        '<p>From Muscat & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210.00/- per one-way drop.</p>';
      textToShow +=
        '<p>From Abu Dhabi & Al Ain Locations to Muscat & Northern Emirates Locations will be Dhs 210.00/- per one-way drop.</p>';
      textToShow += '<p>From Abu Dhabi to Al Ain will be Dhs 210.00/- per one-way drop.</p>';
      textToShow += '<p>From Al Ain to Abu Dhabi will be Dhs 210.00/- per one-way drop.</p>';
      textToShow += '</div>';
    } else if (type == 'EXT') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Extensions</h5>';
      textToShow +=
        '<p>If a rental needs to be extended once the car has been picked up, local rental rates on 25% discount from the normal tariff will apply for the additional days and the additional charges will be settled by customer’s credit card.</p>';
      textToShow += '</div>';
    } else if (type == 'CR') {
      textToShow += "<div class='tab-term-content'>";
      textToShow += '<h5>Change of Reservation</h5>';
      textToShow +=
        '<p>1. Any change to a booking made online or through a Dollar Car Rental Oman Representative will be recalculated based on the availability and prices at the time the change is made. This may be greater or lesser than the price originally booked. This applies to changes made to:</p>';
      textToShow +=
        '<p>a. Drop off location | b. Pick up date and time | c. Drop off time and date | d. Vehicle group | e. All other additional products.</p>';
      textToShow +=
        '<p>2. Any modification for changes made online would have an amendment fee of OMR: 5.250.</p>';
      textToShow +=
        '<p>3. The primary customer name and the pick-up location on a reservation cannot be changed. If you wish to change the primary name or the pick-up location, then you must cancel the reservation (see below “Cancellations”) and make a new reservation.</p>';
      textToShow +=
        '<p>4. You will be asked to enter a valid credit card number at the end of a change to a prepaid reservation. This MUST be the same credit card that was used for the original reservation. If you wish to change the credit card, then the original reservation must be canceled (see below “Cancellations”) and a new reservation to be made.</p>';
      textToShow +=
        '<p>5. Administration charges will not apply to amended bookings, unless the amendment significantly changes the original booking, in which case it will be subject to the cancellation fee detailed below. All requests to amend a booking will be subject to normal availability of the requested vehicle. Prices may change between the date of the original booking and the amended booking, in which case the later prices will apply.</p>';
      textToShow += '</div>';
    }

    this.setState({
      LongTextShow: textToShow,
    });
  };
  render() {
    const { t } = this.props;
    // var startDate = moment(this.props.searchParam.CheckOutOn);
    // var endDate = moment(this.props.searchParam.CheckInOn);
    return (
      <>
        <section className="box-shadow-white-box-section pb-4">
          <div className="container-fluid">
            <div className="box-shadow-white-box mt-0 mb-0">
              <div className="rental-policies-wrap">
                <div className="box-title-icon-border d-flex align-items-center mb-4 mb-0 mt-0">
                  <span className="box-title-icon-rounded mt-0">
                    <img src={rentalpolicy} alt="Dollar Rental Policies" width="18" height="18" />
                  </span>
                  <h4 className="text-16 text-red">{t('Rental Policies.1')}</h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="text-16 mb-3">
                      All renters must be able to provide a valid driver’s license, passport with
                      visa page and authorized credit card under the main Driver Name. All drivers
                      must carry a locally accepted driving license.
                    </p>
                    <p className="text-16 mb-3">
                      OMR RESIDENTS: A valid OMR driving license is a must. Please note that driving
                      without a Valid OMR driving license when on residence or employment visa is a
                      violation of the LAW.{' '}
                    </p>
                    <p className="text-16 mb-3">
                      NON-RESIDENTS: Visitors are encouraged to have an International Driving
                      License/Permit prior from arrival to the OMR.{' '}
                    </p>
                    <div class="collapse" id="readmore">
                      <p className="text-16 mb-3">
                        Customers from the following countries can drive in the United Arab Emirates
                        without an International Driving Permit (IDP); however, must hold the
                        passport and driver’s license from the same country: <b>GCC Countries: </b>
                        Saudi Arabia, Oman, Bahrain, Kuwait, Qatar <b>European Countries:</b> Italy,
                        Germany, Switzerland, Poland, Slovakia, Finland, Spain, Netherlands, Greece,
                        Sweden, Belgium, Ireland, Turkey, Denmark, Portugal, Austria, France, United
                        Kingdom, Norway, Romania, Luxemburg, Estonia, Latvia, Serbia, Hungary,
                        Lithuania, Cyprus, Iceland, Holland, Malta, Albania, Ukraine, Bulgaria, The
                        Republic of Montenegro <b>North American Countries:</b> United States of
                        America, Canada. <b>African Country:</b> South Africa.
                      </p>
                      International or National Driving license in any other language other than
                      Arabic and English should be translated into Arabic or English. The
                      translation should be attested by the Embassy. <br />
                      <br />
                      <p className="text-16 mb-3">
                        Discount rates on the website and on counter given cannot be clubbed with
                        any Loyalty discounts or promotion running.
                      </p>
                    </div>
                    <a
                      class="square-bttn"
                      data-toggle="collapse"
                      href="#readmore"
                      role="button"
                      aria-expanded="false"
                      aria-controls="readmore"
                    >
                      READ MORE
                    </a>

                    <ul className="rental-policies-list text-15 opacity-50">
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('DCR')}
                      >
                        Documents Required
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ADD')}
                      >
                        {t('Additional Driver.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('INS')}
                      >
                        Insurance
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('MAL')}
                      >
                        Minimum Age Limit
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('FP')}
                      >
                        Fuel Policy
                      </li>
                      {/* <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('ST')}>Salik (Toll Fee)</li> */}
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ORC')}
                      >
                        Off-Road Charges
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('TV')}
                      >
                        Traffic Violations
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('DCC')}
                      >
                        Delivery Collection Charges
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('LR')}
                      >
                        Late Return
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('M')}
                      >
                        Mileage
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('CP')}
                      >
                        Cancellation Policy
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ER')}
                      >
                        Early Return/No Shows
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ET')}
                      >
                        Early Termination{' '}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('BP')}
                      >
                        Breakdown Procedure
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('VAT')}
                      >
                        Value Added Tax(VAT)
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('SD')}
                      >
                        Security Deposits
                      </li>
                      {/* <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('OWF')}>One Way Fees</li> */}
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('EXT')}
                      >
                        Extensions
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('RC')}
                      >
                        Return Charges
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('CR')}
                      >
                        Change of Reservation
                      </li>
                      {/* <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('OI')}>Oman Insurance</li> */}
                      {/* <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('VLF')}>Vehicle License Fees</li> */}
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('VAA')}
                      >
                        Value Added Assets
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="box-shadow-white-box-section pb-4">
                        <div className="container-fluid">
                            <div className="box-shadow-white-box mt-0 mb-0">
                                <div className="box-title-icon-border d-flex align-items-center mb-4 mt-0">
                                    <span className="box-title-icon-rounded mt-0">
                                        <img src={rentalpolicy} alt="Dollar Rental Policies" width="18" height="18" />
                                    </span>
                                    <h4 className="text-16 text-red">{t('Rental Policies.1')}</h4>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-15 mb-4">All renters must be able to provide a valid driver’s license, passport with visa page and authorized credit card under the main Driver Name. All drivers must carry a locally accepted driving license.</p>
                                        <p className="text-15 mb-4">OMR RESIDENTS: A valid OMR driving license is a must. Please note that driving without a Valid OMR driving license when on residence or employment visa is a violation of the LAW. </p>
                                        <p className="text-15 mb-4">NON-RESIDENTS: Visitors are encouraged to have an International Driving License/Permit prior from arrival to the OMR. </p> 
                                        <a class="text-link d-inline-block mb-4" data-toggle="collapse" href="#readmore" role="button" aria-expanded="false" aria-controls="readmore">Read More</a>
                                      
                                        <div class="collapse" id="readmore">
                                            <p className="text-15 mb-4">Customers from the following countries can drive in the United Arab Emirates without an International Driving Permit (IDP); however, must hold the passport and driver’s license from the same country: <b>GCC Countries: </b>Saudi Arabia, Oman, Bahrain, Kuwait, Qatar <b>European Countries:</b> Italy, Germany, Switzerland, Poland, Slovakia, Finland, Spain, Netherlands, Greece, Sweden, Belgium, Ireland, Turkey, Denmark, Portugal, Austria, France, United Kingdom, Norway, Romania, Luxemburg, Estonia, Latvia, Serbia, Hungary,  Lithuania, Cyprus, Iceland, Holland, Malta, Albania, Ukraine, Bulgaria, The Republic of Montenegro <b>North American Countries:</b> United States of America, Canada. <strong>East Asian Countries:</strong> Japan, Australia, Singapore, People's Republic of China, South Korea, New Zealand, Hong Kong, <b>African Country:</b> South Africa.</p> 
                                            International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy. <br /><br />
                                            <p className="text-15 mb-4">Discount rates on the website and on counter given cannot be clubbed with any Loyalty discounts or promotion running.</p>
                                        </div>
                                       <ul className="rental-policies-list text-15 opacity-50">
                                        <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('DCR')}>Documents Required</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('ADD')}>{t('Additional Driver.1')}</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('INS')}>Insurance</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('MAL')}>Minimum Age Limit</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('FP')}>Fuel Policy</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('ST')}>Salik (Toll Fee)</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('ORC')}>Off-Road Charges</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('TV')}>Traffic Violations</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('DCC')}>Delivery Collection Charges</li>
                                            <li style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('LR')}>Late Return</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('M')}>Mileage</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('CP')}>Cancellation Policy</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('ER')}>Early Return/No Shows</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('BP')}>Breakdown Procedure</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('VAT')}>Value Added Tax(VAT)</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('SD')}>Security Deposits</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('OWF')}>One Way Fees</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('EXT')}>Extensions</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('RC')}>Return Charges</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('CR')}>Change of Reservation</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('OI')}>Oman Insurance</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('VLF')}>Vehicle License Fees</li>
                                            <li  style={{cursor:"pointer"}} onClick={()=>this.onAdditionalDetails('VAA')}>Value Added Assets</li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
        {this.state.showExtrasDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showExtrasDetails}
                onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewIncludeLongText LongTextShow={this.state.LongTextShow} IsTextWithHtml={1} />
              </Modal>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(withRouter(RentalPolicies));
