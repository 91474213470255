import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';

class MonthlyRentals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerActive: true,
    };
  }
  componentDidMount = () => {
    scroll.scrollToTop();
  };

  handleHeaderActive = (value) => {
    this.setState({ headerActive: value });
  };

  handleBanner = (value) => {};

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Monthly Car Rental In Oman - Book Now and Save upto 30%! </title>
          <meta
            name="description"
            content="Dollar Car Rental offers best deals on monthly car rentals across Oman. Explore exclusive offers, unmissable deals and book your next rental today.  "
          ></meta>
          <meta
            property="og:title"
            content="Monthly Car Rental In Oman - Book Now and Save upto 30%! "
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/monthly-rentals"></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental offers best deals on monthly car rentals across Oman. Explore exclusive offers, unmissable deals and book your next rental today.  "
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 monthlyrentalPage">
          <div className="bg-grey">
            {/* <section className="position-relative search-widget-module pt-5 pb-4 search-widget-title-show-module"> */}
            {/* <SearchWidgetNew page={"H"} isMonthlyRental={true}/> */}
            {/* </section > */}
            <section className="sort-c1 reservationHeight">
              <div className="inner-wrapper" style={{ background: 'white' }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-content " id="nav-tabContent">
                        <StartBooking
                          manageBanner={this.handleBanner}
                          handleHeaderActive={this.handleHeaderActive}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-12 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h2 className="border-botom">Monthly Rentals</h2>
                        {/* <p>Already got your Dollar Car Reserved?</p> */}
                        <p className="text_p mt-2 text-black">
                          Enjoy massive savings when you choose monthly car rentals over daily &
                          weekly rentals. With our easy & flexible payment modes, enjoy monthly
                          prices cheaper than conventional car purchasing budgets.
                        </p>
                        <p className="text_p mt-2 text-black">
                          Dollar promises great prices for your choice of dream car, without the
                          hassles of frequent paperwork, maintenance & long-term financial
                          commitments.
                        </p>
                        <p className="text_p mt-2 text-black">
                          Instead, reap the benefits of up-to-date vehicle performance, fuel
                          economy, 24x7 dedicated premium support, roadside & emergency assistance
                          for the best prices in Oman.
                        </p>
                        <p className="text_p mt-2 text-black">
                          Perfect for new residents, young professionals, and working families.
                        </p>
                        <p className="text_p mt-2 text-black">
                          Contact us to know more or better yet visit any of our 6+ neighborhood
                          locations to experience Dollar today.
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-3 mr-6">
                      <h5 className="font-weight-bold">{t('Benefits Of Monthly Rentals.1')}</h5>
                      <br />
                      <ul className="check-box-listing round-checkmark-wrap">
                        <li>
                          <span>
                            <strong>ALL-INCLUSIVE</strong>
                            <br />
                            <br />
                            No more lengthy paperwork, insurances, maintenance & services. It’s all
                            on us.
                            <br />
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong> NO COMMITMENTS. </strong>
                            <br />
                            <br />
                            Stop. Pause. Restart. No questions asked. No strings attached.
                            <br />
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>GRADE AS YOU LIKE!</strong>
                            <br />
                            <br />
                            Upgrade, downgrade or continue with your choice of wheels as you like.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>SAVE MORE.</strong>
                            <br />
                            <br />
                            Our smart, practical plans help you make the wise choice of renting a
                            vehicle without the financial commitments of owning one!
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>HASSLE-FREE ONBOARDING</strong>
                            <br />
                            <br />
                            One-time verifications for all rental activities.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>KEY IN & GO</strong>
                            <br />
                            <br />
                            Best car reserved for you or choose your own.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(MonthlyRentals);
