import React, { Component } from 'react';

import Alert from '@material-ui/lab/Alert';

class SuccessAlert extends Component {
  render() {
    return (
      <div class="mt-4">
        <Alert severity="success">{this.props.successMessage}</Alert>
      </div>
    );
  }
}
export default SuccessAlert;
