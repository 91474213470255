import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import appstore from '../assets/images/app-store.png';
import playstore from '../assets/images/google-play-store.png';

class SocialMediaBox extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className="box-shadow-white-box-section grey-bg py-2">
        <div className="container-fluid">
          <div className="box-shadow-white-box bg-red">
            <div className="socialMediaSection">
              <div className="try-our-app-section">
                <h3 className="text-white">Try Our App</h3>
                <div className="try-our-app-img-download">
                  <a className="app-store-download">
                    <img src={appstore} alt={'App Store'}></img>
                  </a>
                  <a className="app-store-download">
                    <img src={playstore} alt={'Play Store'}></img>
                  </a>
                </div>
              </div>
              <div className="follow-us-section">
                <h3 className="text-white">Follow Us On</h3>
                <ul>
                  <li>
                    <a href="https://twitter.com/dollar_oman" target="_blank">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/oman.dollar/" target="_blank">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/dollar-oman" target="_blank">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(withRouter(withRouter(SocialMediaBox)));
