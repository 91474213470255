import queryString from 'query-string';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ListingHeader from '../Common/ListingHeader';
import { bookingInstance } from '../axios/axiosInstance';
import './PaymentProcessing.css';

class PaymentProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);

    if (params.encResp != null) {
      const requestData = { encResp: params.encResp };

      try {
        const response = await bookingInstance.post('/payment/muscat/CallBack', requestData);

        setTimeout(() => {
          this.props.history.push(`/reservationconfirm/${response.data.result.Identity.Id}/1`);
        }, 5000);
      } catch (error) {
        // console.error('Payment processing failed', error);
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />
        <div className="payment-loader-container">
          <div className="payment-loader">
            <div className="pad">
              <div className="chip"></div>
              <div className="line line1"></div>
              <div className="line line2"></div>
            </div>
            <div className="loader-text">{t('Processing the payment.1')}</div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(PaymentProcessing));
