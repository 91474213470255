import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import '../assets/css/slick.css';
import { branchInstance, productInstance } from '../axios/axiosInstance';
import LessFineNextArrow from './LessFineNextArrow';
import LessFinePrevArrow from './LessFinePrevArrow';

class VehicleClassifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleTypes: [],
      ID: '',
    };
  }

  componentDidMount = () => {
    this.getVehicleTypes();
  };

  getAllBranches = (VehicleClassificationID) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance
      .get(`all?countryCode=${code}`)
      .then((response) => {
        const allBranches = response.data.result;
        if (allBranches.length > 0)
          this.getHoursToReady(
            allBranches[0]?._id,
            VehicleClassificationID,
            allBranches[0].Name,
            allBranches[0].Address,
            allBranches[0].Message,
            allBranches[0].Description,
          );
      })
      .catch((error) => {});
  };

  getHoursToReady = (timeId, VehicleClassificationID, Name, Address, Message, Description) => {
    var isDeliver = this.state.isDeliverToMe || false;
    branchInstance
      .get(`/hrsToGetReady?branchId=${timeId}&isDeliverToMe=${isDeliver}`)
      .then((response) => {
        const timeReady = response.data.result;
        let pickupDate = moment(moment.tz(timeReady.PickupOn, 'Asia/Muscat')).format(
          'DD MMM YYYY, h:mm A',
        );
        let returnDate = moment(moment.tz(timeReady.PickupOn, 'Asia/Muscat')).format(
          'DD MMM YYYY, h:mm A',
        );

        var BookingInfo = [
          {
            CheckOutLocationId: timeId,
            CheckInLocationId: timeId,
            PickUpDate: pickupDate,
            ReturnDate: returnDate,
            pickupLocationName: Name,
            dropOffLocationName: Name,
            pickupLocationAddress: Address,
            dropOffLocationAddress: Address,
            pickupLocationMessage: Message,
            dropOffLocationMessage: Message,
            pickupLocationBranchDescription: Description,
            dropOffLocationBranchDescription: Description,
            promocode: '',
          },
        ];

        localStorage.setItem('VehicleTypeParam', VehicleClassificationID);
        localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
        this.props.history.push('/booking');
      })
      .catch((error) => {});
  };

  onSeeAllCarsClick = (Id) => {
    this.getAllBranches(Id);
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      this.setState({ vehicleTypes: response.data.result });
    });
  };

  onViewMoreClick = (Id) => {
    localStorage.setItem('vehicleClassificationID', Id);
    this.props.history.push(`/vehicletypes/${Id}`);
  };

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      centerPadding: '0',
      centerMode: true,
      prevArrow: <LessFinePrevArrow />,
      nextArrow: <LessFineNextArrow />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    };
    const { t } = this.props;
    return (
      <Slider className="more-car-slider-wrap mb-0 slick-dotted" {...settings}>
        {this.state.vehicleTypes.map((vehicleType, index) => {
          return (
            <div className="more-cars-slider" key={vehicleType.VehicleClassificationID}>
              <div
                style={{ textAlign: 'center', alignItems: 'center' }}
                onClick={() => {
                  this.onViewMoreClick(vehicleType.VehicleClassificationID);
                }}
              >
                <img
                  src={vehicleType.Attachment.ImageString}
                  alt="First slide"
                  style={{ width: '572', height: '282' }}
                />
                {vehicleType.Name == 'Dollar Prestige' ? (
                  <h4 className="text-red">{vehicleType.Name}</h4>
                ) : (
                  <h4>{vehicleType.Name}</h4>
                )}

                <p>{vehicleType.ShortDetails}</p>
                <a
                  className="link"
                  title="Explore"
                  onClick={() => {
                    this.onViewMoreClick(vehicleType.VehicleClassificationID);
                  }}
                ></a>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  }
}

export default withTranslation()(withRouter(VehicleClassifications));
