import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import allInclusiveicon from '../assets/images/credit-card-icon.svg';
import saveMoreicon from '../assets/images/shopping-bag-icon.svg';
import hassleFreeonboradingicon from '../assets/images/verified-icon.svg';

class MonthlyRenting extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="month-rental-box-section padding-100 grey-bg">
          <div className="container">
            <div className="titleBorderWrap titleCenter">
              <h2>How It Works</h2>
              <p>Dollar promises great prices for your choice of dream car</p>
            </div>
            <div className="month-rental-box-row">
              <div className="month-rental-box-col">
                <div className="month-rental-box">
                  <img src={allInclusiveicon} alt="All-Inclusive" />
                  <h3>Its quick simple and easy</h3>
                  <p>Select your car delivery location and rent duration</p>
                </div>
              </div>
              <div className="month-rental-box-col">
                <div className="month-rental-box">
                  <img src={saveMoreicon} alt="Save More" />
                  <h3>Effortless booking</h3>
                  <p>Upload your digital documents and make the payment</p>
                </div>
              </div>
              <div className="month-rental-box-col">
                <div className="month-rental-box">
                  <img src={hassleFreeonboradingicon} alt="Hassle-Free Onboarding" />
                  <h3>Seamless delivery</h3>
                  <p>Get the car delivered to your preferred location</p>
                  <p>Go on and drive near and far in your rented car</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(MonthlyRenting);
