import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import babyseater from '../assets/images/baby-seat-icon.png';
import drivers from '../assets/images/driver-icon.png';
import gpstrack from '../assets/images/gps-icons.png';
import Personalaccident from '../assets/images/personal-accident-icon.png';
import Superdamagewaiver from '../assets/images/super-damage-waiver.png';
import image from '../assets/images/xpress-checkout.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ContactUsBox from './ContactUsBox';
import SocialMediaBox from './SocialMediaBox';

class RentalAddons extends Component {
  componentDidMount() {
    scroll.scrollToTop();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Addons to Rent a Car in Oman | Best Car Rental Addons at Dollaroman</title>
          <link rel="canonical" href="https://www.dollaroman.com/rentaladdons" />
          <meta
            name="description"
            content="Rent a car in Oman with essential add-ons like Wi-Fi, additional drivers, or roadside assistance. Make your travel experience easy and hassle-free."
          ></meta>
          <meta
            property="og:title"
            content="Addons to Rent a Car in Oman | Best Car Rental Addons at Dollaroman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/rentaladdons"></meta>
          <meta
            property="og:description"
            content="Rent a car in Oman with essential add-ons like Wi-Fi, additional drivers, or roadside assistance. Make your travel experience easy and hassle-free."
          ></meta>
        </Helmet>

        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">Rental Add-ons</h2>
                            <p>For Your Dollar Car Rental.</p>
                            <p className="text_p mt-4">
                              Enhance your Dollar Car Rental Experience with our practical
                              easy-to-rent add-ons & amenities. Be it ‘Child & Baby seats’ for your
                              kid’s safety, ‘Additional Drivers’ to take turns driving through your
                              journey, or GPS trackers to embark on adventures during your road
                              trips, we will have the perfect options for you as you make your
                              rental reservations - both online & offline.
                            </p>
                            {/* <p className="text_p mt-4">We will have the perfect options for you as you make your rental reservations - both online & offline. </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img src={image} alt="Dollar Rent a car" className="img-fluid rounded-xl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section grey-bg py-2">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="categories-content-wrap">
                  <h5 className="font-weight-bold">Categories</h5>
                  <div className="categories-box-wrap div-text-center-align">
                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={babyseater} alt="Baby Seater" />
                        </div>
                        <div className="categories-box-content">
                          <h6>Child & Baby seats</h6>
                          <p>Buckle in your kid’s safety & comfort.</p>
                        </div>
                      </div>
                    </div>
                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={drivers} alt="Additional Driver" />
                        </div>
                        <div className="categories-box-content">
                          <h6>Additional Driver</h6>
                          <p>Take turns in the driver’s seat.</p>
                        </div>
                      </div>
                    </div>
                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={gpstrack} alt="GPS Tracker" />
                        </div>
                        <div className="categories-box-content">
                          <h6>GPS Tracker</h6>
                          <p>Through adventures - anytime, anywhere.</p>
                        </div>
                      </div>
                    </div>

                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={Personalaccident} alt="Personal Accident Insurance" />
                        </div>
                        <div className="categories-box-content">
                          <h6>Personal Accident Insurance</h6>
                          <p>More than just insurance.</p>
                        </div>
                      </div>
                    </div>

                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={Superdamagewaiver} alt="Super Damage Waiver" />
                        </div>
                        <div className="categories-box-content">
                          <h6>Super Damage Waiver</h6>
                          <p>Worry-less coverage for your wheels.</p>
                        </div>
                      </div>
                    </div>

                    <div className="categories-box-col">
                      <div className="categories-box-pink">
                        <div className="categories-box-icon rounded-xl">
                          <img src={collisiondamageImage} alt="Collision Damage waiver" />
                        </div>
                        <div className="categories-box-content">
                          <h6>Collision Damage waiver</h6>
                          <p>Comprehensive. Affordable. Responsible</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SocialMediaBox></SocialMediaBox>

          <ContactUsBox subHeading="" heading="Do you have further Question?"></ContactUsBox>
        </div>

        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(RentalAddons);
