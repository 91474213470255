import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import phpTrsRal from '../assets/images/Business/431848417.jfif';
class BusinessSolutionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
          <div className="container-fluid" id="top" name="top">
            <div className="box-shadow-white-box-a ">
              <div className="">
                <div className="row flex-reversea">
                  <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                    <div className="pickup-location-box ">
                      <div className="pickup-location-flex">
                        <div className="pickup-location-flex-content">
                          <h2 className="border-botom">Business Solutions </h2>
                          <p>We believe, no road is long with the right company.</p>
                          <p className="text_p mt-3">
                            {' '}
                            And that is why, at Dollar Car Rental, we aim to be the leading and
                            innovative provider of mobility & transportation solutions in Oman.
                          </p>
                          <p className="text_p mt-3">
                            {' '}
                            With Dollar Car Rental Oman, you can quick-lease a vehicle for 1 year
                            extendable up to 3 years, at the lowest monthly prices, along with free
                            delivery & pickup services plus the convenience of 6+ service counters
                            of Dollar Car Rental Oman across Muscat, Salalah, and Sohar. Our Leasing
                            Consultants will be happy to share with you the season’s latest
                            competitive rates, offers, and deals from Dollar Car Rental Oman.
                          </p>
                          <p className="text_p mt-3"> Contact us today to know more.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                    <img src={phpTrsRal} alt={'Business Solution'} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(withRouter(BusinessSolutionHeader)),
  BusinessSolutionHeader,
);
