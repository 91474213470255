import React, { Component } from 'react';

import Alert from '@material-ui/lab/Alert';

class ErrorAlert extends Component {
  render() {
    return (
      <div class="mt-4">
        <Alert severity="error">{this.props.errorMessage}</Alert>
      </div>
    );
  }
}
export default ErrorAlert;
