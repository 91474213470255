import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import validator from 'validator';
import email3 from '../assets/images/email (3).svg';
import email2 from '../assets/images/email2.svg';
import message from '../assets/images/msg.svg';
import phonealt3 from '../assets/images/phone-alt3.svg';
import phoneCall2 from '../assets/images/phone-call (2).svg';
import userIcon from '../assets/images/user-icon3.svg';
import whatsapp from '../assets/images/WhatsApp.png';
import { contentInstance } from '../axios/axiosInstance';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
var qs = require('qs');

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: '',
    };
  }

  handleSelect = (index) => {
    this.setState({ avaliableindex: index });
  };

  componentDidMount = () => {
    scroll.scrollToTop();
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  ValidateContactInfo = () => {
    const language = localStorage.getItem('i18nextLng');
    let lngParam = language == 'Oman' ? 'ar' : 'en';

    const valid = true;
    if (this.state.fullName == undefined || this.state.fullName === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the fullName' });
      } else {
        //ar
        this.setState({ errorMessage: 'يرجى ملء FULLNAME' });
      }
      return false;
    }

    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the email' });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء تعبئة البريد الإلكتروني' });
      }
      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }

    if (this.state.phoneNo == undefined || this.state.phoneNo === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the phoneNo' });
      } else {
        //ar
        this.setState({ errorMessage: 'يرجى ملء phoneNo' });
      }
      return false;
    }
    if (this.state.comments == undefined || this.state.comments === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please fill the comments' });
      } else {
        //ar
        this.setState({ errorMessage: 'يرجى ملء تعليقات' });
      }
      return false;
    }
    this.setState({ errorMessage: '' });
    return valid;
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  onSendClick = () => {
    if (this.ValidateContactInfo()) {
      this.setState({ loading: true });
      var data = {
        personalInformation: {
          fullName: this.state.fullName,
          contactNo: this.state.phoneNo,
          email: this.state.email,
          address: null,
        },
        message: this.state.comments,
      };
      contentInstance
        .post('contactRequest', data)
        .then((response) => {
          this.setState({ loading: false });
          const responseData = response.data.result;
          if (responseData.IsValid == true) {
            if (responseData != null && responseData.Message != null) {
              localStorage.setItem('thanksmessage', responseData.Message);
            }

            this.props.history.push('/thankyou');
          } else {
            this.setState({ errorMessage: responseData.Message });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({
            errorMessage: 'Error while sending contact info!!!',
          });
        });
    } else {
    }
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Contact Us</title>
          <meta
            name="description"
            content="24x7. Right Here for You. Call or Write to us and we will be happy to help! "
          ></meta>
          <meta property="og:title" content="Dollar Car Rental Oman | Contact Us"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/contactus"></meta>
          <meta
            property="og:description"
            content="24x7. Right Here for You. Call or Write to us and we will be happy to help! "
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100" style={{ backgroundColor: '#F8F8F8' }}>
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="row flex-reversea justify-content-center">
                  <div className="col-12">
                    <div className="pickup-location-box  text-center contact-content-title">
                      <h5 className="font-bold mb-1">Support Zone</h5>
                      <h3 className="mb-2">24x7. Right Here for You.</h3>
                      <span>
                        For support related to making reservations, upcoming rentals, rentals in
                        progress, completed rentals, corporate lease, or other general
                        enquiries/concerns.
                      </span>
                    </div>
                  </div>

                  <div className="col-md-10">
                    <div className="row align-items-start">
                      <div className="col-md-6 col-12">
                        <div
                          className="contactusform-right pl-xl-5 ml-xl-5"
                          style={{ zIndex: '150' }}
                        >
                          {/* <h4 style={{ color: 'white', padding: "10px" }}>{t('Get in Touch.1')}</h4> */}
                          <div className="spans">
                            <div className="tinyicon">
                              <img src={email3} alt="" />
                            </div>

                            <div className="tiny-content">
                              <h4>Booking Email</h4>
                              <a href="mailto:csoman@dollaroman.com">
                                {' '}
                                <span>csoman@dollaroman.com</span>{' '}
                              </a>
                            </div>
                          </div>
                          {/* <div className="spans">
                                            <div className="tinyicon">
                                                <img src={email3} alt="" />
                                            </div>
                                            
                                            <div className="tiny-content">
                                                <h4>Support Email</h4>
                                                <a href="mailto:customer.care@dollaroman.ae">  <span>customer.care@dollaroman.ae</span> </a>
                                            </div>
                                        </div> */}
                          {/* <div className="spans">
                                            <div className="tinyicon">
                                                <img src={phoneCall2} alt="" />
                                            </div>
                                            <div className="tiny-content">
                                                <h4>Phone</h4>
                                                <a href="tel:+97143365065"> <span>+971 4 336 5065</span></a>
                                            </div>
                                        </div> */}
                          <div className="spans">
                            <div className="tinyicon">
                              <img src={phoneCall2} alt="" />
                            </div>
                            <div className="tiny-content">
                              <h4>Phone</h4>
                              <a href={'tel:800 70011'}>
                                {' '}
                                <span>{'(Toll Free): 800 70011'}</span>
                              </a>
                            </div>
                          </div>
                          <div className="spans">
                            <div className="tinyicon1">
                              <img src={whatsapp} alt="" />
                            </div>

                            <div className="tiny-content">
                              <h4>WhatsApp</h4>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+96871901379&text=Hi, I'm contacting you through dollaroman.com`}
                                target="_blank"
                              >
                                <span>{'+968 7 1901379'}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div
                          id="LoginFormPopup"
                          className="box-shadow-white-box-a bg-grey pb-4 px-4 mt-0 box-shadow-custom"
                        >
                          <div className="form-register">
                            <div className="form-group">
                              <label htmlFor="exampleInputUsername">
                                <h6 className="font-weight-bold mb-0 text-black">
                                  {t('Your Name.1')}
                                </h6>
                              </label>
                              <div className="position-relative">
                                <img src={userIcon} className="img-size" />
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputUsername"
                                  aria-describedby="emailHelp"
                                  name="fullName"
                                  placeholder={t('Enter Your Full Name.1')}
                                  onChange={this.onChange}
                                  value={this.state.fullName}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                <h6 className="font-weight-bold mb-0 text-black">{t('Email.1')}</h6>
                              </label>
                              <div className="position-relative">
                                <img src={email2} className="img-size" alt="Dollar Email" />
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputUsername"
                                  aria-describedby="emailHelp"
                                  name="email"
                                  placeholder={t('  Enter Email.1')}
                                  onChange={this.onChange}
                                  value={this.state.email}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputcontactNo">
                                <h6 className="font-weight-bold mb-0 text-black">
                                  {t('Contact No.1')}
                                </h6>
                              </label>
                              <div className="position-relative">
                                <img src={phonealt3} className="img-size" alt="Dollar Conatct No" />
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputUsername"
                                  aria-describedby="emailHelp"
                                  name="phoneNo"
                                  placeholder={t('  Enter ContactNo.1')}
                                  onChange={this.onChange}
                                  value={this.state.phoneNo}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-0">
                              <label htmlFor="exampleInputPassword">
                                <h6 className="font-weight-bold mb-0 text-black">
                                  {t('Message.1')}
                                </h6>
                              </label>
                              <div className="position-relative">
                                <img src={message} className="img-size" alt="Dollar Message" />
                                <textarea
                                  className="form-control"
                                  cols="20"
                                  id="comments"
                                  name="comments"
                                  placeholder={t('Enter Your Message.1')}
                                  rows="4"
                                  onChange={this.onChange}
                                  value={this.state.comments}
                                ></textarea>
                              </div>
                            </div>

                            <br />
                            <div className="row">
                              <div className="col-md-6">
                                {this.state.loading == false ? (
                                  <button
                                    type="submit"
                                    id="btnContactRequestSubmit"
                                    className="rounded-bttn rounded-bttn-transparent text-center   width-100"
                                    onClick={this.onSendClick}
                                  >
                                    Submit&nbsp;{' '}
                                  </button>
                                ) : null}
                              </div>
                              <div className="col-md-6">
                                {this.state.errorMessage != null &&
                                this.state.errorMessage.length > 0 ? (
                                  <Dialog
                                    open={this.state.errorMessage.length > 0}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        {this.state.errorMessage}
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={this.handleClose} color="primary">
                                        {t('OK.1')}
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                ) : null}
                                {this.state.loading == true ? (
                                  <div style={{ verticalAlign: 'center' }}>
                                    <Spinner animation="border" />
                                    &nbsp;{t('Saving Contact Info.1')}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section  grey-bg py-4">
            <div className="container-fluid">
              <div className="">
                <div className="pickup-location-box">
                  <h2 class="border-botom">{t('FAQs.1')}</h2>
                </div>
                <div className="faq-wrapper">
                  {/* <div className="box-title-icon-border d-flex align-items-center mb-4">
                                <span className="box-title-icon-rounded">
                                    <img src={faq} />
                                </span>
                                <h4 className="text-16 text-red">{t('FAQ.1')}</h4>
                            </div> */}
                  <div className="">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseOne"
                              onClick={() => {
                                this.handleSelect(1);
                              }}
                            >
                              {t('What are the basic rental requirements?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse in">
                          <div className="panel-body text-muted">
                            <span>
                              {' '}
                              {t(
                                '1. Minimum Age Limit Dollar Car Rental Oman requires one to be of 22 years old and above for all rental plans within the country',
                              )}
                              <br />
                              {t('2. Valid Documents Required.1')}
                              <br />
                              {t('For Local Residents.1')}
                              <br />
                              {t('• Emirates ID Copy (Front & Back).1')}
                              <br />
                              {t('• Oman Driving License Copy (Front & Back).1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visa Copy.1')}
                              <br />
                              {t('• International Driving License Copy.1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visiting Visa Copy.1')}
                              <br />
                              {t('For Tourists (GCC Countries).1')}
                              <br />
                              {t('• Valid GCC Driving License Copy.1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visiting Visa Copy.1')}
                              <br />
                              {t(
                                'International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.1',
                              )}
                              <br />
                              {t(
                                '3. Security Deposits Valid Credit Card with minimum 3 months validity.1',
                              )}
                              <br />
                              {t('4. Vehicle Inspection & Add-ons.1')}
                              <br />
                              {t(
                                'Once you’ve ensured the vehicle quality is up to your satisfaction, you may proceed to sign the rental contract and complete the payment process. You may also add on amenities like baby/toddler rear seats, GPS trackers, Mobile WIFI, etc. at this stage.1',
                              )}{' '}
                              <br />
                              {t('• Valid GCC Driving License Copy.1')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed  font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                              onClick={() => {
                                this.handleSelect(2);
                              }}
                            >
                              {t('Do you offer a vehicle delivery & pick up service?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'For your added convenience, we can deliver and collect vehicles right at your doorstep for a nominal fee. This service is subject to availability.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree"
                              onClick={() => {
                                this.handleSelect(3);
                              }}
                            >
                              {t('Can I reserve a specific make/model car?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental Oman does not guarantee a specific make/model/color of the vehicle against any reservation. Should the specific car group/make/model not be available at the point of pick-up, Dollar reserves the right to offer an alternate make/model within the same group',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFour"
                              onClick={() => {
                                this.handleSelect(4);
                              }}
                            >
                              {t('What happens when I return the car late?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Additional charges are applicable based on the signed rental contract. You may nevertheless, check with our team for an authorized extension.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFive"
                              onClick={() => {
                                this.handleSelect(5);
                              }}
                            >
                              {t('How about the Fuel Policy at Dollar Car Rental?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'One must return the vehicle with the same fuel level as given by the company. If not, the hirer will be charged the cost of fuel plus a service charge.1',
                              )}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSix"
                              onClick={() => {
                                this.handleSelect(6);
                              }}
                            >
                              {t(
                                'What should I do when my vehicle needs servicing and maintenance?.1',
                              )}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSix" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Please inform Dollar Team or use the ‘Book A Service’ feature from our website to arrange an appointment with one of our service representatives. We will book your vehicle in for service and arrange a replacement vehicle.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeven"
                              onClick={() => {
                                this.handleSelect(7);
                              }}
                            >
                              {t('What are my options for mode of Payment?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeven" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental Oman accepts all major Credit Cards (MASTER, VISA, JCB, DINERS and AMERICAN EXPRESS). Credit card must be valid for a minimum of 3 months from the day of rental and must be physically available at the time of vehicle collection',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEight"
                              onClick={() => {
                                this.handleSelect(8);
                              }}
                            >
                              {t('Can I rent a car without a credit card?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEight" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Unfortunately no. The security deposit gets collected from credit cards using a pre-authorization block. This is the safest and most recommended way for both parties, as the collected amount is automatically released by the bank after 21 working days.1',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseNine"
                              onClick={() => {
                                this.handleSelect(9);
                              }}
                            >
                              {t('What are the parking rules to be followed in Oman?')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseNine" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Parking across Oman is easily available. Paid Parking along roadsides are strictly followed, with parking meters under the same',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTen"
                              onClick={() => {
                                this.handleSelect(10);
                              }}
                            >
                              {t('What about Traffic violations?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              Most roads in Oman are radar-controlled and thus it is in the driver’s
                              interest to adhere to all speed limits & road signs. Any Traffic
                              Violation is a direct offense of the driver. All fines related to your
                              dollar rental car will be processed by our team while subsequent
                              notifications and costs are invoiced to the customer. For every
                              traffic fine incurred, you will be charged 10% extra to cover the
                              administration charges.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEleven"
                              onClick={() => {
                                this.handleSelect(11);
                              }}
                            >
                              {t('What do we do in the case of breakdown/accidents?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEleven" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Dollar offers you 24x7 breakdown and roadside assistance. And in the unforeseen circumstances of your vehicle being involved in an accident, please note that the vehicle should not be moved from the point of the accident or damage, except in the case where the damage is minor and the vehicle is causing an obstruction to the traffic. You will then call the police and remain with the vehicle in order to obtain a police report to be submitted to Dollar, so that an insurance claim can be made. Kindly notify our 24x7 support zone, 800 70011 Dollar and they will facilitate any replacement vehicle that may be required.',
                              )}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwelve"
                              onClick={() => {
                                this.handleSelect(12);
                              }}
                            >
                              {t('What should I do if my rental vehicle is stolen?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwelve" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Report the theft to the police immediately, obtain a copy of the police report and then notify Dollar Car Rental at the earliest.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThirteen"
                              onClick={() => {
                                this.handleSelect(13);
                              }}
                            >
                              {t('Can I add additional drivers for the same car rental?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThirteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Yes, you may add an extra driver, for a minimal charge, provided he/she fills all the relevant details.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFourteen"
                              onClick={() => {
                                this.handleSelect(14);
                              }}
                            >
                              {t('What is the policy on off-road driving?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFourteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Off-road use is a violation of the rental agreement and is not allowed.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFifteen"
                              onClick={() => {
                                this.handleSelect(15);
                              }}
                            >
                              {t('What is your Policy on driving to Oman?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFifteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Our cars may be driven into Oman with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. Charges vary based on the vehicles, time and period. However, certain vehicle groups may not be allowed to travel into Oman territory. Driving to Bahrain, Saudi Arabia, Qatar, Yemen, Kuwait, etc. is strictly forbidden.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSixteen"
                              onClick={() => {
                                this.handleSelect(16);
                              }}
                            >
                              {t('What are the expected additional charges?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSixteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              Additional charges related to the rental include: any driving/traﬃc
                              fine or violation charges imposed by ROP/Municipality, Re-fueling
                              Service Charge, additional driver charge, baby seats and GPS, Airport
                              Return Charges, and for any Oman registered rented or leased vehicle.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeventeen"
                              onClick={() => {
                                this.handleSelect(17);
                              }}
                            >
                              {t('How can I avail the best offers at Dollar Car Rental Oman?')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeventeen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental guarantees the best prices, assured seasonal offers and surprise deals, 365 days a year! You can follow us on social media to stay tuned or ask our representative to update you on the ongoing promotions.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(ContactUs)), ContactUs);
