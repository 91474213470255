import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import newsImage from '../assets/images/news.png';
import { contentInstance } from '../axios/axiosInstance';
import NewsTile from './NewsTile';

class AllNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allNews: [],
    };
  }
  componentDidMount = () => {
    let lngParam = getLanguage();
    this.getAllNews(lngParam);
  };

  getAllNews = (lngParam) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    contentInstance
      .get(`news/allUi?countryCode=${code}`)
      .then((response) => {
        this.setState({
          allNews: response.data.result,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">{t('News & Updates.1')}</h2>
                            <p>The Latest from Dollar Car Rental Oman.</p>
                            <p className="text_p mt-4">
                              Read on to know how we keep ourselves up-to-speed with this
                              compilation of latest updates, newsroom highlights, growth stories,
                              and customer excerpts.
                            </p>
                            <div className="mt-4 card-profile-wrap">
                              <h3> </h3>
                              {/* <h4>Anudeep Raghuthaman <span>Head - Marketing &amp; Sales</span></h4> */}
                              <ul>
                                <li>
                                  <p>
                                    <br></br>
                                    <br></br>
                                  </p>
                                </li>

                                {/* <li>
                                                                <p><i className="fa fa-envelope-o"></i>anudeep@dollaroman.com</p>
                                                            </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <div style={{ width: '97%', position: 'relative', top: '15px' }}>
                        <img src={newsImage} alt="" className="img-fluid rounded-xl" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="what-new-sectio ptb-80 grey-bg py-4">
            <div className="container">
              <div className="row">
                {' '}
                {this.state.allNews != null
                  ? this.state.allNews.map((news) => {
                      return <NewsTile key={news.NewsID} news={news} />;
                    })
                  : ''}
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(AllNews)), AllNews);
