import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import dollarLogo from '../assets/images/dollar-logo.svg';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import FindLocation from '../Hire/FindLocation';
import Subscribe from '../Hire/Subscribe';
import NewsIndex from '../News/NewsIndex';
import BannerSection from './MonthlyBannerSection';
import MonthlyFeatures from './MonthlyFeatures';
import MonthlyRenting from './MonthlyRenting';
import MonthlyTestimonials from './MonthlyTestimonials';

class MonthlySubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerActive: true,
      seoImage: dollarLogo,
    };
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  handleHeaderActive = (value) => {
    if (window.innerWidth > 553) {
      this.setState({ headerActive: value });
    }
  };

  updateSeoimage = (value) => {
    if (value) this.setState({ seoImage: value });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Flexi Monthly Car Rental in Oman | Best Offers at Dollaroman</title>
          <link rel="canonical" href="https://www.dollaroman.com/fleximonthly" />
          <meta
            name="description"
            content="Flexi Monthly Car Rental in Oman offers flexibility, affordability, and a diverse vehicle selection. Perfect for your long-term travel needs. Reserve now!"
          ></meta>
          <meta
            property="og:title"
            content="Flexi Monthly Car Rental in Oman | Best Offers at Dollaroman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/fleximonthly"></meta>
          <meta
            property="og:description"
            content="Flexi Monthly Car Rental in Oman offers flexibility, affordability, and a diverse vehicle selection. Perfect for your long-term travel needs. Reserve now!"
          ></meta>
          <meta property="og:type" content="video.other" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@dollaroman" />
          <meta
            name="twitter:title"
            content="Flexi Monthly Car Rental in Oman | Best Offers at Dollaroman"
          />
          <meta
            name="twitter:description"
            content="Flexi Monthly Car Rental in Oman offers flexibility, affordability, and a diverse vehicle selection. Perfect for your long-term travel needs. Reserve now!"
          />
          <link rel="canonical" href="https://www.dollaroman.com/fleximonthly" />
        </Helmet>

        {this.state.headerActive && <Header />}
        <BannerSection handleHeaderActive={this.handleHeaderActive} />
        <MonthlyRenting />
        <MonthlyFeatures />
        <MonthlyTestimonials />
        <Subscribe />
        <NewsIndex />
        <FindLocation />
        <Footer updateSeoimage={this.updateSeoimage} />
      </>
    );
  }
}

export default withTranslation()(MonthlySubscription);
