import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getCustomerId, getLanguage } from '../Common/Util';
import '../assets/css/slick.css';
import appstore from '../assets/images/app-store.png';
import arencologo from '../assets/images/arenco-logo.png';
import dollarlogo from '../assets/images/dollar.svg';
import googleplay from '../assets/images/google-play-store.png';
import { branchInstance } from '../axios/axiosInstance';

class ListingFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleTypes: [],
      locations: [],
      airPortLocations: [],
      citySegmentLocations: [],
      cityLocations: [],
      errorMessage: '',
      CookiePolicyAccepted: '',
    };
  }

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  onFindReservationClick = (path) => {
    const customerId = getCustomerId();
    if (customerId == '00000000-0000-0000-0000-000000000000') {
      this.props.history.push('/findreservation');
    } else {
      this.props.history.push('/profile');
    }
  };

  onFooterMenuLinkClick = (path) => {
    localStorage.setItem('locationid', 'null');
    this.props.history.push(path);
  };

  componentDidMount = () => {
    this.getVehicleTypes();
    const CookiePolicyAccepted = localStorage.getItem('CookiePolicyAccepted');
    this.setState({ CookiePolicyAccepted: CookiePolicyAccepted });
  };

  getVehicleTypes = () => {
    let lngParam = getLanguage();
    const code = process.env.REACT_APP_COUNTRY_CODE;
    this.getAllLocations(lngParam);
  };

  onLocationMenuClick = (locationId) => {
    localStorage.setItem('locationid', locationId);
    this.props.history.push(`/locations`);
  };

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i].locationId.toString() == id.toString()) return true;
    }
    return false;
  };

  getAllLocations = (lngParam) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`all?countryCode=${code}`).then((response) => {
      const { t } = this.props;
      const allBranches = response.data.result;
      const locations = [];
      const airPortLocations = [];
      const citySegmentLocations = [];
      const cityLocations = [];
      locations.push({
        locationId: '-1',
        name: t('All.1'),
      });

      allBranches.forEach((branch) => {
        if (!this.containsLocation(locations, branch.BranchType.BranchTypeID)) {
          locations.push({
            locationId: branch.BranchType.BranchTypeID,
            name: branch.BranchType.Name,
          });

          if (!branch.BranchType.Name.trim().toLowerCase().includes('airport')) {
            citySegmentLocations.push({
              locationId: branch.BranchType.BranchTypeID,
              name: branch.BranchType.Name,
            });
          }
        }
        if (branch.BranchType.Name.trim().toLowerCase().includes('airport')) {
          airPortLocations.push({
            branchTypeID: branch.BranchType.BranchTypeID,
            locationId: branch._id,
            name: branch.Name,
          });
        } else {
          cityLocations.push({
            branchTypeID: branch.BranchType.BranchTypeID,
            locationId: branch._id,
            name: branch.Name,
          });
        }
      });
      this.setState({ locations: locations });
      this.setState({ airPortLocations: airPortLocations });
      this.setState({ citySegmentLocations: citySegmentLocations });
      this.setState({ cityLocations: cityLocations });
    });
  };

  onMobileAppClick = () => {
    this.setState({ errorMessage: 'Mobile App is under construction.  Check back soon.' });
    return false;
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  onFooterMenuLinkClick = (path) => {
    this.props.history.push(path);
  };

  onAcceptContinue = () => {
    var cookiesPolicy = 'accepted';
    localStorage.setItem('CookiePolicyAccepted', cookiesPolicy);
    const CookiePolicyAccepted = localStorage.getItem('CookiePolicyAccepted');
    this.setState({ CookiePolicyAccepted: CookiePolicyAccepted });
    //window.location.reload();
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <footer className="footer-section">
          <div className="footer-top-wrap">
            <div className="footer-nav-menu-wrap first">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-12">
                    <h4>Airport Locations</h4>
                  </div>
                  <div className="col-lg-10 col-12">
                    <ul className="nav-item-ul">
                      {this.state.airPortLocations.map((loc) => {
                        return (
                          <li className="nav-item" key={loc.locationId}>
                            <a
                              className={
                                this.state.selectedLocation == loc.locationId
                                  ? 'nav-link active'
                                  : 'nav-link '
                              }
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-home"
                              role="tab"
                              aria-controls="pills-home3"
                              aria-selected="false"
                              onClick={() => this.onLocationMenuClick(loc.branchTypeID)}
                            >
                              {loc.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-nav-menu-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-12">
                    <h4>City Locations</h4>
                  </div>
                  <div className="col-lg-10 col-12">
                    {this.state.citySegmentLocations.map((branch) => {
                      return (
                        <ul className="nav-item-ul" key={branch.locationId}>
                          <span>{branch.name}</span>
                          {this.state.cityLocations.map((loc) => {
                            return branch.locationId == loc.branchTypeID ? (
                              <li className="nav-item" key={loc.locationId}>
                                <a
                                  id="pills-home-tab"
                                  data-toggle="pill"
                                  href="#pills-home"
                                  role="tab"
                                  aria-controls="pills-home3"
                                  aria-selected="false"
                                  onClick={() => this.onLocationMenuClick(loc.branchTypeID)}
                                >
                                  {loc.name}
                                </a>
                              </li>
                            ) : null;
                          })}
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom-wrap">
            <div className="container">
              <div className="row justify-content-md-between justify-content-center">
                <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                  <a href="/" className="footer-logo" title="Dollar">
                    <img src={dollarlogo} width="105" height="29" alt="Dollar" />
                  </a>
                  <a href={'tel:800 70011'} className="footer-mobile" title="Call">
                    800 70011
                  </a>
                  <a href={'tel:+968 2 4590824'} className="footer-mobile" title="Call">
                    +968 2 4590824
                  </a>
                  <a
                    href="mailto:reservations@dollaroman.com"
                    className="footer-mobile footer-email"
                    title="Mail"
                  >
                    reservations@dollaroman.com
                  </a>
                </div>

                <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-sm-0">
                  <h5>Download App</h5>
                  <ul className="footer-nav-wrap">
                    <li>
                      <a
                        title="Google Play Store"
                        className="download-store-app"
                        onClick={this.onMobileAppClick}
                      >
                        <img src={googleplay} alt="Google Play Store" />
                      </a>
                    </li>
                    <li>
                      <a
                        title="App Store"
                        className="download-store-app"
                        onClick={this.onMobileAppClick}
                      >
                        <img src={appstore} alt="App Store" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                  <h5>The Group</h5>
                  <ul className="footer-nav-wrap">
                    <li>
                      <a href="https://www.aaagroup.com/" target="_blank" title="Arenco">
                        <img src={arencologo} width="80" height="86" alt="Arenco" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                  <h5 className="empty">&nbsp;</h5>
                  <ul className="list-unstyled social-media-footer justify-content-lg-end">
                    <li>
                      <a href="https://twitter.com/dollar_oman" target="_blank">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    {/* <li>
                                        <a href="https://plus.google.com/u/0/101042575278890939651" target="_blank">
                                            <i className="fa fa-google-plus" aria-hidden="true"></i>
                                        </a>
                                    </li> */}
                    <li>
                      <a href="https://www.facebook.com/oman.dollar/" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/dollar-oman" target="_blank">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
            <Dialog
              open={this.state.errorMessage.length > 0}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.errorMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}

          {this.state.loading == true ? (
            <div style={{ verticalAlign: 'center' }}>
              <Spinner animation="border" />
              &nbsp;{this.state.loadingText}
            </div>
          ) : null}
        </footer>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(ListingFooter)), ListingFooter);
