import { GoogleApiWrapper } from 'google-maps-react';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import FlexiMonthly from '../MonthlySubscription/Tabs/FlexiMonthly';
import ManageBooking from '../MonthlySubscription/Tabs/ManageBooking';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';
import '../assets/css/slick.css';
import { contentInstance } from '../axios/axiosInstance';
import Banner from './Banner';

class BannerSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedWidget: false,
      imageString: '',
      BannerData: [],
      activeTab: 'startBooking',
      manageBanner: false,
    };
  }
  onFeedBackClick = () => {
    this.props.history.push('/feedback');
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ promocode: e.target.value });
  };

  componentDidMount() {
    this.getBanner();

    const searchParams = new URLSearchParams(this.props.location.search);
    const activeTabParam = searchParams.get('tab');
    if (
      activeTabParam &&
      ['startBooking', 'manageBooking', 'flexiMonthly'].includes(activeTabParam)
    ) {
      this.setState({ activeTab: activeTabParam });
    }
  }

  getBanner = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    contentInstance
      .get(`banners/allUi?countryCode=${code}&placement=${1}`)
      .then((response) => {
        this.setState({
          imageString: response.data.result[1].BannerAttachment.ImageString,
          BannerData: response.data.result,
        });
      })
      .catch((error) => {});
  };

  setActiveTab = (tab) => {
    this.props.history.push(`?tab=${tab}`);
    this.setState({ activeTab: tab });
  };

  onOutSideClick = () => {
    /*document.getElementById('body').className = '';*/
  };

  handleBanner = (value) => {
    setTimeout(() => {
      this.setState({ manageBanner: value });
    }, 300);
  };

  render() {
    const { t } = this.props;
    const { activeTab } = this.state;
    return (
      <>
        <section className="banner-section banner-new-section" onClick={this.onOutSideClick}>
          <section className="sort-c1">
            <nav style={{ background: 'white' }}>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className={`nav-link ${activeTab === 'startBooking' ? 'active' : ''}`}
                  id="bookingTab"
                  type="button"
                  onClick={() => this.setActiveTab('startBooking')}
                >
                  {t('Start Booking.1')}
                </button>
                <button
                  className={`nav-link ${activeTab === 'manageBooking' ? 'active' : ''}`}
                  id="manageBookingtab"
                  type="button"
                  onClick={() => this.setActiveTab('manageBooking')}
                >
                  {t('Manage Booking.1')}
                </button>
                <button
                  className={`nav-link ${activeTab === 'flexiMonthly' ? 'active' : ''}`}
                  id="fleximonthly"
                  type="button"
                  onClick={() => this.setActiveTab('flexiMonthly')}
                >
                  {t('Flexi Monthly.1')}
                </button>
              </div>
            </nav>

            <div className="inner-wrapper" style={{ background: 'white' }}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content " id="nav-tabContent">
                      {activeTab === 'startBooking' && (
                        <StartBooking
                          manageBanner={this.handleBanner}
                          handleHeaderActive={this.props.handleHeaderActive}
                        />
                      )}
                      {activeTab === 'manageBooking' && <ManageBooking />}
                      {activeTab === 'flexiMonthly' && (
                        <FlexiMonthly
                          manageBanner={this.handleBanner}
                          handleHeaderActive={this.props.handleHeaderActive}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Banner
            BannerData={this.state.BannerData}
            manageBanner={this.state.manageBanner}
          ></Banner>
        </section>
        <a className="feedback-sticky" title="Feedback" onClick={this.onFeedBackClick}>
          {t('Feedback.1')}
        </a>
        <div className="info-bttn-sidesticky">
          {/* <a href='tel:+911234567890'><i className="fa fa-phone"></i></a> */}
          <a onClick={this.onContactUsClick}>
            <i className="fa fa-phone"></i>
          </a>
          <a href="https://goo.gl/maps/aQ2iKxdx2TRrZfBo8" target="_blank">
            <i className="fa fa-map-marker"></i>
          </a>
        </div>
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(
    GoogleApiWrapper({
      apiKey: 'AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo',
    })(withRouter(BannerSection)),
  ),
  BannerSection,
);
