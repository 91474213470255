import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class FAQSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleSelect(0);
  }

  handleSelect = (index) => {
    this.setState({ avaliableindex: index });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section  grey-bg py-4">
          <div className="container-fluid">
            <div className="">
              <div className="pickup-location-box">
                <h2 className="border-botom">{t('FAQ.1')}</h2>
              </div>
              <div className="faq-wrapper">
                <div className="">
                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThirteen"
                            onClick={() => {
                              this.handleSelect(13);
                            }}
                          >
                            Is there any fee for returning the car in airport counters?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseThirteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            All vehicles off hired/returned in any of our airport counters will be
                            charged an airport drop off fees. If you wish to avoid this drop off
                            fees, rented vehicles can be returned to any non-airport branch
                            locations between 08:00H to 17:30H (Saturday to Thursday). Presently the
                            airport drop off fees is as follows: Muscat Airport – RO 5.00; Salalah
                            Airport – RO 5.00
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFourteen"
                            onClick={() => {
                              this.handleSelect(14);
                            }}
                          >
                            Do you provide chauffeur service?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFourteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            Chauffeur Service is available only on request at RO 25/day for 8hrs
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFifteen"
                            onClick={() => {
                              this.handleSelect(15);
                            }}
                          >
                            What about traffic violations?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFifteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            Drivers are expected to abide with the traffic rules and regulations.
                            For every traffic fine incurred will be charged to the hirer with
                            additional 10% service charge.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseSixteen"
                            onClick={() => {
                              this.handleSelect(16);
                            }}
                          >
                            What do we do in the case of a breakdown or Accidents?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseSixteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            Dollar Rent A Car offers you 24hr breakdown and roadside assistance. And
                            In the unforeseen circumstances of your vehicle being involved in an
                            accident please note that the vehicle should not be moved from the point
                            of the accident or damage, except in the case where the damage is minor
                            and the vehicle is causing an obstruction to the traffic. You will then
                            call the police and remain with the vehicle in order to obtain a police
                            report to be submitted to Dollar Rent A Car so that an Insurance claim
                            can be made. Please notify our 24/7 toll free number - 800 70011 and
                            they will facilitate any replacement vehicle that may be required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseSeventeen"
                            onClick={() => {
                              this.handleSelect(17);
                            }}
                          >
                            Do I need to have an International Driving License?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseSeventeen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            All drivers must carry a locally accepted driving license. Hirers must
                            hold a valid International Driving License from the country on whose
                            passport the hirer is traveling. In the absence of an International
                            Driving License customers are advised to obtain a Temporary Driving
                            Permit upon arrival in the Sultanate before the commencement of rental.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(FAQSection)), FAQSection);
