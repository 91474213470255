import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getCustomerId } from '../Common/Util';
import { productInstance } from '../axios/axiosInstance';

class SiteMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFindReservationClick = (path) => {
    const customerId = getCustomerId();
    if (customerId == '00000000-0000-0000-0000-000000000000') {
      this.props.history.push('/findreservation');
    } else {
      this.props.history.push('/profile');
    }
  };
  onFooterMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };
  componentDidMount = () => {
    this.getVehicleTypes();
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });
    });
  };

  onViewMoreClick = (Id) => {
    this.props.history.push(`/vehicletypes/${Id}`);
  };

  onOfferClick = (Id) => {
    this.props.history.push(`${Id}`);
  };

  onLocationMenuClick = (locationId) => {
    localStorage.setItem('locationid', locationId);
    this.props.history.push(`/locations`);
  };

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i].locationId.toString() == id.toString()) return true;
    }
    return false;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />

        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2">
            <div className="container-fluid">
              <div className="sitemapPage">
                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Home.1')} className="footer-nav-title">
                        {t('Home.1')}
                      </a>
                    </li>
                    <li>
                      <a href="" title={t('Offers.1')} onClick={() => this.onOfferClick('/offers')}>
                        {t('Offers.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Business Solutions.1')}
                        onClick={() => this.onOfferClick('/business-solutions')}
                      >
                        {t('Business Solutions.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Dollar Prestige.1')}
                        onClick={() => this.onOfferClick('/dollar-prestige')}
                      >
                        {t('Dollar Prestige.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Locations.1')}
                        onClick={() => this.onFooterMenuLinkClick('/locations')}
                      >
                        {t('Locations.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Support.1')}
                        onClick={() => this.onFooterMenuLinkClick('/contactus')}
                      >
                        {t('Support.1')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Reservations.1')} className="footer-nav-title">
                        {t('Reservations.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        title={t('Start a Reservation.1')}
                        onClick={() => this.onFooterMenuLinkClick('/start-a-reservation')}
                      >
                        {t('Start a Reservation.1')}
                      </a>
                    </li>
                    <li>
                      <a title={t('View, Modify, Cancel.1')} onClick={this.onFindReservationClick}>
                        {t('View, Modify, Cancel.1')}
                      </a>
                    </li>

                    <li>
                      <a
                        href=""
                        title="DollarXpress Booking"
                        onClick={() => this.onFooterMenuLinkClick('/dollar-xpress')}
                      >
                        {t('DollarXpress.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Monthly Rentals.1')}
                        onClick={() => this.onFooterMenuLinkClick('/monthly-rentals')}
                      >
                        {t('Monthly Rentals.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Request a Receipt.1')}
                        onClick={() => this.onFooterMenuLinkClick('/requestreceipt')}
                      >
                        {t('Request a Receipt.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Submit Feedback.1')}
                        onClick={() => this.onFooterMenuLinkClick('/feedback')}
                      >
                        {t('Submit Feedback.1')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Vehicles.1')} className="footer-nav-title">
                        {t('Vehicles.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('All Vehicles.1')}
                        onClick={() => {
                          this.onViewMoreClick('-1');
                        }}
                      >
                        {t('All Vehicles.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Dollar Prestige.1')}
                        onClick={() => {
                          this.onViewMoreClick('fc8ac3c3-9ce7-41e7-9856-4aa0e09741d6');
                        }}
                      >
                        {t('Dollar Prestige.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('People Movers.1')}
                        onClick={() => {
                          this.onViewMoreClick('619340ab-0e37-4e2f-a3b6-1eaabd363bc7');
                        }}
                      >
                        {t('People Movers.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Commercial Vehicles.1')}
                        onClick={() => {
                          this.onViewMoreClick('f2a4bbb4-bfd8-4285-bbfc-df5b00567571');
                        }}
                      >
                        {t('Commercial Vehicles.1')}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Business.1')} className="footer-nav-title">
                        {t('Business.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title="Why and How"
                        onClick={() => this.onFooterMenuLinkClick('/fleet-management-solutions')}
                      >
                        Fleet Management Solutions
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Specialized Vehicles.1')}
                        onClick={() => this.onFooterMenuLinkClick('/specialized-vehicles')}
                      >
                        {t('Specialized Vehicles.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Explore Corporate Fleet.1')}
                        onClick={() => this.onFooterMenuLinkClick('/explore-corporate-fleet')}
                      >
                        {t('Explore Corporate Fleet.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Business Solutions.1')}
                        onClick={() => this.onFooterMenuLinkClick('/business-solutions')}
                      >
                        {t('Business Solutions.1')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Customer Service.1')} className="footer-nav-title">
                        {t('Customer Service.1')}
                      </a>
                    </li>

                    <li>
                      <a
                        href=""
                        title={t('Help & FAQs.1')}
                        onClick={() => this.onFooterMenuLinkClick('/faq')}
                      >
                        {t('Help & FAQs.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Contact us.1')}
                        onClick={() => this.onFooterMenuLinkClick('/contactus')}
                      >
                        {t('Contact us.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Book a Service.1')}
                        onClick={() => this.onFooterMenuLinkClick('/book-service')}
                      >
                        {t('Book a Service.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Terms and Conditions.1')}
                        onClick={() => this.onFooterMenuLinkClick('/termsandconditions')}
                      >
                        {t('Terms and Conditions.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Rental Information.1')}
                        onClick={() => this.onFooterMenuLinkClick('/rental-guide')}
                      >
                        {t('Rental Information.1')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Locations.1')} className="footer-nav-title">
                        {t('Locations.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Explore Locations.1')}
                        onClick={() => this.onFooterMenuLinkClick('/locations')}
                      >
                        {t('Explore Locations.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Airport Locations.1')}
                        onClick={() => {
                          this.onLocationMenuClick('dc5e6235-b3e2-4b04-bdd9-67bfd529cf65');
                        }}
                      >
                        {t('Airport Locations.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Abu Dhabi.1')}
                        onClick={() => {
                          this.onLocationMenuClick('8f001d78-8df6-42bb-be8d-9d539e2a24d6');
                        }}
                      >
                        {t('Abu Dhabi.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Muscat.1')}
                        onClick={() => {
                          this.onLocationMenuClick('b4b6ed99-9b2e-4e21-9591-b3ae4957f5ec');
                        }}
                      >
                        {t('Muscat.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Salalah.1')}
                        onClick={() => {
                          this.onLocationMenuClick('9d74b862-92b9-4232-aaa2-a351e4880bde');
                        }}
                      >
                        {t('Salalah.1')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sitemap-col">
                  <ul className="footer-nav-wrap">
                    <li>
                      <a title={t('Know Us.1')} className="footer-nav-title">
                        {t('Know Us.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('About Us.1')}
                        onClick={() => this.onFooterMenuLinkClick('/knowus/top')}
                      >
                        {t('About Us.1')}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.aaagroup.com/" target="_blank" title={t('The Group.1')}>
                        {t('The Group.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Vision & Mission.1')}
                        onClick={() => this.onFooterMenuLinkClick('/knowus/vision')}
                      >
                        {t('Vision & Mission.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Why Dollar.1')}
                        onClick={() => this.onFooterMenuLinkClick('/knowus/whydollar')}
                      >
                        {t('Why Dollar.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Awards.1')}
                        onClick={() => this.onFooterMenuLinkClick('/knowus/awardreco')}
                      >
                        {t('Awards.1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        title={t('Careers.1')}
                        onClick={() => this.onFooterMenuLinkClick('/jobposting')}
                      >
                        {t('Careers.1')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>

        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(SiteMap)), SiteMap);
