import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import covid19banner from '../assets/images/covid-19-response.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';

class Covid19 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />
        <div>
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h5 className="font-bold mb-3 mt-2 ">COVID-19 UPDATE</h5>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h3 className="mb-4 w-100">Dollar Car Rental COVID-19 Response.</h3>
                            <span>
                              Whether you rent a car at the airport or at one of our neighborhood
                              locations, we’re here to support your travel needs. At Dollar Car
                              Rental Oman, we strive to make your travels easier, and our focus
                              remains on getting you on your way safely and confidently.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={covid19banner}
                        alt={t('Dollar Covid 19 Banner.1')}
                        className="img-fluid rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <h5 className="font-weight-bold">Government Guidelines</h5>
                    <p className="text_p mt-4">
                      We are closely monitoring Coronavirus (COVID-19) and following the current
                      guidance from the leading government and health authorities to ensure we are
                      taking the right actions to protect our customers, employees and the
                      communities where we operate.
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Safety Standards</h5>
                    <p className="text_p mt-4">
                      Please also know that we continue to maintain our high standards for safety
                      and cleanliness. In addition to practicing preventative measures to reduce the
                      spread of germs within our facilities, all of our vehicles go through a
                      rigorous, multi-step cleaning process for each rental. We are remaining
                      vigilant in upholding these practices and will take additional precautions as
                      recommended by the World Health Organization (WHO) and local governments.
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Multi Point Cleaning</h5>
                    <p className="text_p mt-4">
                      Your safety is our foremost priority. Before each rental, our cars are cleaned
                      and disinfected according to WHO guidelines following our multi-point cleaning
                      process which we listed.
                    </p>
                    <br />

                    <h5 className="font-weight-bold">Contact for more info</h5>
                    <p className="text_p mt-4">
                      During this time, we recognize flexibility is more important than ever. Should
                      your plans change, adjusting existing reservations is easy on
                      www.dollaroman.com or call our 24x7 support center at 800 70011. We continue
                      to monitor this evolving situation and will keep you informed of future
                      updates.
                      <br />
                      <br />
                      Until then,
                      <br />
                      <br />
                      Stay Safe.
                    </p>
                    <br />
                  </div>

                  <div className="col-lg-5">
                    <div className="p-3 bg_custom mr-6">
                      <h5 className="font-weight-bold">Multi-point cleaning process</h5>
                      <br />
                      <ul className="check-box-listing ">
                        <li>
                          <span>Disinfect vehicle at return area.</span>
                        </li>
                        <br />
                        <li>
                          <span>
                            At cleaning area, inspect interior of vehicle, tires and overall vehicle
                            condition.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>Check wiper fluid and replenish if needed.</span>
                        </li>
                        <br />
                        <li>
                          <span>Remove trash and lost/found items.</span>
                        </li>
                        <br />
                        <li>
                          <span>Vacuum trunk, front seat area and rear seat area.</span>
                        </li>
                        <br />
                        <li>
                          <span>Clean window interiors.</span>
                        </li>
                        <br />
                        <li>
                          <span>Spray odor eliminator.</span>
                        </li>
                        <br />
                        <li>
                          <span>
                            Disinfect all high-touch contact areas (steering wheel, consoles, door
                            handles, etc.)
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>Wash exterior of vehicle.</span>
                        </li>
                        <br />
                        <li>
                          <span>Perform quality check.</span>
                        </li>
                        <br />
                        <li>
                          <span>Move vehicle to parking stall.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(Covid19);
