import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import Login from '../Account/LoginPopup';
import dollarLogo from '../assets/images/dollar.svg';
import { getUserInfo } from '../Common/Util';
import TopLeftMenu from './TopLeftMenu';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginNow: false,
      showRegisterNow: false,
      DisplayName: '',
      isLoginSuccessful: false,
      language: 'EN',
      showStickHeader: false,
      customerId: '',
      leftMenu: false,
      showReadMore: true,
      profileMenu: false,
      windowSize: 1024,
      isMobileView: false,
    };
  }

  handleClick(lang) {
    const { t, i18n } = this.props;

    i18n.changeLanguage(lang);

    if (lang == 'EN') {
      this.setState({
        language: 'EN',
      });
    }
    if (lang == 'Oman') {
      this.setState({
        language: 'Oman',
      });
    }
    window.location.reload();
  }

  onHideReadMore = () => {
    this.setState({ showReadMore: false });
  };

  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
    this.setState({ isMobileView: false });
    if (window.innerWidth < 770) {
      this.setState({ isMobileView: true });
    }
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll, true);
    const userinfo = getUserInfo();
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
    if (userinfo !== null) {
      this.setState({
        DisplayName: userinfo.DisplayName,
      });
      this.setState({
        isLoginSuccessful: true,
      });
      this.setState({
        customerId: userinfo.CustomerID,
      });
    } else {
      this.setState({
        isLoginSuccessful: false,
      });
    }

    const language = localStorage.getItem('i18nextLng');
    this.setState({
      language: language,
    });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let lastScrollY = window.scrollY;
    if (lastScrollY > 20) {
      this.setState({
        showStickHeader: true,
      });
    } else {
      this.setState({
        showStickHeader: false,
      });
    }
  };

  userinfo = () => {};
  toogleLoginNowPopup = (showHide) => {
    this.setState({
      showLoginNow: showHide,
    });
  };

  onHireClick = () => {
    this.props.history.push('/');

    scroll.scrollToTop();
  };

  onOfferClick = () => {
    this.props.history.push('/offers');
  };

  onFlexiMonthlyClick = () => {
    this.props.history.push('/fleximonthly');
  };

  onLeasingClick = () => {
    this.props.history.push('/longtermleasing');
  };

  onLocationClick = () => {
    this.props.history.push('/locations');
  };

  onDollarPrestigeClick = () => {
    this.props.history.push('/dollarprestige');
  };

  onReadMoreClick = () => {
    this.props.history.push('/covid19');
  };

  onWhoweareClick = () => {
    this.props.history.push('/contactus');
  };

  LeaseToOwnClick = () => {
    this.props.history.push('/rent-to-own');
  };

  TeachersCampaign = () => {
    this.props.history.push('/teachers-offer');
  };

  onLeasingClick = () => {
    this.props.history.push('/personalleasing');
  };

  onBusinessClick = () => {
    this.props.history.push('/businesssolutions');
  };

  onWelcomeClick = (Id) => {
    this.props.history.push(`/profile/${Id}`);
  };

  onLeftMenuClick = () => {
    if (!this.state.leftMenu) {
      document.getElementById('body').className = 'toggle-left-body';
    } else {
      document.getElementById('body').className = '';
    }
    this.setState({
      leftMenu: !this.state.leftMenu,
    });
  };

  onLogoutClick = () => {
    localStorage.removeItem('userinfo');
    window.location = '/';
  };

  onProfileMenuClick = () => {
    if (!this.state.profileMenu) {
      this.setState({
        profileMenu: true,
      });
    } else if (this.state.profileMenu) {
      this.setState({
        profileMenu: false,
      });
    }
  };

  render() {
    const { t } = this.props;

    let LoginAndRegister = (
      <>
        <Link to="#" onClick={() => this.toogleLoginNowPopup(true)}>
          {t('Login.1')}
        </Link>

        <Link to="/register">{t('Register.1')}</Link>
      </>
    );

    let welcome = (
      <>
        <div className="login-wrapper">
          <h6
            className="user-name"
            onClick={() => this.onWelcomeClick(this.state.customerId)}
            style={{ cursor: 'pointer' }}
          >
            {t('Welcome.1')} {this.state.DisplayName}
          </h6>
          <h6 className="logout-bttn" onClick={this.onLogoutClick} style={{ cursor: 'pointer' }}>
            {t('Logout.1')}
          </h6>
        </div>
      </>
    );

    return (
      <div>
        {this.state.showReadMore ? (
          <div className="header-top-info text-center">
            <div className="container">
              Want to browse Dollar Car Rental UAE.
              <a
                title="Read More"
                className="ml-1"
                href="https://www.dollaruae.com/"
                target="_blank"
              >
                Show
              </a>
              <a title="Close" className="ml-3 close-info" onClick={this.onHideReadMore}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        ) : null}

        <header
          className={
            this.state.showStickHeader
              ? 'header-nav sticky-top header-sticky'
              : 'header-nav sticky-top'
          }
        >
          <div className="container">
            <div className="navbar navbar-expand-lg navbar-light p-0">
              <a
                className={
                  this.state.leftMenu
                    ? 'toggle-icon left-wrap-menu cross-toggle'
                    : 'toggle-icon left-wrap-menu'
                }
                onClick={this.onLeftMenuClick}
              >
                <span className="one"></span>
                <span className="two"></span>
                <span className="three"></span>
              </a>
              <a href="/" className="logo">
                <img src={dollarLogo} width="152" height="39.95" alt="Dollar Car Rental Oman" />
              </a>
              <button
                className="navbar-toggler toggle-icon d-none"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.onHireClick}
              >
                <span className="one"></span>
                <span className="two"></span>
                <span className="three"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <nav className="navigation-wrap">
                  <ul className="navbar-nav">
                    <li
                      className={
                        this.state.showOfferDiv
                          ? 'nav-item has-submenu menu-toggle'
                          : 'nav-item has-submenu'
                      }
                    >
                      <a title="Offers" onClick={this.onOfferClick}>
                        {t('Offers.1')}
                      </a>
                    </li>
                    <li
                      className={
                        this.state.showOfferDiv
                          ? 'nav-item has-submenu menu-toggle'
                          : 'nav-item has-submenu'
                      }
                    >
                      <a title="Flexi Monthly" onClick={this.onFlexiMonthlyClick}>
                        Flexi Monthly
                      </a>
                    </li>
                    <li
                      className={
                        this.state.showOfferDiv
                          ? 'nav-item has-submenu menu-toggle'
                          : 'nav-item has-submenu'
                      }
                    >
                      <a title="Leasing" onClick={this.onLeasingClick}>
                        {t('Leasing.1')}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a title="Business" onClick={this.onBusinessClick}>
                        {t('Business.1')}
                      </a>
                    </li>
                    {/* <li>
                                            <a onClick={() => this.onDollarPrestigeClick()} title="Lease">
                                                {'Dollar Prestige'}
                                            </a>
                                        </li> */}
                    <li className="nav-item">
                      <a onClick={() => this.onLocationClick()} title="Location">
                        {t('Locations.1')}
                      </a>
                    </li>

                    {/* <li className="nav-item">
                                            <a title="Support" onClick={this.onWhoweareClick}>
                                                {t('Support')}
                                            </a>
                                        </li> */}
                    {this.state.isLoginSuccessful == false ? (
                      <>
                        <li className="nav-item login">
                          <Link to="#" onClick={() => this.toogleLoginNowPopup(true)}>
                            {t('Login.1')}
                          </Link>
                        </li>
                        <li className="nav-item register-bttn">
                          <Link to="/register">{t('Register.1')}</Link>
                        </li>{' '}
                      </>
                    ) : null}
                  </ul>
                </nav>
              </div>
              <div className="reguster-right-wrap">
                <div
                  className={
                    this.state.profileMenu
                      ? 'login-register-wrap toggle'
                      : this.state.isMobileView
                        ? 'login-register-wrap'
                        : ''
                  }
                  onClick={this.onProfileMenuClick}
                >
                  {this.state.isMobileView && this.state.isLoginSuccessful ? (
                    <div className="mobile-profile-menu logged-in">
                      {this.state.isLoginSuccessful
                        ? welcome
                        : this.state.isMobileView
                          ? LoginAndRegister
                          : null}
                    </div>
                  ) : (
                    <div className="mobile-profile-menu">
                      {this.state.isLoginSuccessful
                        ? welcome
                        : this.state.isMobileView
                          ? LoginAndRegister
                          : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <TopLeftMenu leftMenuClick={this.onLeftMenuClick}></TopLeftMenu>
        </header>

        {this.state.showLoginNow ? (
          <div className="modal-content">
            <Modal
              show={this.state.showLoginNow}
              dialogClassName="modal-dialog  modal-md"
              onHide={() => this.toogleLoginNowPopup(false)}
            >
              <Login showHeader={true} />
            </Modal>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(withRouter(Header));
