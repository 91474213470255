import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { getLanguage } from '../../Common/Util';
import CheckedRed from '../../assets/images/CheckedRed.svg';
import calendarImg from '../../assets/images/calendarTab.svg';
import closeImg from '../../assets/images/closeX.svg';
import mapPin from '../../assets/images/map-pin.svg';
import promoImg from '../../assets/images/promo.svg';
import { bookingInstance, branchInstance } from '../../axios/axiosInstance';
import AddPromocode from './AddPromocode';
import ChooseDelivery from './ChooseDelivery';
import FlexiMontlyCalender from './FlexiMontlyCalender';
import PickUpPopup from './PickUpPopup';

class FlexiMonthly extends Component {
  constructor(prop) {
    super(prop);
    this.updatePickUpTime = this.updatePickUpTime.bind(this);
    this.state = {
      openPickUpPopup: false,
      selectedValue: null,
      openCalander: false,
      pickUpDate: moment().format('DD MMM YYYY'),
      returnDate: moment().add(3, 'days').format('DD MMM YYYY'),
      pickupTime: '09:00',
      returnTime: '09:00:00',
      pickUp24: '09:00',
      errorMessage: '',
      selectedPicupLocation: null,
      selectedDestinationLocation: null,
      selectedPickupDate: Date().toLocaleString(),
      selectedReturnDate: Date().toLocaleString(),
      chooseDevliveryPopUp: false,
      pickupPeriod: 'AM',
      searchQuery: '',
      isComponentMounted: false,
      size: window.innerWidth,
      popUpScrollPosition: 0,
      openPromoCodeModal: false,
      promoCode: '',
      isDaily: false,
      loading: false,

      deliveryStateName: '',
      deliveryStateCode: '',
      deliveryAddress: '',

      iPAddress: '127.0.0.1',
      customerCountryCode: 'OMR',
      customerCountryName: 'Oman',
    };
    this.popUpRef = createRef();
    this.errorPopUpRef = createRef();
  }

  handlePeriodChange = (period) => {
    this.setState({
      pickupPeriod: period,
    });
  };

  // Method to update pickUpDate
  updatePickUpDate = (date) => {
    const newDate = moment(date, 'DD MMM YYYY').add(3, 'days').format('DD MMM YYYY');
    this.setState({
      pickUpDate: date,
      returnDate: newDate,
    });
  };

  // Method to update pickUpTime
  updatePickUpTime = (time) => {
    this.setState({ pickupTime: time });
  };

  componentDidMount() {
    this.getIPAddress();
    this.setState({ isComponentMounted: true });
    if (this.divElement) {
      document.addEventListener('mousedown', this.handleClickOutside);
      this.divElement.addEventListener('scroll', this.handleScroll);
    }

    if (this.props.page === 'L') {
      if (this.props.pickupLocation !== null) {
        this.setState({
          selectedValue: this.props.pickupLocation,
        });
      }

      this.setState({
        pickUpDate: moment(this.props.PickUpDate).format('DD MMM YYYY'),
        pickupTime: moment(this.props.PickUpDate).format('hh:mm'),
        pickupPeriod: moment(this.props.PickUpDate).format('A'),
      });

      if (
        this.props.promoCode !== null &&
        this.props.promoCode !== undefined &&
        this.props.promoCode !== ''
      ) {
        this.setState({
          promoCode: this.props.promoCode,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.setState({ isComponentMounted: false });
  }

  getIPAddress = () => {
    var config = {
      method: 'get',
      url: 'https://geolocation-db.com/json/',
      headers: {},
    };

    axios(config)
      .then((res) => {
        this.setState({
          iPAddress: res.data.IPv4,
          customerCountryCode: res.data.country_code,
          customerCountryName: res.data.country_name,
        });
      })
      .catch((error) => {});
  };

  componentDidUpdate(prevProps, prevState) {
    if (window.innerWidth !== prevState.size) {
      this.setState({ size: window.innerWidth });
    }
  }

  togglePromoCodeModal = (e) => {
    e.preventDefault();
    this.setState((prev) => ({
      openPromoCodeModal: !prev.openPromoCodeModal,
    }));
  };

  handlePromoCode = (val) => {
    this.setState({ promoCode: val });
  };

  handleClickOutside = (event) => {
    if (this.errorPopUpRef.current && this.errorPopUpRef.current.contains(event.target)) {
      return;
    }

    if (this.popUpRef.current && !this.popUpRef.current.contains(event.target)) {
      this.props.manageBanner(false);
      setTimeout(() => {
        if (this.state.isComponentMounted) {
          this.setState({ openPickUpPopup: false }, () => {
            document.body.style.overflow = 'unset';
          });

          if (!this.state.openPickUpPopup && !this.state.openCalander) {
            this.props.handleHeaderActive(true);
          }
        }
      }, 300);
      this.setState({ openCalander: false });
      this.setState({ chooseDevliveryPopUp: false });
    }
  };

  clearSearchProps = () => {
    const updatedSelectedValue = { ...this.state.selectedValue };
    updatedSelectedValue.Name = '';
    this.setState({ selectedValue: updatedSelectedValue });
  };

  convertTo12HourFormat = (time24) => {
    // Splitting the input time string into hours and minutes
    let timeSplit = time24.split(':');
    let hours = parseInt(timeSplit[0]);
    let minutes = parseInt(timeSplit[1]);

    // Determine AM or PM
    let period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (00:00)

    // Format minutes to ensure always 2 digits
    minutes = (minutes < 10 ? '0' : '') + minutes;

    // Construct the 12-hour time string
    let time12 = hours + ':' + minutes;

    return [time12, period];
  };

  convertTo24HourFormat = (time12) => {
    // Splitting the input time string into hours, minutes, and period
    let timeSplit = time12.split(/:| /);
    let hours = parseInt(timeSplit[0]);
    let minutes = parseInt(timeSplit[1]);
    let period = timeSplit[2];

    // Convert hours to 24-hour format
    if (period === 'PM' && hours !== 12) {
      hours = hours + 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0; // Midnight (12:00 AM)
    }

    // Format hours and minutes to ensure always 2 digits
    hours = (hours < 10 ? '0' : '') + hours;
    minutes = (minutes < 10 ? '0' : '') + minutes;

    // Construct the 24-hour time string
    let time24 = hours + ':' + minutes;

    return time24;
  };

  handleReturnDate = (value) => {
    this.setState({ returnDate: value });
  };

  handleSelectedValue = (value) => {
    this.setState({ selectedValue: value, searchQuery: '', openPickUpPopup: false }, () => {
      document.body.style.overflow = 'unset';
    });

    branchInstance
      .get(`/hrsToGetReady?branchId=${value?._id}&isDeliverToMe=${value?.Address?.length > 0}`)
      .then((response) => {
        const timeReady = response.data.result;
        this.setState({
          pickUpDate: moment.tz(timeReady.PickupOn, 'Asia/Muscat').format('DD MMM YYYY'),
        });

        this.setState({
          returnDate: moment.tz(timeReady.ReturnOn, 'Asia/Muscat').format('DD MMM YYYY'),
        });

        this.setState({
          pickUp24: moment.tz(timeReady.PickupOn, 'Asia/Muscat').format('HH:mm'),
        });

        const [time, period] = this.convertTo12HourFormat(this.state.pickUp24);
        this.setState({
          pickupTime: time,
        });

        this.setState({
          pickupPeriod: period,
        });

        this.setState({
          returnTime: moment.tz(timeReady.ReturnOn, 'Asia/Muscat').format('HH:mm'),
        });
      })
      .catch((error) => {});
    this.props.handleHeaderActive(true);
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  validateSearch = () => {
    let lngParam = getLanguage();
    this.setState({ errorMessage: '' });
    if (this.state.selectedValue === null || this.state.selectedValue === undefined) {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'Please select Pickup Location', loading: false });
      } else {
        //ar
        this.setState({ errorMessage: 'الرجاء تحديد بيك اب الموقع', loading: false });
      }

      return false;
    }

    return true;
  };

  handlePopUpAirport = (index) => {
    this.setState({ openPickUpPopup: false });
  };

  closeCalendar = () => {
    this.setState({ openCalander: false });
  };

  clearPopOver() {
    document.getElementById('body').className = '';
    this.setState({ searchWidgetMode: 0 });
    this.setState({ showPopover: false });
  }

  concatTimeAndDate() {
    let selectedPickupDate =
      moment(this.state.pickUpDate).format('DD MMM YYYY') +
      ', ' +
      this.state.pickupTime +
      ' ' +
      this.state.pickupPeriod;

    return selectedPickupDate;
  }

  searchClickHandler = () => {
    this.setState({ loading: true });

    if (this.validateSearch()) {
      var checkOutID = '00000000-0000-0000-0000-000000000000';

      if (this.state?.deliveryStateName?.length <= 0)
        checkOutID = this.state.selectedValue?._id?.toString();

      if (this.state.selectedValue === '') {
        this.setState({ errorMessage: 'Please select Pickup Location' });
        return;
      }

      const selectedPickUpDate = this.concatTimeAndDate();

      var data = {
        CheckOutOn: selectedPickUpDate,
        Daily: this.state.isDaily,
        RentalDays: this.state.dayBetween,
        IPLocation: {
          IPAddress: this.state.iPAddress,
          Country_code: this.state.customerCountryCode,
          Country_name: this.state.customerCountryName,
        },
      };

      if (this.state.deliveryStateName && this.state.deliveryStateName.length)
        data.DeliveryDetails = {
          StateName: this.state.deliveryStateName,
          StateCode: this.state.deliveryStateCode,
          Address: this.state.deliveryAddress,
        };

      if (this.state.returnStateName && this.state.returnStateName.length)
        data.PickupDetails = {
          StateName: this.state.returnStateName,
          StateCode: this.state.returnStateCode,
          Address: this.state.returnAddress,
        };

      if (checkOutID && checkOutID.length && checkOutID !== '00000000-0000-0000-0000-000000000000')
        data.CheckOutBranchID = checkOutID;

      if (this.props.modifiedReservationID && this.props.modifiedReservationID.length)
        data.ModifiedReservationID = this.props.modifiedReservationID;

      const code = process.env.REACT_APP_COUNTRY_CODE;
      bookingInstance
        .post(`validate?countryCode=${code}`, data)
        .then((response) => {
          if (response.data.result.data.isPassed == true) {
            var BookingInfo = [
              {
                CheckOutLocationId: checkOutID,
                PickUpDate: selectedPickUpDate,
                pickupLocationName: this.state.selectedValue.Name,
                pickupLocationAddress: this.state.selectedValue.Address,
                pickupLocationMessage: this.state.selectedValue.Message,
                pickupLocationBranchDescription: this.state.selectedValue.Description,
                deliveryState: this.state.deliveryStateName,
                deliveryCode: this.state.deliveryStateCode,
                deliveryAddress: this.state.deliveryAddress,
                ShukranId: this.state.promoCode.length === 16 ? this.state.promoCode : '',
                ModifiedReservationID: this.props.modifiedReservationID,
                Daily: this.state.isDaily,
                IPLocation: {
                  IPAddress: this.state.iPAddress,
                  Country_code: this.state.customerCountryCode,
                  Country_name: this.state.customerCountryName,
                },
              },
            ];

            localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
            window.location = '/monthlybooking';
          } else {
            this.setState({
              errorMessage: response.data.result.data.message,
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            errorMessage: 'Something is not correct, please try again.',
            loading: false, // Set loading to false if there is an error
          });
        });
    }
  };

  onPickupBranchSelected = (data) => {
    this.handleSelectedValue(data);
  };

  handleChooseDelivery = (isOpen, value) => {
    this.setState({ chooseDevliveryPopUp: isOpen });
    if (value === 0) {
      this.setState({ openPickUpPopup: false });
    } else {
      this.setState({ openPickUpPopup: true });
    }
  };

  chooseDeliveryValue = (stateName, address, stateCode) => {
    this.setState({
      deliveryStateName: stateName,
      deliveryAddress: address,
      deliveryStateCode: stateCode,
    });
  };

  handleScroll = (event) => {
    const scrollPosition = this.divElement.scrollTop;
    if (this.popUpRef.current && !this.popUpRef.current.contains(event.target)) {
      this.setState({ popUpScrollPosition: scrollPosition });
    }
  };

  render() {
    const { t } = this.props;
    const { openPromoCodeModal } = this.state;
    return (
      <>
        <div className="" id="flexiMonthly" role="tabpanel" aria-labelledby="fleximonthly">
          <form action="" onSubmit={(e) => this.searchClickHandler(e)}>
            <div className="inner-wrapper">
              <div className="pick-return-location inner-box">
                <label htmlFor="pickupAndReturnLocation" style={{ marginBottom: 0 }}>
                  <span>{t('Pickup Location.1')}</span>
                </label>

                <div
                  className={`input ${this.state.openPickUpPopup && 'active'}`}
                  onClick={() => {
                    this.setState({ openPickUpPopup: true }, () => {
                      if (window.innerWidth > 567) {
                        document.body.style.overflow = 'hidden';
                      }
                    });
                    this.props.manageBanner(true);
                    this.props.handleHeaderActive(false);
                  }}
                >
                  <div className="icon">
                    <img src={mapPin} alt="" />
                  </div>
                  <input
                    type="text"
                    value={this.state.selectedValue?.Name || ''}
                    placeholder="Airport, City or Address"
                    id="pickupAndReturnLocationFlexi"
                    autoComplete="off"
                    autoCorrect="off"
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        selectedValue: {
                          ...prevState.selectedValue,
                          Name: e.target.value,
                        },
                        searchQuery: e.target.value,
                      }))
                    }
                  />
                  {this.state.selectedValue?.Name && this.state.openPickUpPopup && (
                    <button
                      className="close-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ selectedValue: null });
                      }}
                    >
                      <img src={closeImg} alt="" />
                    </button>
                  )}
                </div>
              </div>
              <div className="pickup-date">
                <input
                  type="text"
                  name="pickUpReturnDate"
                  value="01/01/2018 "
                  readOnly
                  id="pickUpReturnDate"
                />

                <div className="inner-box">
                  <label htmlFor="pickupAndReturn">
                    <span>{t('Pickup Date & Time.1')}</span>
                  </label>
                  <div
                    className={`input ${this.state.openCalander && 'active'}`}
                    onClick={() => {
                      this.setState({
                        openCalander: !this.state.openCalander,
                        openPickUpPopup: false,
                      });
                      this.props.manageBanner(true);
                      this.props.handleHeaderActive(false);
                    }}
                  >
                    <div className="icon">
                      <img src={calendarImg} alt="" />
                    </div>
                    <input
                      type="text"
                      placeholder="14 Feb 2023  | 12:00 AM"
                      id="pickup"
                      readOnly
                      value={
                        this.state.pickUpDate +
                        ` |  ${this.state.pickupTime} ${this.state.pickupPeriod}`
                      }
                    />
                  </div>

                  <div className="locationWrapperContent">
                    {/* promo code */}
                    <div className="promo-code">
                      {this.state.promoCode.length === 0 ? (
                        <div className="promo-code p-0">
                          <button id="promo-code-btn" onClick={(e) => this.togglePromoCodeModal(e)}>
                            <div className="icon" style={{ marginBottom: 0 }}>
                              <img src={promoImg} alt="" />
                            </div>
                            <span>{t('Shukran Id')}</span>
                          </button>
                        </div>
                      ) : (
                        <div className="promo-code p-0">
                          <div className="checked-icon">
                            <img src={CheckedRed} alt="" />
                          </div>
                          <button id="promo-code-btn" onClick={(e) => this.togglePromoCodeModal(e)}>
                            <div className="icon" style={{ marginBottom: 0 }}>
                              <img src={promoImg} alt="" />
                            </div>
                            <span>{this.state.promoCode}</span>
                          </button>
                          <span className="mr-0">({t('Shukran Id')})</span>
                          <button
                            className="removeBtn"
                            onClick={() => this.setState({ promoCode: '' })}
                          >
                            {t('Remove.1')}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ position: 'relative', display: 'inline-block' }}>
                {/* Show loader on top of the button */}
                {this.state.loading && (
                  <div className="loader-overlay">
                    <Oval
                      height={40}
                      width={40}
                      color="#fff" /* Adjust color for better visibility */
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                )}

                <button
                  type="button"
                  className="primary-button showCarBtn"
                  onClick={this.searchClickHandler}
                  disabled={this.state.loading} // Disable button when loading
                >
                  {this.state.loading ? 'Loading...' : t('Show Cars.1')}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          className="overlayPopUpLocation"
          style={{
            zIndex: this.state.openPickUpPopup ? '9' : '-2',
            width: this.state.openPickUpPopup ? '' : 'auto',
          }}
          ref={(div) => {
            this.divElement = div;
          }}
        >
          <div style={{ height: this.state.openPickUpPopup ? '5000px' : 'auto' }}></div>
        </div>

        {this.state.openPickUpPopup && (
          <div ref={this.popUpRef}>
            <PickUpPopup
              isDeliveryRequire={true}
              searchInputBoxValue={this.state.selectedValue?.Name || ''}
              clearSearchProps={this.clearSearchProps}
              onSelectValue={this.handleSelectedValue}
              searchClickHandler={this.searchClickHandler}
              handleChooseDelivery={this.handleChooseDelivery}
              searchQuery={this.state.searchQuery || ''}
              handlePopUpAirport={this.handlePopUpAirport}
              popUpScrollPosition={this.state.popUpScrollPosition}
            />
          </div>
        )}

        {/* promo code */}
        {this.state.size < 575 && (
          <div className="promo-code">
            {this.state.promoCode.length === 0 ? (
              <div className="promo-code p-0">
                <button id="promo-code-btn" onClick={(e) => this.togglePromoCodeModal(e)}>
                  <div className="icon" style={{ marginBottom: 0 }}>
                    <img src={promoImg} alt="" />
                  </div>
                  <span>{t('Shukran Id')}</span>
                </button>
              </div>
            ) : (
              <div className="promo-code p-0">
                <div className="checked-icon">
                  <img src={CheckedRed} alt="" />
                </div>
                <button id="promo-code-btn" onClick={(e) => this.togglePromoCodeModal(e)}>
                  <div className="icon" style={{ marginBottom: 0 }}>
                    <img src={promoImg} alt="" />
                  </div>
                  <span>{this.state.promoCode}</span>
                </button>
                <span className="mr-0">({t('Added Shukran Id')})</span>
                <button className="removeBtn" onClick={() => this.setState({ promoCode: '' })}>
                  {t('Remove.1')}
                </button>
              </div>
            )}
          </div>
        )}
        {openPromoCodeModal && (
          <AddPromocode
            promoCode={this.state.promoCode}
            handleClose={this.togglePromoCodeModal}
            handlePromoCode={this.handlePromoCode}
            isShukranId={true}
          />
        )}

        {this.state.openCalander && (
          <div ref={this.popUpRef}>
            <FlexiMontlyCalender
              searchClickHandler={this.searchClickHandler}
              pickUpDate={this.state.pickUpDate}
              pickUpTime={this.state.pickupTime}
              pickupPeriod={this.state.pickupPeriod}
              selectedValue={this.state.selectedValue}
              updatePickUpDate={this.updatePickUpDate}
              updatePickUpTime={this.updatePickUpTime}
              handlePeriodChange={this.handlePeriodChange}
              closeCalendar={this.closeCalendar}
            />
          </div>
        )}

        {/* Choose delivery to me */}
        {this.state.chooseDevliveryPopUp && (
          <div ref={this.popUpRef}>
            <ChooseDelivery
              errorRef={this.errorPopUpRef}
              onPickupBranchSelected={this.onPickupBranchSelected}
              chooseDeliveryValue={this.chooseDeliveryValue}
              handleChooseDelivery={this.handleChooseDelivery}
            />
          </div>
        )}

        {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
          <Dialog
            style={{ zIndex: 100001 }}
            open={this.state.errorMessage.length > 0}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
              {'Dollar'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={this.handleClose}
                variant="outlined"
                color="error"
                style={{
                  background: '#E31A37',
                  color: 'white',
                  justifyContent: 'center',
                  width: '100%',
                  margin: '0px 47px',
                }}
              >
                {t('OK.1')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(withRouter(FlexiMonthly));
