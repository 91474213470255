import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import docattachment from '../assets/images/document-attachments-icon.svg';

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  onFooterMenuLinkClick = (path) => {
    this.props.history.push(path);
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Terms & Conditions </title>
          <meta
            name="description"
            content="A quick look at our terms and conditions and rental policies. "
          ></meta>
          <meta property="og:title" content="Dollar Car Rental Oman | Terms & Conditions "></meta>
          <meta property="og:url" content="https://www.dollaroman.com/termsandconditions"></meta>
          <meta
            property="og:description"
            content="A quick look at our terms and conditions and rental policies. "
          ></meta>
        </Helmet>
        <ListingHeader></ListingHeader>
        <div className="mb-2 pt-5 minH100 bg-grey">
          <div className="terms-page">
            <div className="container">
              <div className="terms-title-wrap row">
                <div className="col-6">
                  <h2 className="border-botom">Terms and Conditions</h2>
                  {/* <p></p> */}
                </div>
                <div className="col-6">
                  <div className="terms-tab-wrap">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link active"
                          id="general-term-tab"
                          data-toggle="tab"
                          href="#general-term"
                          role="tab"
                          aria-controls="general-term"
                          aria-selected="true"
                        >
                          General Terms and Conditions
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="pre-paid-term-tab"
                          data-toggle="tab"
                          href="#pre-paid-term"
                          role="tab"
                          aria-controls="pre-paid-term"
                          aria-selected="false"
                        >
                          Pre-Paid Terms and Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="general-term"
                      role="tabpanel"
                      aria-labelledby="general-term-tab"
                    >
                      <div className="tab-title mt-0">
                        <img src={docattachment} width="20" className="mr-3"></img>Documentation
                        Required
                      </div>
                      <div className="tab-term-content">
                        <p>
                          All renters must be able to provide a valid driver’s license, passport
                          copy with visa page and authorized credit card under the main Driver Name.
                        </p>
                        <p>All drivers must carry a locally accepted driving license.</p>
                        <h5>Oman RESIDENTS</h5>
                        <p>
                          A valid Oman driving license is a must. Please note that driving without a
                          Valid Oman driving license when on residence or employment visa is a
                          violation of the LAW.
                        </p>
                        <h5>NON-RESIDENTS</h5>
                        <p>
                          Visitors are encouraged to have an International Driving License/Permit
                          prior from arrival to Oman. Customers from the following countries can
                          drive in Oman without an International Driving Permit (IDP); however, must
                          hold the passport and driver’s license from the same country:{' '}
                          <b>GCC Countries</b>: Saudi Arabia, Oman, Bahrain, Kuwait, Qatar{' '}
                          <b>European Countries</b>: Germany, Italy, Switzerland, Poland, Slovakia,
                          Finland, Spain, Netherlands, Greece, Sweden, Belgium, Ireland, Turkey,
                          Denmark, Portugal, Austria, France, United Kingdom, Norway, Romania,
                          Luxemburg, Latvia, Serbia, Lithuania, Bulgaria{' '}
                          <b>North American Countries</b>: United States of America, Quebec State in
                          Canada. <b>African Country</b>: South Africa.
                        </p>
                        <p>
                          International or National Driving license in any other language other than
                          Arabic and English should be translated into Arabic or English. The
                          translation should be attested by the Embassy.
                        </p>
                      </div>
                      <div className="tab-title ">GENERAL TERMS AND CONDITIONS</div>
                      <div className="tab-term-content">
                        <h5>Minimum Rental Period</h5>
                        <p>24 hours</p>
                        <h5>Currency</h5>
                        <p>Rial Omani (RO)</p>
                        <h5>Minimum Driver Age</h5>
                        <p>
                          22 years. In case of accident, driver below 25 years old will pay an
                          insurance excess of RO 262.50 as per the law.
                        </p>
                        <h5>Insurance Excess</h5>
                        <p>
                          Deductible amount charged where hirer is at fault or provided a police
                          report under hit and run case:
                        </p>
                        <p>
                          <strong>RO 131.25 & RO 157.50</strong> Depends on Car Category
                        </p>
                        <p>
                          <strong>Value Added Tax (VAT)</strong> Dollar Rent A Car reserves the
                          right to add VAT as confirmed and implemented by Sultanate Oman Government
                          with effect on April 2021. This is applicable for all bookings for pick-up
                          from 16 April 2021
                        </p>
                        <h5>Deposits</h5>
                        <p>
                          A pre-authorization is taken from the hirer’s credit card at the time of
                          rental. Pre-authorization amount is minimum RO 150.00 depending on the
                          vehicle type. We keep pre-authorization amount up to 21 days until we
                          receive late traffic fines updates from Oman traffic department.
                        </p>
                        <p>
                          An open credit card imprint of the hirer is required. If the driver does
                          not have a credit card in his/her name, Dollar Rent A Car reserves the
                          right to refuse the rental.
                        </p>
                        <h5>Method of Payment</h5>
                        <p>
                          We accept all major Credit Cards (MASTER, VISA and AMERICAN EXPRESS).
                          Credit card should be valid for a minimum of 3 months from the day of
                          rental and must be physically available at time of vehicle collection.
                        </p>
                        <h5>{t('Return Charges.1')}</h5>
                        <p>
                          <strong>1- Airport Return Charges</strong>
                        </p>
                        <p>
                          All vehicles off hired/returned in any of our airport counters will be
                          charged an airport drop off fees. If you wish to avoid this drop off fees,
                          rented vehicles can be returned to any non-airport branch locations
                          between 08:00H to 17:30H (Sunday to Thursday). Presently the drop off fees
                          is as follows: Salalah Airport - OMR 5.250, Muscat Airport OMR 5.250.
                        </p>
                        <p>
                          <strong>2- Non Airport Return</strong>
                        </p>
                        <p>
                          Drop-offs at any Non-Airport locations will be free of cost based on the
                          working hours as mentioned on Locations.
                        </p>
                        <p>
                          <strong>3- OMR Off hire charges/Return charges</strong>
                        </p>
                        <p>Muscat/Salalah: OMR 147; Abu Dhabi: OMR 183.750.</p>
                        {/* <h5>One Way Fees</h5>
                                                <p>If you arrange to pick the car up in one location and drop it off in different Emirate One Way Fee will apply as below.</p>
                                                <p>•	From Muscat & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210/- per one-way drop.</p>
                                                <p>•	From Abu Dhabi & Al Ain Locations to Muscat & Northern Emirates Locations will be Dhs 420/- per one-way drop.</p>
                                                <p>•	From Abu Dhabi to Al Ain will be Dhs 210/- per one-way drop.</p>
                                                <p>•	From Al Ain to Abu Dhabi will be Dhs 210/- per one-way drop.</p> */}
                        <h5>Super Damage Waiver (SDW)</h5>
                        <p>
                          {t('Reduces the excess insurance/liability amount to.1')}{' '}
                          <strong>{t('ZERO.1')}</strong>
                        </p>
                        <h5>Mileage Restriction</h5>
                        <p>
                          <strong>Standard Vehicles :</strong> Monthly: 4000km/month. RO 0.042 for
                          every extra km
                        </p>
                        <p>
                          <strong>2WD/4WD Vehicles :</strong> Monthly: 4000km/month. RO 0.063 for
                          every extra km
                        </p>

                        <h5>Super Collision Damage Waiver (SCDW)</h5>
                        <p>
                          Reduces the excess insurance/liability amount to <strong>ZERO.</strong>
                        </p>
                        {/* <h5>Mileage</h5>
                                                <p>Unlimited kilometers for <strong>DAILY</strong> and <strong>WEEKLY</strong> rentals.</p>
                                                <p><strong>Mini to Standard Vehicles:</strong> 5000km for MONTHLY rentals, OMR 0.35 for every extra km.</p>
                                                <p><strong>SUV Vehicles</strong> 3000km per month, OMR 0.35 for every extra km.</p> */}
                        <h5>Monthly Rentals</h5>
                        <p>
                          Monthly rentals are calculated on a 22 days rental period. Advance payment
                          is mandatory. Early car return will automatically cancel the monthly plan
                          and respective weekly or daily tariff with 25% discount will be applicable
                          for the rental period.{' '}
                        </p>
                        <p>
                          Agreements opened on DAILY and WEEKLY rentals cannot be extended to
                          MONTHLY contract.
                        </p>
                        <h5>Personal Accident Insurance</h5>
                        <p>
                          To add more security on your driving, we also offer Personal Accident
                          Insurance, this can purchase at an additional cost which covers the driver
                          and the passengers.
                        </p>
                        <h5>Driving License</h5>
                        <p>
                          All drivers must carry a locally accepted driving license. Hirers must
                          hold a valid International Driving License from the country on whose
                          passport the hirer is traveling. In the absence of an International
                          Driving License customers are advised to obtain a Temporary Driving Permit
                          upon arrival in the Sultanate before the commencement of rental.
                        </p>
                        <h5>Fuel Policy</h5>
                        <p>
                          The hirer should return the vehicle with the same fuel level as given by
                          the company. If not, the hirer will be charged the cost of fuel plus a
                          service charge.
                        </p>

                        <h5>Late Return</h5>
                        <p>
                          Only 1 hour grace period is allowed. Beyond 1 hour, full-day charges as
                          per the tariff card will be charged directly from the hirer.
                        </p>
                        <h5>Additional Driver</h5>
                        <p>
                          The hirer may put on an extra driver with additional cost of RO 3.15,
                          provided that he fulfills all necessary requirements.
                        </p>
                        <h5>Driving Across Border</h5>
                        <p>
                          Hirers must obtain permission to cross the border from any of the Dollar
                          Rent A Car office after the payment of additional Insurance and other
                          service charges.{' '}
                        </p>
                        <p>
                          Dollar Rent A Car Oman has the sole discretion to accept or cancel any
                          border crossing request{' '}
                        </p>
                        <h5>Value Added Tax (VAT)</h5>
                        <p>
                          Dollar Car Rental Oman reserves the right to add 5% VAT as confirmed and
                          implemented by Oman Government with effect on April 2021.
                        </p>
                        {/* <h5>Oman Insurance</h5> */}
                        {/* <p>Driving to Oman territory is allowed with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. <strong>Oman Insurance charges are as follows (these rates are excluding the 5% VAT):</strong></p>
                                                <table className="mt-4">
                                                    <tr>
                                                        <th width="30%">Car Type or Similar</th>
                                                        <th>1-3 days</th>
                                                        <th>7 days</th>
                                                        <th>7+ days</th>
                                                        <th>30 days</th>
                                                        <th>Extra KM Charged</th>
                                                    </tr>
                                                    <tr>
                                                        <td>▪ Nissan Sunny ▪ Nissan Micra ▪ Kia Picanto ▪ Chevrolet Spark ▪ Hyundai Elantra ▪ Toyota Yaris ▪ Honda Civic ▪ Toyota Corolla ▪ Nissan Altima ▪ Toyota Camry ▪ Mazda 6 ▪ Kia Optima ▪ Nissan Kicks ▪ Hyundai Creta.</td>
                                                        <td>350 Dhs/-<br></br> (with 600 km for 3 days).<br></br>( Each Day extra + 75 Dhs/- ).</td>
                                                        <td>600 Dhs/-<br></br> ( 250 Km per day ).</td>
                                                        <td>75 Dhs /-</td>
                                                        <td>1000 Dhs /-</td>
                                                        <td>1 OMR</td>
                                                    </tr>
                                                    <tr>
                                                        <td>▪ Toyota Previa ▪ Toyota Fortuner ▪ Mazda CX-9 ▪ Mazda CX-5 ▪ Nissan X-Trail.</td>
                                                        <td>450 Dhs/- <br></br>(with 600 km for 3 days). <br></br> (Each Day extra 100 Dhs/-).</td>
                                                        <td>750 Dhs/- <br></br>( 350 Km per day ).</td>
                                                        <td>100 Dhs/-</td>
                                                        <td>1500 Dhs/-</td>
                                                        <td>1 OMR</td>
                                                    </tr>
                                                </table> */}
                        {/* <p className="my-4">LIMITED MILEAGE provided when driving the car in Oman. Following vehicles are <strong><u>NOT</u></strong> allowed to be driven to Oman: Dodge Charger, BMW, VW Golf GTI, Mercedes C200, Lexus, Audi A6, Audi A8, Nissan Patrol, Dodge Challenger, and Jeep Wrangler. </p> */}
                        <h5>Off-Road Liability/Charges</h5>
                        <p>
                          Insurance does not cover off road usage of the vehicle and the customer
                          will be liable for damage caused and charges arising out of such use.
                        </p>
                        {/* <h5>Vehicle License Fee</h5>
                                                <p>A vehicle monitoring device fee also known as the Vehicle License Fee (VRF) will be charged as implemented/mandated by the Roads and Transport Authority of Muscat. <strong>Daily:</strong> OMR 5.25; <strong>Weekly:</strong> OMR 31.50; <strong>Monthly:</strong> OMR 78.75.</p>
                                                 */}
                        <h5>Traffic Violations</h5>
                        <p>
                          Drivers are expected to abide with the traffic rules and regulations. For
                          every traffic fine incurred will be charged to the hirer with additional
                          10% service charge.
                        </p>
                        <h5>Chauffer Service</h5>
                        <p>On request only (RO 26.25/day for 8hrs)</p>

                        {/* <h5>Oman Off hire charges/Return charges</h5>
                                                <p>Muscat/Sohar: OMR 1050.00 ; Salalah: OMR 1575.00.00.</p> */}
                        <h5>Value Added Assets</h5>
                        <p>
                          Child Seats are available on request only and are subject to availability.
                          Cost: RO 3.15/- per day
                        </p>
                        <p>
                          GPS are available on request only and are subject to availability. Cost:
                          RO 3.15/- per day
                        </p>
                        <h5>Breakdown Procedure</h5>
                        <p>
                          Please try to park the vehicle in safe and secure place. Try to know
                          exactly where you are. Contact the nearest Dollar Car Rental Oman office
                          or Toll Free 800-4710 (800 Dollar) and provide the details of breakdown
                          type. A replacement vehicle will be sent. A Service Fee will apply for
                          replacement of the spare tyre.{' '}
                        </p>
                        <h5>Delivery and Collection</h5>
                        <p>
                          Not to put you through inconvenience we can deliver and collect vehicles
                          right at your doorsteps for a mild charge per transaction in Muscat City
                          or Salalah City limit between 08:30am to 05:00pm.
                        </p>
                        <p>One way Service :-Domestic :</p>
                        <p>• One way drop off charges from Muscat to Salalah: RO 157.50/-</p>
                        <p>• One way drop off charges from Muscat to Sohar: RO 52.50/-</p>
                        <p>• One way drop off charges from Sohar to Muscat: RO 52.50/-</p>
                        <p>• One way drop off charges from Salalah to Muscat: RO 157.50/-</p>
                        <p>• One way drop off charges from Salalah to Sohar: RO 210/-</p>
                        <p>• One way drop off charges from Sohar to Salalah: RO 210/-</p>
                        <p>• One way drop off charges for Nizwa: RO 42/-</p>
                        <p>• Other places on request.</p>
                        <p>• One way Service : (Delivery into other country)</p>
                        <p>• Muscat/Salalah OMR one way drop off charges RO 147/-</p>
                        <p>• Salalah - OMR one way drop off charges RO 183.75/-</p>

                        <h5>OMR Insurance</h5>
                        <p>
                          Driving to OMR territory is allowed with prior purchase of an{' '}
                          <strong>
                            {' '}
                            OMR Insurance Certificate available at the rental desk. OMR Insurance
                            charges are as follows
                          </strong>
                        </p>
                        <p>
                          <strong>OMR Insurance (Crossing the Border)- valid for 7 days</strong>
                        </p>
                        <table className="mt-4">
                          <tr>
                            <th width="70%">
                              Nissan Sunny-Toyota Yaris-Mazda 3- Toyota Corolla-Mazda 6- Nissan
                              kicks
                            </th>
                            <th>36.75/-OMR </th>
                          </tr>
                          <tr>
                            <th width="70%">Hyundai Tucson and Kia Sportage</th>
                            <th>42.00/-OMR </th>
                          </tr>
                          <tr>
                            <th width="70%">Toyota Fortuner-Toyota Land Cruiser</th>
                            <th>47.25/-OMR </th>
                          </tr>
                          <tr>
                            <th width="70%">Extension</th>
                            <th>10.50/- OMR per day </th>
                          </tr>
                        </table>

                        <h5>Public Holidays</h5>
                        <p>
                          Dollar Car Rental Oman Downtown offices are closed on Public Holidays. All
                          Airport counters are open 24/7.
                        </p>
                        <h5>
                          Discount rates on the website and on counter given cannot be clubbed with
                          any Loyalty discounts or promotion running.
                        </h5>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pre-paid-term"
                      role="tabpanel"
                      aria-labelledby="pre-paid-term-tab"
                    >
                      <div className="tab-title mt-0">PRE-PAID GENERAL TERMS AND CONDITIONS</div>
                      <div className="tab-term-content">
                        <h5>General</h5>
                        <p>
                          This online reservation facility does not constitute a contract for the
                          supply of vehicle rental services. A contract will be entered into at the
                          time of rental and will be governed by the laws of the country of rental.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>
                          Dollar Car Rental Oman Prepaid rental booking showing a specified value
                          will be accepted as a means of payment of the reservation charges at
                          rental counters. The booking must be accompanied by a credit card both for
                          security and to cover the charges not included in the booking value, such
                          as fuel, extras, and excess in case of damage or loss of the rented
                          vehicle. A pre-authorisation will be taken on the credit card to cover
                          excess & other additional charges at the time of vehicle hire.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>
                          Standard Dollar Car Rental Oman Terms and Conditions apply. All rentals
                          may be subject to additional surcharges (e.g. for special equipment,
                          additional drivers, young drivers, optional insurances, fuel, toll,
                          Traffic fine etc.).
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>
                          All rentals are subject to an insurance excess, optional insurances are
                          available to excess.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Amendments:</h5>
                      </div>
                      <div className="tab-term-content">
                        <h5>(A) PAYMENT BY CREDIT CARD</h5>
                        <p>
                          Credit cards are accepted for online payment. Please note the Credit card
                          used online must be presented at time of pick up. Credit card on the name
                          of card holder must be available at pick up location. This is a mandatory
                          requirement. The credit card must have enough funds to cover the security
                          deposit.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>(B) PREPAID RATES ONLY</h5>
                        <p>
                          Dollar Car Rental Oman applies Cancellation and No Show / Lost Rental
                          charges on all prepaid rates booked from www.dollaroman.com.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>(C) Change of Reservation:</h5>
                        <p>
                          1. Any change to a booking made online or through a Dollar Car Rental Oman
                          Representative will be recalculated based on the availability and prices
                          at the time the change is made. This may be greater or lesser than the
                          price originally booked. This applies to changes made to:
                        </p>
                        <p>
                          a. Drop off location | b. Pick up date and time | c. Drop off time and
                          date | d. Vehicle group | e. All other additional products.
                        </p>
                        <p>
                          2. Any modification for changes made online would have an amendment fee of
                          OMR: 5.250.
                        </p>
                        <p>
                          3. The primary customer name and the pick-up location on a reservation
                          cannot be changed. If you wish to change the primary name or the pick-up
                          location, then you must cancel the reservation (see below “Cancellations”)
                          and make a new reservation.
                        </p>
                        <p>
                          4. You will be asked to enter a valid credit card number at the end of a
                          change to a prepaid reservation. This MUST be the same credit card that
                          was used for the original reservation. If you wish to change the credit
                          card, then the original reservation must be canceled (see below
                          “Cancellations”) and a new reservation to be made.
                        </p>
                        <p>
                          5. Administration charges will not apply to amended bookings, unless the
                          amendment significantly changes the original booking, in which case it
                          will be subject to the cancellation fee detailed below. All requests to
                          amend a booking will be subject to normal availability of the requested
                          vehicle. Prices may change between the date of the original booking and
                          the amended booking, in which case the later prices will apply.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Cancellation:</h5>
                        <p>
                          1. Customers may cancel a pre-paid booking with an administration fee of
                          OMR : 5.000 if Dollar Rent A Car OMAN is advised 48 hours prior to pick up
                          date.
                        </p>
                        <p>
                          2. For cancellations of rentals with less than 48 hours’ notice, Dollar
                          Rent A Car will charge one day rental of the reservation and an
                          Administration fee of OMR : 5.000.
                        </p>
                        <p>
                          3. Refunds will be made to the credit card that the original booking was
                          made on within 21 working days
                        </p>
                        <p>
                          4. Any booking that is cancelled on the same day of pick-up due to invalid
                          documents, etc., will be considered as cancelled less than 48 hours'
                          notice. Hence, Dollar Rent A Car will charge one day rental of the
                          reservation and an Administration fee of OMR : 5.000
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Early Return/ No Shows:</h5>
                        <p>
                          1. There will be no refund for unused car rental days on a prepaid rental,
                          once the rental has started.
                        </p>
                        <p>
                          2. There will be no refund if the customer fails to show up for a rental
                          that has not been cancelled prior to the pickup date.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Early Termination:</h5>
                        <p>
                          1. Incase of Early Termination, a Penalty Fee equivalent to a 1 Month
                          Rental will be applied.
                        </p>
                        <p>
                          2. There will be no refund for unused car rental days on a Prepaid/Pay on
                          Arrival rentals, once the rental has started.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Extensions:</h5>
                        <p>
                          If a rental needs to be extended once the car has been picked up, local
                          rental rates on 25% discount from the normal tariff will apply for the
                          additional days and the additional charges will be settled by customer’s
                          credit card.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Cash:</h5>
                        <p>
                          Cash or credit card as a form of payment is only accepted at the end of
                          rental. At the beginning of rental a credit card is required for the
                          deposit.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Late Arrival Policy:</h5>
                        <p>
                          If you make a reservation specifying a pick-up location and you do not
                          arrive at the specified pick-up location for the rental within 4 hours of
                          the specified pick-up time, (or if the location closes before then, by the
                          locations closing time), the reservation will be treated as a No Show and
                          charged accordingly.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Extras:</h5>
                        <p>
                          Your extras, i.e. GPS, Baby seat etc. are payable at the rental location &
                          is subject to availability.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>
                          Discount rates on the website and on counter given cannot be clubbed with
                          any Loyalty discounts or promotion running
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListingFooter></ListingFooter>
      </>
    );
  }
}

export default Terms;
