import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.handleSelect(0);
  }

  handleSelect = (index) => {
    this.setState({ avaliableindex: index });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Frequently Asked Questions </title>
          <meta
            name="description"
            content="Fuel Policy, Booking Modification, Payment Terms, Additional Driver, Offers and Deals - All your doubts covered in our FAQs"
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Frequently Asked Questions "
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/faq"></meta>
          <meta
            property="og:description"
            content="Fuel Policy, Booking Modification, Payment Terms, Additional Driver, Offers and Deals - All your doubts covered in our FAQs"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section  grey-bg py-4">
            <div className="container-fluid">
              <div className="">
                <div className="pickup-location-box">
                  <h1 class="border-botom">{t('FAQ on Vehicle Rentals.1')}</h1>
                  <h2 class="border-botom">{t('Frequently Asked Questions at Dollar.1')}</h2>
                  <p class="mb-4">{t('We have got you covered.1')}</p>
                </div>

                <div className="faq-wrapper">
                  {/* <div className="box-title-icon-border d-flex align-items-center mb-4">
                                <span className="box-title-icon-rounded">
                                    <img src={faq} />
                                </span>
                                <h4 className="text-16 text-red">{t('FAQ.1')}</h4>
                            </div> */}
                  <div className="">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseOne"
                              onClick={() => {
                                this.handleSelect(1);
                              }}
                            >
                              {t('What are the basic rental requirements?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse in">
                          <div className="panel-body text-muted">
                            <span>
                              {' '}
                              {t(
                                '1. Minimum Age Limit Dollar Car Rental Oman requires one to be of 22 years old and above for all rental plans within the country',
                              )}
                              <br />
                              {t('2. Valid Documents Required.1')}
                              <br />
                              {t('For Local Residents.1')}
                              <br />
                              {t('• Oman Resident Card (Front & Back).1')}
                              <br />
                              {t('• Oman Driving License Copy (Front & Back).1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visa Copy.1')}
                              <br />
                              {t('• International Driving License Copy.1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visiting Visa Copy.1')}
                              <br />
                              {t('For Tourists (GCC Countries).1')}
                              <br />
                              {t('• Valid GCC Driving License Copy.1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visiting Visa Copy.1')}
                              <br />
                              {t(
                                'International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.1',
                              )}
                              <br />
                              {t(
                                '3. Security Deposits Valid Credit Card with minimum 3 months validity.1',
                              )}
                              <br />
                              {t('4. Vehicle Inspection & Add-ons.1')}
                              <br />
                              {t(
                                'Once you’ve ensured the vehicle quality is up to your satisfaction, you may proceed to sign the rental contract and complete the payment process. You may also add on amenities like baby/toddler rear seats, GPS trackers, Mobile WIFI, etc. at this stage.1',
                              )}{' '}
                              <br />
                              {t('• Valid GCC Driving License Copy.1')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed  font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                              onClick={() => {
                                this.handleSelect(2);
                              }}
                            >
                              {t('Do you offer a vehicle delivery & pick up service?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'For your added convenience, we can deliver and collect vehicles right at your doorstep for a nominal fee. This service is subject to availability.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree"
                              onClick={() => {
                                this.handleSelect(3);
                              }}
                            >
                              {t('Can I reserve a specific make/model car?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental Oman does not guarantee a specific make/model/color of the vehicle against any reservation. Should the specific car group/make/model not be available at the point of pick-up, Dollar reserves the right to offer an alternate make/model within the same group',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFour"
                              onClick={() => {
                                this.handleSelect(4);
                              }}
                            >
                              {t('What happens when I return the car late?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Additional charges are applicable based on the signed rental contract. You may nevertheless, check with our team for an authorized extension.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFive"
                              onClick={() => {
                                this.handleSelect(5);
                              }}
                            >
                              {t('How about the Fuel Policy at Dollar Car Rental?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'One must return the vehicle with the same fuel level as given by the company. If not, the hirer will be charged the cost of fuel plus a service charge.1',
                              )}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSix"
                              onClick={() => {
                                this.handleSelect(6);
                              }}
                            >
                              {t(
                                'What should I do when my vehicle needs servicing and maintenance?.1',
                              )}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSix" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Please inform Dollar Team or use the ‘Book A Service’ feature from our website to arrange an appointment with one of our service representatives. We will book your vehicle in for service and arrange a replacement vehicle.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeven"
                              onClick={() => {
                                this.handleSelect(7);
                              }}
                            >
                              {t('What are my options for mode of Payment?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeven" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental Oman accepts all major Credit Cards (MASTER, VISA, JCB, DINERS and AMERICAN EXPRESS). Credit card must be valid for a minimum of 3 months from the day of rental and must be physically available at the time of vehicle collection',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEight"
                              onClick={() => {
                                this.handleSelect(8);
                              }}
                            >
                              {t('Can I rent a car without a credit card?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEight" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Unfortunately no. The security deposit gets collected from credit cards using a pre-authorization block. This is the safest and most recommended way for both parties, as the collected amount is automatically released by the bank after 21 working days.1',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseNine"
                              onClick={() => {
                                this.handleSelect(9);
                              }}
                            >
                              What are the parking rules to be followed in Oman?
                            </a>
                          </h4>
                        </div>
                        <div id="collapseNine" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              Parking across Oman is easily available. Paid Parking along roadsides
                              are strictly followed, with parking meters under the same.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTen"
                              onClick={() => {
                                this.handleSelect(10);
                              }}
                            >
                              {t('What about Traffic violations?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              Most roads in Oman are radar-controlled and thus it is in the driver’s
                              interest to adhere to all speed limits & road signs. Any Traffic
                              Violation is a direct offense of the driver. All fines related to your
                              dollar rental car will be processed by our team while subsequent
                              notifications and costs are invoiced to the customer. For every
                              traffic fine incurred, you will be charged 10% extra to cover the
                              administration charges.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEleven"
                              onClick={() => {
                                this.handleSelect(11);
                              }}
                            >
                              {t('What do we do in the case of breakdown/accidents?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEleven" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Dollar offers you 24x7 breakdown and roadside assistance. And in the unforeseen circumstances of your vehicle being involved in an accident, please note that the vehicle should not be moved from the point of the accident or damage, except in the case where the damage is minor and the vehicle is causing an obstruction to the traffic. You will then call the police and remain with the vehicle in order to obtain a police report to be submitted to Dollar, so that an insurance claim can be made. Kindly notify our 24x7 support zone, 800 Dollar and they will facilitate any replacement vehicle that may be required.1',
                              )}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwelve"
                              onClick={() => {
                                this.handleSelect(12);
                              }}
                            >
                              {t('What should I do if my rental vehicle is stolen?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwelve" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Report the theft to the police immediately, obtain a copy of the police report and then notify Dollar Car Rental at the earliest.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThirteen"
                              onClick={() => {
                                this.handleSelect(13);
                              }}
                            >
                              {t('Can I add additional drivers for the same car rental?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThirteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Yes, you may add an extra driver, for a minimal charge, provided he/she fills all the relevant details.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFourteen"
                              onClick={() => {
                                this.handleSelect(14);
                              }}
                            >
                              {t('What is the policy on off-road driving?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFourteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Off-road use is a violation of the rental agreement and is not allowed.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFifteen"
                              onClick={() => {
                                this.handleSelect(15);
                              }}
                            >
                              {t('What is your Policy on driving to Oman?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFifteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Our cars may be driven into Oman with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. Charges vary based on the vehicles, time and period. However, certain vehicle groups may not be allowed to travel into Oman territory. Driving to Bahrain, Saudi Arabia, Qatar, Yemen, Kuwait, etc. is strictly forbidden.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSixteen"
                              onClick={() => {
                                this.handleSelect(16);
                              }}
                            >
                              {t('What are the expected additional charges?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSixteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              Additional charges related to the rental include: any driving/traﬃc
                              fine or violation charges imposed by ROP/Municipality, Re-fueling
                              Service Charge, additional driver charge, baby seats and GPS, Airport
                              Return Charges, and for any Oman registered rented or leased vehicle.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeventeen"
                              onClick={() => {
                                this.handleSelect(17);
                              }}
                            >
                              {t('How can I avail the best offers at Dollar Car Rental OMN?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeventeen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental guarantees the best prices, assured seasonal offers and surprise deals, 365 days a year! You can follow us on social media to stay tuned or ask our representative to update you on the ongoing promotions.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(FAQ), FAQ);
