import { bookingInstance } from '../axios/axiosInstance';

export function getUserInfo() {
  if (localStorage.getItem('userinfo') !== null) {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'));
    return userinfo;
  } else {
    return null;
  }
}

export function getCustomerId() {
  const userInfo = getUserInfo();
  if (userInfo == null) {
    return '00000000-0000-0000-0000-000000000000';
  } else {
    return userInfo.CustomerID;
  }
}

export function getLanguage() {
  const language = localStorage.getItem('i18nextLng');
  let lngParam = language == 'OMR' ? 'ar' : 'en';
  return lngParam;
}

export function getCancellationInformation() {
  if (localStorage.getItem('cancelInformation') !== null) {
    const cancellationInfo = JSON.parse(localStorage.getItem('cancelInformation'));
    return cancellationInfo;
  } else {
    return null;
  }
}

export function getClearCancellationInformation() {
  localStorage.setItem('cancelInformation', null);
}

export function getCurrency() {
  const currency = localStorage.getItem('currency');

  if (currency !== 'undefined' && currency) return currency;

  const code = process.env.REACT_APP_COUNTRY_CODE;
  bookingInstance
    .get(`currency?countryCode=${code}`)
    .then((response) => {
      localStorage.setItem('currency', response.data.result.data);
      return response.data.result.data;
    })
    .catch((error) => {
      return null;
    });
}
