import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import image from '../assets/images/DollarExpress1.png';
import keyIn from '../assets/images/key-50.png';
import hassleFreeOnboard from '../assets/images/onboarding-50.png';
import quickReservation from '../assets/images/reservation-50.png';
import counterService from '../assets/images/service-50.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ContactUsBox from './ContactUsBox';
class DollarXpress extends Component {
  componentDidMount = () => {
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Choose DollarXpress for a Quick and Hassle-Free Car Rental in Oman</title>
          <meta
            name="description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service. "
          ></meta>
          <meta
            property="og:title"
            content="Choose DollarXpress for a Quick and Hassle-Free Car Rental in Oman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/dollar-xpress"></meta>
          <meta
            property="og:description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service. "
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 bg-grey">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">DollarXpress</h2>
                            <p>Skip the queues and get on the road faster!</p>
                            <p className="text_p mt-4">
                              The next time you find yourself at one of Dollar Oman counters, skip
                              the queues and get on the road faster with the DollarXpress services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <div className="top-margin-fifty text-center">
                        <div className="pr-lg-5 px-4">
                          <img src={image} alt="Dollar Xpress" className="img-fluid rounded-xl" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <h5 className="font-weight-bold">DollarXpress Services</h5>
                      <p className="text_p mt-3">
                        {' '}
                        Contact us to know more or better, visit any of our neighborhood locations
                        to experience one today.
                      </p>
                    </div>
                    <div className="express-box-wrap express-row div-text-center-align">
                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={counterService} alt="Priority Counter Service" />
                          </div>
                          <div className="categories-box-content">
                            <h6>PRIORITY COUNTER SERVICE</h6>
                            <p>Skip the queues and get on the road faster.</p>
                          </div>
                        </div>
                      </div>
                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={quickReservation} alt="Quick Reservation" />
                          </div>
                          <div className="categories-box-content">
                            <h6>QUICKER RESERVATIONS</h6>
                            <p>Enjoy speedy reservations, pick-up and returns.</p>
                          </div>
                        </div>
                      </div>
                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={hassleFreeOnboard} alt="Hassle Free Onboard" />
                          </div>
                          <div className="categories-box-content">
                            <h6>HASSLE-FREE ONBOARDING</h6>
                            <p>One-time verifications for all future rental activities.</p>
                          </div>
                        </div>
                      </div>

                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={keyIn} alt="Key In and Go" />
                          </div>
                          <div className="categories-box-content">
                            <h6>KEY IN &amp; GO</h6>
                            <p>Best car reserved for you or choose your own.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ContactUsBox
            subHeading={t(
              'Visit any of our neighborhood locations to experience Dollar services today.1',
            )}
            heading={t(
              'This service is currently available only in Muscat. Contact us to know more.1',
            )}
          ></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(DollarXpress);
