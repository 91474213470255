import { GoogleApiWrapper } from 'google-maps-react';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ViewExtrasDetails from '../ListingPage/ViewExtrasDetails';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';
import email from '../assets/images/email.svg';
import phone from '../assets/images/phone-alt.svg';
import time from '../assets/images/time.svg';
import { branchInstance } from '../axios/axiosInstance';

class LocationTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBranchDescription: false,
      BranchDescription: '',
      isBooking: false,
      selectedPickupLocation: {
        _id: null,
        Name: null,
      },
      picupDateTime: null,
      returnDate: null,
    };
  }

  onInfoClick = (locationSelected) => {
    this.setState({
      showBranchDescription: true,
      BranchDescription: locationSelected.Description,
    });
  };

  toogleDescriptionPopUp = (showHide) => {
    this.setState({
      showBranchDescription: showHide,
    });
  };

  onBookNowClick = (locationSelected) => {
    var isDeliver = this.state.isDeliverToMe || false;
    branchInstance
      .get(`/hrsToGetReady?branchId=${locationSelected?._id}&isDeliverToMe=${isDeliver}`)
      .then((response) => {
        const timeReady = response.data.result;
        let pickupDate = moment.tz(timeReady.PickupOn, 'Asia/Muscat').format('DD MMM YYYY, h:mm A');
        let returnDate = moment.tz(timeReady.ReturnOn, 'Asia/Muscat').format('DD MMM YYYY, h:mm A');

        var BookingInfo = [
          {
            CheckOutLocationId: locationSelected?._id,
            CheckInLocationId: locationSelected?._id,
            PickUpDate: pickupDate,
            ReturnDate: returnDate,
            pickupLocationName: locationSelected.Name,
            dropOffLocationName: locationSelected.Name,
            pickupLocationAddress: locationSelected.Address,
            dropOffLocationAddress: locationSelected.Address,
            pickupLocationMessage: locationSelected.Message,
            dropOffLocationMessage: locationSelected.Message,
            pickupLocationBranchDescription: locationSelected.Description,
            dropOffLocationBranchDescription: locationSelected.Description,
            promocode: '',
          },
        ];

        localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));

        //this.props.history.push("/booking");
        this.state.selectedPickupLocation = locationSelected;
        this.setState({
          isBooking: true,
          picupDateTime: pickupDate,
          returnDate: returnDate,
        });
      })
      .catch((error) => {});
  };

  toogleVehicleType = (val) => {
    this.setState({
      isBooking: val,
    });
  };

  handleBanner = () => {};
  handleHeaderActive = () => {};

  render() {
    const { t } = this.props;
    return (
      <div className="box-shadow-white-box location-info-box">
        <div className="location-info-box-left">
          <h5 className="font-bold">
            {this.props.loc.Name}{' '}
            <a className="text-red" onClick={() => this.onInfoClick(this.props.loc)}>
              {t('More Info.1')} <i className="fa fa-angle-right"></i>
            </a>
          </h5>
          <span className="my-fonta">{this.props.loc.Address}</span>
          <span className="my-fonta">{this.props.loc.Message}</span>
          <ul>
            <li>
              <a href={'tel:' + this.props.loc.ContactNo}>
                <img src={phone} alt="Phone" className="img-fluid phonea" />
                <span className="img_text ml-2">{this.props.loc.ContactNo}</span>
              </a>
            </li>
            <li>
              <a href={'mailTo:' + this.props.loc.EmailID}>
                <img src={email} alt="email" className="img-fluid phone" />
                <span className="img_text ml-2">{this.props.loc.EmailID}</span>
              </a>
            </li>
            <li>
              <a className="d-flex  align-items-start">
                <img src={time} alt="girl" className="img-fluid phoneaa" />
                {/* <span className="img_text ml-2">Sun - Sat :10:00,21:00</span> */}
                <span className="img_text ml-2">
                  {' '}
                  {this.props.loc.BranchOfficeTiming != null
                    ? this.props.loc.BranchOfficeTiming.BranchTimings != null
                      ? this.props.loc.BranchOfficeTiming.BranchTimings.map((timing) => {
                          return (
                            <>
                              {timing != null ? (
                                <>
                                  {timing.DayString} :{' '}
                                  {timing.Shifts.map((shift) => {
                                    return <>{shift}</>;
                                  })}
                                </>
                              ) : null}
                              <br />
                            </>
                          );
                        })
                      : null
                    : null}
                </span>
              </a>
            </li>
            <li>
              <div className="location-info-box-right mt-3 text-right">
                <button
                  type="submit"
                  onClick={() => this.onBookNowClick(this.props.loc)}
                  className="rounded-bttn text-center"
                >
                  {t('BOOK NOW.1')}
                </button>
              </div>
            </li>
          </ul>
        </div>

        {this.state.showBranchDescription ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showBranchDescription}
                onHide={() => this.toogleDescriptionPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewExtrasDetails descriptionToShow={this.state.BranchDescription} />
              </Modal>
            </div>
          </div>
        ) : null}

        {this.state.isBooking ? (
          <div
            className="modal-dialog modal-xl modal-dialog-centered border-transparent-bg"
            style={{ paddingRight: 0 }}
          >
            <Modal
              show={this.state.isBooking}
              onHide={() => this.toogleVehicleType(false)}
              dialogClassName="modal-dialog modal-xl border-transparent-bg locationsPage"
            >
              <section className="sort-c1 modal-c">
                <div className="inner-wrapper" style={{ background: 'white' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="tab-content " id="nav-tabContent">
                          <StartBooking
                            manageBanner={this.handleBanner}
                            handleHeaderActive={this.handleHeaderActive}
                            pickupLocation={this.state.selectedPickupLocation}
                            dropLocation={this.state.selectedPickupLocation}
                            PickUpDate={this.state.picupDateTime}
                            ReturnDate={this.state.returnDate}
                            page={'L'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal>
          </div>
        ) : null}
      </div>
    );
  }
}

export default hoistStatics(
  withTranslation()(
    GoogleApiWrapper({
      apiKey: 'AIzaSyD5B6KKLVWThFRg2oXcEYoFHMyRFoLexzo',
    })(withRouter(LocationTile)),
  ),
  LocationTile,
);
