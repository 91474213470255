import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import careers from '../assets/images/careers.png';
import { contentInstance, userInstance } from '../axios/axiosInstance';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
class JobPosting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      loading: false,
      name: '',
      email: '',
      jobtitle: '',
      remark: '',
      resumeUrl: '',
      fileName: '',
    };
    this.fileInputRef = React.createRef();
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  validate = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: '',
    });

    let valid = true;
    if (this.state.name == undefined || this.state.name == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The name field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل الاسم',
        });
      }

      return false;
    }
    if (this.state.email == undefined || this.state.email === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The email is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }

      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }
      return false;
    }

    if (this.state.jobtitle == undefined || this.state.jobtitle === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The jobtitle field is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب مجال jobtitle',
        });
      }

      return false;
    }
    if (this.state.remark == undefined || this.state.remark === '') {
      if (lngParam == 'en') {
        this.setState({ errorMessage: 'The remark field is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل الملاحظات',
        });
      }

      return false;
    }

    return valid;
  };
  onSubmitClick = () => {
    if (this.validate()) {
      var data = {
        personalInformation: {
          fullName: this.state.name,
          contactNo: '938723594283',
          email: this.state.email,
        },
        visaType: 0,
        totalExperience: 3,
        attachment: {
          attachmentDataBase64: null,
        },
        jobPostingID: 'fe0220a5-7049-4fb4-96be-19f0d5a1d4ad',
        jobPostingTitle: this.state.jobtitle,
        remark: this.state.remark,
        resumeUrl: this.state.resumeUrl,
      };

      this.setState({
        loading: true,
      });
      contentInstance
        .post('jobs/ui')
        .then(({ data }) => {
          if (data.result.IsValid == true) {
            this.setState({
              loading: false,
            });
            window.location = '/thankyou';
          } else {
            if (data.result.Message != null) {
              this.setState({
                errorMessage: data.result.Message,
              });
            } else {
              this.setState({
                errorMessage: data.result.Message,
              });
            }
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {});
    }
  };

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ fileName: file.name });
    const formData = new FormData();
    formData.append('file', file);
    if (file) {
      userInstance
        .post('ui/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => {
          this.setState({
            resumeUrl: data.result,
          });
        });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers at Dollar Car Rental Oman</title>
          <meta
            name="description"
            content="Our work culture amongst as a team remains the same as towards our clients - one that of ensuring meaningful experiences, authenticity and joyful relationships. Join our dynamic team now."
          ></meta>
          <meta property="og:title" content="Careers at Dollar Car Rental Oman"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/jobposting"></meta>
          <meta
            property="og:description"
            content="Our work culture amongst as a team remains the same as towards our clients - one that of ensuring meaningful experiences, authenticity and joyful relationships. Join our dynamic team now."
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg pt-3 pb-2">
            <div className="container-fluid">
              <div className="box-shadow-white-box" id="About">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <h2 className="border-botom">Careers at Dollar Car Rental Oman</h2>
                    <p>After all, no road is long with the right company.</p>
                    {/* <span className="sm_text">Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed</span> */}
                    {/* <h3 className="mt-3">{t('Apply Now')}</h3> */}
                    {/* <ul>
                                        <li>
                                            <div className="sm_text">
                                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                </div>
                                        </li>
                                        <br />
                                        <li>
                                            <div className="sm_text">
                                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                </div>
                                        </li>
                                        <br />
                                        <li>
                                            <div className="sm_text">
                                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                </div>
                                        </li>
                                        <br />
                                        <li>
                                            <div className="sm_text">
                                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                </div>
                                        </li>
                                        <br />
                                        <li>
                                            <div className="sm_text">
                                                Lorem Ipsum is that it has a more-or-less normal distribution of
                                </div>
                                        </li>
                                    </ul>
                                    <h5 className="font-weight-bold mt-4">Lorem Ipsum is that it has a more-or-less normal</h5> */}
                    <p className="text_p mt-4">
                      {' '}
                      Dollar Car Rental is one of the leading global car rental companies known for
                      providing an award winning customer experience.
                    </p>
                    <p className="text_p mt-4">
                      Our work culture amongst as a team remains the same as towards our clients -
                      one that of ensuring meaningful experiences, authenticity and joyful
                      relationships. We are an equal opportunity employer and promote innovation,
                      creativity, talent and collective growth.
                    </p>
                    <p className="text_p mt-4">
                      Our job listings are updated on this page frequently. Share in your profiles
                      against your prospective role and rest assured, we’ll take it from there.
                    </p>
                    <p className="text_p mt-4">Thank you for your consideration.</p>

                    {/* <h5 className="font-weight-bold mt-4">{t('Terms & Conditions.1')}</h5> */}
                    {/* <p className="text_p mt-4">Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now Lorem Ipsum is that it has Lorem Ipsum is that it has a more-or-</p> */}
                    <img
                      src={careers}
                      alt="Dollar Job Posting"
                      className="img-fluid rounded-xl mt-4"
                    />
                  </div>
                  <div className="col-lg-5" id="book">
                    <div className="p-3 bg_custom">
                      <h5 className="font-weight-bold pt-3">{t('Job Request.1')}</h5>
                      <br></br>
                      <div className="mt-0">
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text mb-2">
                                  <h6 className="font-weight-bold mb-0">{t('Name.1')}</h6>
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  placeholder={t('Name.1')}
                                  onChange={this.onChange}
                                  value={this.state.name}
                                />
                              </div>
                            </div>
                            <div className="col-12 mt-4">
                              <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text mb-2">
                                  <h6 className="font-weight-bold mb-0">{t('Email ID.1')}</h6>
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder={t('Email ID.1')}
                                  onChange={this.onChange}
                                  value={this.state.email}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="row">
                            <div className="col-12">
                              <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text mb-2">
                                  <h6 className="font-weight-bold mb-0">{t('Job Title.1')}</h6>
                                </label>
                                <input
                                  type="text"
                                  name="jobtitle"
                                  placeholder={t('Job Title.1')}
                                  onChange={this.onChange}
                                  value={this.state.jobtitle}
                                />
                              </div>
                            </div>
                            <div className="col-12 mt-4">
                              <div className="custom-input-type-wrap" style={{ width: '80%' }}>
                                <label htmlFor="exampleInputEmail1" className="book-text mb-2">
                                  <h6 className="font-weight-bold mb-0">{t('Remark.1')}</h6>
                                </label>
                                <input
                                  type="text"
                                  name="remark"
                                  placeholder={t('Remark.1')}
                                  onChange={this.onChange}
                                  value={this.state.remark}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="row">
                            <div className="col-12">
                              <a
                                href="#"
                                className="rounded-bttn  color-red mb-3"
                                title="Book &amp; Pay Online"
                                onClick={this.handleButtonClick}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  className="mr-2"
                                  viewBox="0 0 20.881 22.273"
                                >
                                  <defs></defs>
                                  <g transform="translate(4.872)">
                                    <path
                                      className="a"
                                      d="M138.962,5.806,134.09.238a.694.694,0,0,0-1.047,0l-4.872,5.568a.7.7,0,0,0,.523,1.154h2.784v9.048a.7.7,0,0,0,.7.7h2.784a.7.7,0,0,0,.7-.7V6.96h2.784a.7.7,0,0,0,.523-1.154Z"
                                      transform="translate(-127.998)"
                                    ></path>
                                  </g>
                                  <g transform="translate(0 15.312)">
                                    <path
                                      className="a"
                                      d="M34.1,352v4.176H18.784V352H16v5.568a1.392,1.392,0,0,0,1.392,1.392h18.1a1.391,1.391,0,0,0,1.392-1.392V352Z"
                                      transform="translate(-16 -352)"
                                    ></path>
                                  </g>
                                </svg>
                                {t('Upload Resume.1')}
                              </a>

                              <input
                                type="file"
                                ref={this.fileInputRef}
                                style={{ display: 'none' }}
                                onChange={this.handleFileChange}
                              />
                              <p>{this.state.fileName}</p>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-12">
                            {this.state.loading == false ? (
                              <button
                                className="rounded-bttn rounded-bttn-transparent w-40 text-center mt-3 mb-3"
                                onClick={this.onSubmitClick}
                              >
                                {' '}
                                {t('Submit.1')}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(withRouter(JobPosting)));
