import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader></ListingHeader>
        <div className="my-5">
          <div className="terms-page">
            <div className="container">
              <div className="terms-title-wrap row">
                <div className="col-6">
                  <h2 className="border-botom">Privacy Policy</h2>
                  {/* <p>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet.</p> */}
                </div>

                <div className="col-12">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pre-paid-term"
                      role="tabpanel"
                      aria-labelledby="pre-paid-term-tab"
                    >
                      {/* <div className="tab-title">
                                                PRE-PAID GENERAL TERMS AND CONDITIONS
                                            </div> */}
                      <div className="tab-term-content">
                        <h5>What information do we collect?</h5>
                        <p>
                          This online reservation facility does not constitute a contract for the
                          supply of vehicle rental services. A contract will be entered into at the
                          time of rental and will be governed by the laws of the country of rental.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <p>
                          When registering or contacting us through our site, as appropriate, you
                          may be asked to enter your name, e-mail address or phone number.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>What do we use your information for? </h5>
                        <p>
                          Any of the information we collect from you may be used in one of the
                          following ways:
                        </p>
                        <p>
                          - To improve customer service (your information helps us to effectively
                          respond to your customer service requests and support needs).
                        </p>
                        <p>
                          - To send periodic emails, the email address you provide may be used to
                          send you information & promotions , respond to inquiries, and/or other
                          requests or questions.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>How do we protect your information?</h5>
                        <p>
                          We implement a variety of security measures to maintain the safety of your
                          personal information when you enter, submit, or access your personal
                          information.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Cookies and Online Advertising Choices? </h5>
                        <p>
                          Many browsers are set to accept cookies by default. You may be able to set
                          your browser to disable cookies, or alert you when cookies are being sent.
                          Many mobile devices allow you to disable the ability for geo-location
                          information to be collected from your mobile device. The help function on
                          most browsers and mobile devices contains instructions on how to set your
                          browser to notify you before accepting cookies, disable cookies, or
                          disable the collection of geo-location data.
                        </p>
                        <div className="tab-term-content">
                          <p>
                            You need to set each browser, on each device you use to view websites.
                            If you disable cookies or refuse to accept a request to place a cookie,
                            it is possible that some parts of Our websites or other websites will
                            not function properly, and the advertising you receive when you visit
                            Our websites or other websites may not be tailored to your interests. We
                            may not respond to a web browser’s “do not track” signal, or other
                            mechanism that indicates a request to disable online tracking of
                            individuals who use or visit Our websites.
                          </p>
                        </div>
                        <p>
                          We adhere to the Self-Regulatory Principles for Online Behavioral
                          Advertising. To "opt out" of advertising cookies placed by the Network
                          Advertising Initiative's ("NAI") online behavioral advertising member
                          networks, and thereby limit the tracking of your online activity and the
                          targeted advertising you receive, please click here:
                          http://optout.networkadvertising.org
                        </p>
                        <p>
                          To opt out of advertising cookies for Your Online Choices if you are based
                          in the EU, please click here: http://www.youronlinechoices.eu
                        </p>

                        <p>
                          Our designated service providers assist Us with advertisement display
                          functions and related analytics for Our websites. To view information or
                          opt out of Google Analytics for Display Advertising, please click here:
                          https://policies.google.com/technologies. Note that even if you exercise
                          choice for targeted advertising, you will still receive general online
                          advertising. Information regarding you and any interactions with Us may be
                          collected and utilized to provide you with targeted advertising on the
                          web.
                        </p>
                        <p>
                          With regard to mobile apps, any app’s collection and use of Personal Data
                          that is materially different from what’s described in this Policy will be
                          supplemented by an individual Privacy Policy that will require acceptance
                          for that specific app.
                        </p>
                        <p>
                          In addition to the options provided by your mobile operating systems, you
                          may opt out of the collection of information on mobile apps as follows:
                        </p>
                        <p>
                          <strong>Uninstalling the app.</strong> You can stop all collection of
                          information by the app by uninstalling the app. You may use the standard
                          uninstall processes as may be available as part of your mobile device or
                          via the Mobile Application marketplace or network from which you
                          downloaded the app.
                        </p>
                        <p>
                          <strong>Opt out of interest-based advertising.</strong> See the
                          instructions below or contact Us for information about how to opt out from
                          the use of information to serve targeted advertising by advertisers and/or
                          third-party network advertisers. Note that the effect of opting out of
                          targeted advertising will be to prevent targeted advertising, but will not
                          prevent you from seeing all advertisements. Information may still be
                          collected and used for other purposes (such as research, online services
                          analytics or internal operations, and to enforce your opt-out
                          preferences):
                        </p>
                        <p>
                          <strong>For Apple users: iOS 7 or higher</strong>, you can change your
                          settings in the following location:{' '}
                          <strong>{`Settings > Privacy > Advertising. iOS 6`}</strong>, you can
                          change your settings in the following location:{' '}
                          <strong>{`Settings > General > About > Advertising.`}</strong> For further
                          information please see Apple’s website here: www.apple.com
                        </p>
                        <p>
                          <strong>For other major mobile platforms</strong> Open the platform
                          account settings, select “Ads”.
                        </p>
                        <p>
                          <strong>Opt out of location data</strong> Go to “settings” on your mobile
                          device to opt out of the collection of location data.
                        </p>
                        <p>
                          <strong>Opt out of cross-app data collection.</strong> The Digital
                          Advertising Alliance (“DAA”) offers a separate choice tool for the
                          collection of cross-app data on a mobile device for interest-based
                          advertising and other applicable uses. To exercise choice for companies
                          participating in this choice tool, you can install the DAA’s AppChoices
                          app (see www.aboutads.info/appchoices).
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Do we disclose any information to outside parties? </h5>
                        <p>
                          We do not sell, trade, or otherwise transfer to outside parties your
                          personally identifiable information. This does not include trusted third
                          parties who assist us in operating our website, conducting our business,
                          or servicing you, so long as those parties agree to keep this information
                          confidential. We may also release your information when we believe release
                          is appropriate to comply with the law, enforce our website policies, or
                          protect ours or others rights, property, or safety. However,
                          non-personally identifiable visitor information may be provided to other
                          parties for marketing, advertising, or other uses.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Online Privacy Policy Only</h5>
                        <p>
                          This online privacy policy applies only to information collected through
                          our website and not to information collected offline.
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Your Consent</h5>
                        <p>By using our website, you consent to our online privacy policy.</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>Changes to our Privacy Policy</h5>
                        <p>
                          If we decide to change our privacy policy, we will post those changes on
                          this page.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListingFooter></ListingFooter>
      </>
    );
  }
}

export default withTranslation()(PrivacyPolicy);
