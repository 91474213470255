import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import SubscriberightImage from '../assets/images/subscribe-bg-car.png';
import { contentInstance } from '../axios/axiosInstance';
import SuccessAlert from '../Common/AlertError/SuccessAlert';
import { getLanguage } from '../Common/Util';

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
      successMessage: '',
    };
  }

  onChangeEmail = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validateSubscribe = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: '',
    });

    let valid = true;

    if (this.state.email.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is required',
          successMessage: '',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
          successMessage: '',
        });
      }

      return false;
    }

    if (!validator.isEmail(this.state.email)) {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is invalid',
          successMessage: '',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
          successMessage: '',
        });
      }
      return false;
    }

    return valid;
  };

  onSubscribeSubmit = (e) => {
    e.preventDefault();
    if (this.validateSubscribe()) {
      contentInstance
        .post('newsLetterRequest', { Name: '', EmailId: this.state.email })
        .then((response) => {
          const responseData = response.data.result;
          if (responseData != null && responseData.Message != null) {
            localStorage.setItem('thanksmessage', responseData.Message);
          }

          this.props.history.push('/thankyou');
        })
        .catch((error) => {
          this.props.history.push('/thankyou');
        });
    }
  };
  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Best Vehicle Rental Offers</title>
          <meta
            name="description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com"></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta name="title" content="Dollar Car Rental Oman | Best Vehicle Rental Offers"></meta>
          <meta
            name="description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="og:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaroman.com/"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental Oman | Best Vehicle Rental Offers"
          ></meta>
          <meta
            property="twitter:description"
            content="Choose from a wide range of vehicles &  book an award winning car rental experience with Dollar Car Rental Oman. Book now at a convenient location near you for best prices, guaranteed."
          ></meta>
        </Helmet>
        <section className="subscriber-red-section">
          <div className="container">
            <div className="subscriber-container">
              <div className="subscriber-text-left">
                <h2 className="border-botom font-bold">{t('Join Dollar RED Community.1')}</h2>
                <p>
                  Exclusive Flash Deals, Surprise Giveaways, Early Bird Offers, Road Trip
                  Destinations, Tips & Tricks right in your mailbox!
                </p>
                <form>
                  <input
                    type="text"
                    placeholder={t('Enter Email Address.1')}
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                  />
                  <input
                    id="submit"
                    type="submit"
                    onClick={this.onSubscribeSubmit}
                    value={t('Submit.1')}
                  ></input>
                  {/* value={t('Join Now')}  */}
                </form>
              </div>
              <div className="subscriber-text-right">
                <img src={SubscriberightImage} width={670} height={459} />
              </div>
            </div>
          </div>
          {this.state.successMessage != null && this.state.successMessage.length > 0 ? (
            <SuccessAlert successMessage={this.state.successMessage} />
          ) : null}
          {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
            <div className="custom-input-type-wrap">
              <div className="container">
                <Dialog
                  open={this.state.errorMessage.length > 0}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {this.state.errorMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                      {t('OK.1')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          ) : null}
        </section>
      </>
    );
  }
}

export default withTranslation()(withRouter(Subscribe));
