import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import ContactPopup from '../Common/ContactPopup';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import longTermLeasingPart5 from '../assets/images/Business/1069043807.jfif';
import longTermLeasingPart6 from '../assets/images/Business/1707285715.jfif';
import longTermLeasingPart2 from '../assets/images/Business/1779171935.jfif';
import longTermLeasingPart8 from '../assets/images/Business/658647346.jfif';
import longTermLeasingPart4 from '../assets/images/Business/666270484.jfif';
import longTermLeasingPart7 from '../assets/images/Business/704175295.jfif';
import longTermLeasingPart3 from '../assets/images/Business/712414813.jfif';
import longTermLeasingPart1 from '../assets/images/Business/765129373.jfif';
import { productInstance } from '../axios/axiosInstance';
import BusinessSolutionHeader from './../Other/BusinessSolutionHeader';
import ContactUsBox from './ContactUsBox';
import CorporateLeaseBox from './CorporateLeaseBox';
import KeyPointDollar from './KeyPointDollar';
import TransportFleetMenuBox from './TransportFleetMenuBox';
var qs = require('qs');

class LongTermLeasing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }

  componentDidMount = () => {
    this.getVehicleTypes();
    scroll.scrollToTop();
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });
      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onLeasOptionClick = (maxVehicles) => {
    this.setState({ maxVehicles: maxVehicles });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Long Term Car Rental Oman | Best Deals and Offers | Dollaroman</title>
          <link rel="canonical" href="https://www.dollaroman.com/long-term-leasing" />
          <meta
            name="description"
            content="Looking for long-term car rental in Oman? Enjoy flexible options, affordable rates, and reliable vehicles for your extended stay or business needs across the country."
          ></meta>
          <meta
            property="og:title"
            content="Long Term Car Rental Oman | Best Deals and Offers | Dollaroman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/long-term-leasing"></meta>
          <meta
            property="og:description"
            content="Looking for long-term car rental in Oman? Enjoy flexible options, affordable rates, and reliable vehicles for your extended stay or business needs across the country."
          ></meta>
        </Helmet>

        <ListingHeader />

        <div className="know-us-page py-5 bg-grey">
          <div className="container">
            <div className="border-titlec-content mb-4">
              <h1 className="mb-1">{t('Long Term leasing.1')}</h1>
              <h2 className="mb-1">
                {t('We host Oman’s largest & widest fleet, for all your transportation needs.1')}
              </h2>
            </div>
            <div className="row pb-5">
              {/* <div className="col-4">
                            <img src={awardwinnig}></img>
                        </div> */}
              <div className="col-12">
                <div className="pl-md-4 pl-0">
                  <p className="font-18 text-grey">
                    Renting is the new wise. Of that, there’s no doubt. COVID-19 has changed the way
                    we now look at mobility & convenience. Personal Leasing is a practical approach
                    to having a vehicle to yourself for a longer duration (1-3 years) - without the
                    hassle of maintaining it or having to commit to long term financial contracts.
                  </p>
                  <p className="font-18 text-grey mt-4">
                    A reliable and reputed global mobility partner like Dollar Car Rental Oman can
                    help you with a wide range of latest models of car fleet at the best market
                    price in Oman, on extremely flexible, customer-centric, and favorable terms. Our
                    dedicated Personal Leasing Consultants can help you choose the best vehicle
                    model and pocket-friendly affordable rental plan, that comfortably falls well
                    within your monthly budget. Just let us know your preferred vehicle, be it an
                    SUV, Sedan, Hatchback, or luxury car rental, lease duration, monthly budget, and
                    any other add-ons (child safety seats, GPS trackers, mobile WIFI, etc) and we’ll
                    make sure to help you find the best car rental offers and deals in Oman.
                  </p>
                  <p className="font-18 text-grey mt-4">
                    Let us look at some of the advantages of long-term personal car leasing,
                    especially in Oman.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section className="pb-5">
            <div className="container">
              {/* <h2 className="w-50">What makes Dollar your ultimate <span className="text-red">R</span>ental <span className="text-red">E</span>xperience <span className="text-red">D</span>estination?</h2> */}
              <div className="width-75 pt-5 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart1}
                      className="rounded-xl"
                      alt={'Hassle Free'}
                    ></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0">
                      <h3 className="font-24">Hassle-free & Convenient</h3>
                      <p className="font-18 text-grey pt-3">
                        With Dollar Car Rental Oman, enjoy full-maintenance solutions when you lease
                        a vehicle long term, instead of owning one. This set of solutions include
                        Team Dollar taking care of the end-to-end vehicle registration, insurance,
                        vehicle service and maintenance, toll and traffic fine management, licenses,
                        and all other paper works that usually comes with maintaining a car in Oman.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-3">
                      <h3 className="font-24">Cost-effective</h3>
                      <p className="font-18 text-grey pt-3">
                        At Dollar Car Rental Oman, we believe, the experience of driving your dream
                        vehicle shouldn’t come with a burdening price tag. Buying a new car comes
                        with its own set of paper works and heavy financial commitments, that can
                        lock you for years even if you choose to go with a car loan. When you
                        instead choose to personal lease a vehicle with our long-term
                        pocket-friendly payment plans, you’ll advantage from significant comfort and
                        flexibility financially. This is also true when one considers long-term
                        leasing against public transportation costs like cabs.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart2}
                      className="rounded-xl"
                      alt={'Cost effective'}
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart3}
                      className="rounded-xl"
                      alt={'24X7 Service'}
                    ></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0">
                      <h3 className="font-24">24x7 Support</h3>
                      <p className="font-18 text-grey pt-3">
                        We take pride in being continuously recognized and awarded as one of the
                        leading Car Rental agencies in Oman for providing excellent customer
                        services and experience. When you lease from Dollar Car Rental Oman, you
                        automatically unlock premium benefits like 24x7 emergency roadside
                        assistance, complimentary quick replacement vehicle, free vehicle towing
                        services, accident repair & insurance management, dedicated customer support
                        personnel, and much more! We make sure, you and your family are in safe
                        hands round the clock, even at odd hours.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-3">
                      <h3 className="font-24">Wiser & Smarter Choice</h3>
                      <p className="font-18 text-grey pt-3">
                        Why deal with depreciating costs of an owned vehicle when you can opt for a
                        long-term car rental plan? Why deal with lengthy paper works, monotonous
                        maintenance, and servicing schedules? Why worry about long-term financial
                        commitments when you can Dollar Car Rental Oman like you own it?!
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart4}
                      className="rounded-xl"
                      alt={'Wiser Smarter'}
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart5}
                      className="rounded-xl"
                      alt={'Time Effective'}
                    ></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0">
                      <h3 className="font-24">Time-effective</h3>
                      <p className="font-18 text-grey pt-3">
                        Personal Leasing a vehicle comes with straightforward payments. You can save
                        a lot of time with Team Dollar silently managing all the maintenance and
                        service-related to-dos that come with holding a vehicle. Skip long queues
                        and lengthy paper works to instead enjoy complimentary premium
                        delivery/pickup, online reservations, centralized booking access, etc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-3">
                      <h3 className="font-24">Utmost Flexibility</h3>
                      <p className="font-18 text-grey pt-3">
                        Enjoy the freedom to upgrade or downgrade your lease plan based on your
                        requirements. Visiting family? Extended stay cation plans? A cross-country
                        road trip with your squad? Just let our customer support personnel know of
                        your needs and we’ll be happy to assist you in changing/modifying the
                        contractual terms and vehicle model.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart6}
                      className="rounded-xl"
                      alt={'Utmost Flexibility'}
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart7}
                      className="rounded-xl"
                      alt={'Perfect Lifestyle fit'}
                    ></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0">
                      <h3 className="font-24">Perfect Lifestyle Fit</h3>
                      <p className="font-18 text-grey pt-3">
                        Whether you are in Oman as a tourist vacationing long-term, a new resident,
                        contract-based official business traveler, young professional, visiting
                        family, temporary ex-pat, etc, it only makes sense to lease a vehicle long
                        term, ensuring safety & convenience during your stay in the country. While
                        the 6-month long Oman summers can make choosing public transportation
                        inconvenient at times, it’s also a time where you can enjoy surprise deals,
                        major summer and festival offers at Dollar Car Rental Oman.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-3">
                      <h3 className="font-24">An Award-winning Experience</h3>
                      <p className="font-18 text-grey pt-3">
                        Don’t deny yourself Oman’s award-winning customer experience and support
                        from Dollar Car Rental, that’s trusted and relied on by residents, tourists,
                        and major brands in Oman alike, as their mobility partner - every single
                        day!
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={longTermLeasingPart8}
                      className="rounded-xl"
                      alt={'Dollar Award'}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <BusinessSolutionHeader />

        <TransportFleetMenuBox />

        <KeyPointDollar />

        <CorporateLeaseBox />

        <ContactUsBox
          subHeading="To know further on how you can manage your fleet more efficiently and other business solutions,
contact us today."
          heading="We look forward to serving you."
        ></ContactUsBox>

        <Modal
          show={this.state.showContact}
          onHide={() => this.toogleContactPopup(false)}
          dialogClassName="modal-dialog modal-xl modal-dialog-centered"
        >
          <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
        </Modal>

        <ListingFooter />
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(LongTermLeasing), LongTermLeasing);
