import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';
import Truck from '../assets/images/Business/1010860.jpg';
import dollarprestigecars from '../assets/images/Business/2.jfif';
import covid19banner from '../assets/images/covid-19-response.png';

class StartaReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'startBooking',
      tollFreeNumber: '',
      headerActive: true,
    };
  }
  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  componentDidMount() {
    scroll.scrollToTop();

    const searchParams = new URLSearchParams(this.props.location.search);
    const activeTabParam = searchParams.get('tab');
    if (
      activeTabParam &&
      ['startBooking', 'manageBooking', 'flexiMonthly'].includes(activeTabParam)
    ) {
      this.setState({ activeTab: activeTabParam });
    }
  }

  setActiveTab = (tab) => {
    this.props.history.push(`?tab=${tab}`);
    this.setState({ activeTab: tab });
  };

  handleBanner = (val) => {};

  handleHeaderActive = (value) => {
    if (window.innerWidth > 567) {
      this.setState({ headerActive: value });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental{' '}
          </title>
          <link rel="canonical" href="https://www.dollaroman.com/start-a-reservation" />
          <meta
            name="description"
            content="Your Quick Guide to a hassle-free car rental experience in Oman. Rent a Car in Muscat, Salalah & Sohar. "
          ></meta>
          <meta
            property="og:title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental "
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/start-a-reservation"></meta>
          <meta
            property="og:description"
            content="Your Quick Guide to a hassle-free car rental experience in Oman. Rent a Car in Muscat, Salalah & Sohar. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>

          <meta
            name="title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental "
          ></meta>
          <meta
            name="description"
            content="Your Quick Guide to a hassle-free car rental experience in Oman. Rent a Car in Muscat, Salalah & Sohar. "
          ></meta>

          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaroman.com/start-a-reservation"></meta>
          <meta
            property="og:title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental "
          ></meta>
          <meta
            property="og:description"
            content="Your Quick Guide to a hassle-free car rental experience in Oman. Rent a Car in Muscat, Salalah & Sohar. "
          ></meta>

          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta
            property="twitter:url"
            content="https://www.dollaroman.com/start-a-reservation"
          ></meta>
          <meta
            property="twitter:title"
            content="Vehicle Rental Guide: All You Need to Know Before Booking Your Next Car Rental "
          ></meta>
          <meta
            property="twitter:description"
            content="Your Quick Guide to a hassle-free car rental experience in Oman. Rent a Car in Muscat, Salalah & Sohar. "
          ></meta>
        </Helmet>
        {this.state.headerActive && <ListingHeader />}
        <div className="minH100 bg-grey">
          <section className="sort-c1 reservationHeight">
            <div className="inner-wrapper" style={{ background: 'white' }}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content " id="nav-tabContent">
                      <StartBooking
                        manageBanner={this.handleBanner}
                        handleHeaderActive={this.handleHeaderActive}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section grey-bg py-2 mt-3">
            <div className="container-fluid">
              <div className="box-shadow-white-box mb-0">
                <div className="mb-4">
                  <h1 className="border-botom">Hassle-free Car Rental Experience</h1>
                  <h2 className="border-botom">Vehicle Rental Guide</h2>
                  <p>Your Quick Guide to getting a Hassle-free Car Rental Experience in Oman. </p>
                  {/* <p className="text_p mt-2"> Dollar Car Rental Oman requires one to be of 22 years old and above for all our car rental plans within the country.</p> */}
                </div>
                <div className="row">
                  <div className="col-lg-12 p-3">
                    <h5 className="font-weight-bold">Minimum Age Limit</h5>
                    <p className="text_p mt-md-4 mt-2">
                      Dollar Car Rental Oman requires one to be 22 years old and above for all our
                      car rental plans within the country.
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Reservation Methods</h5>
                    <p className="text_p mt-md-4 mt-2">
                      You may make your reservations online at www.dollaroman.com, call our 24x7
                      sales representatives at {this.state.tollFreeNumber} , visit any of our
                      counters at locations across Oman or email us at{' '}
                      <a
                        href="mailto:makeabooking@dollaroman.ae"
                        className="footer-mobile footer-email"
                        title="Mail"
                      >
                        makeabooking@dollaroman.ae
                      </a>
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Security Deposits</h5>
                    <p className="text_p mt-md-4 mt-2">
                      Valid Credit Card with minimum 3 months validity.
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Mode of Payment</h5>
                    <p className="text_p mt-md-4 mt-2">
                      Dollar Car Rental Oman accepts all major Credit Cards (MASTER, VISA, JCB,
                      DINERS). Credit card must be valid for a minimum of 3 months from the day of
                      rental and must be physically available at the time of vehicle collection.
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Vehicle Inspection &amp; Add-ons</h5>
                    <p className="text_p mt-md-4 mt-2">
                      Once you’ve ensured the vehicle quality is up to your satisfaction, you may
                      proceed to sign the rental contract and complete the payment process. You may
                      also add on amenities like baby/ toddler rear seats, GPS trackers, Mobile
                      WIFI, etc. at this stage.
                    </p>
                  </div>
                </div>
                <a
                  onClick={() => this.onMenuLinkClick('/terms')}
                  className="primary-bttn primary-invert-bttn mt-auto"
                  title="View More"
                >
                  {t('Read More.1')}
                </a>
              </div>
            </div>
          </section>

          <section className="box-shadow-white-box-section grey-bg py-2" id="id6">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a" id="partner">
                <div className="pad-30">
                  <div className="row">
                    <div className="col-12 col-lg-4" id="truckrental" name="truckrental">
                      <div className="pickup-location-flex-content h-100 d-flex flex-column align-items-start">
                        <img
                          src={covid19banner}
                          style={{ height: '300px' }}
                          alt="Dollar Covid 19 Banner"
                          className="rounded-xl"
                        />
                        <h3 className="mb-0 w-100">Dollar COVID-19 Response.</h3>
                        <span className="mb-2 mt-0">
                          We are closely monitoring Coronavirus (COVID-19) and following the current
                          guidance from the leading government and health authorities to ensure we
                          are taking the right actions to protect our customers, employees and the
                          communities where we operate.
                        </span>
                        <a
                          onClick={() => this.onMenuLinkClick('/covid19')}
                          className="primary-bttn primary-invert-bttn mt-auto"
                          title="View More"
                        >
                          View More
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                      <div className="pickup-location-flex-content h-100 d-flex flex-column align-items-start">
                        <img
                          src={dollarprestigecars}
                          style={{ height: '300px' }}
                          alt="Dollar Prestige"
                          className="rounded-xl"
                        />
                        <h3 className="mb-0 w-100">Dollar Prestige</h3>
                        <span className="mb-2 mt-0">
                          With the Dollar Prestige Collection, you can drive away in a luxury car at
                          an affordable rate. No matter what you have planned for your next trip – a
                          getaway with friends, a monthly business trip, or an annual family
                          vacation – you can now elevate your travel with one of the vehicles from
                          the Dollar Oman Prestige Collection.
                        </span>
                        <a
                          onClick={() => this.onMenuLinkClick('/dollarprestige')}
                          className="primary-bttn primary-invert-bttn mt-auto"
                          title="View More"
                        >
                          View More
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                      <div className="pickup-location-flex-content h-100 d-flex flex-column align-items-start">
                        <img
                          src={Truck}
                          style={{ height: '300px' }}
                          alt="Dollar Special Vehicles"
                          className="rounded-xl"
                        />
                        <h3 className="mb-0 w-100">Specialized Vehicles</h3>
                        <span className="mb-2 mt-0">
                          Dollar Car Rental Oman takes pride in being associated with leading brands
                          in Oman like Pepsi, Mai Muscat, Pran Foods, NFPC, Aramex, Carrefour, etc.
                          as their total transportation solutions partner, by helping them achieve
                          reduced fleet management costs, improved fleet compliance, customized
                          vehicle-builds, and other business-specific goals.
                        </span>
                        <a
                          onClick={() => this.onMenuLinkClick('/specializedvehicles')}
                          className="primary-bttn primary-invert-bttn mt-auto"
                          title="View More"
                        >
                          {t('View More.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(StartaReservation)), StartaReservation);
// export default StartaReservation;
