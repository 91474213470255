import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import globalpartner from '../assets/images/Business/1010860.jpg';
import specializedVehiclePart2 from '../assets/images/Business/1789307906.jfif';
import largestwidest from '../assets/images/Business/2019-Toyota-Hiace.jpg';
import specializedVehiclePart4 from '../assets/images/Business/262164383.jfif';
import dollarHunt from '../assets/images/Business/hiac_cc_1903_1006.jpg';
import BusinessMobilityBox from './BusinessMobilityBox';
import ContactUsBox from './ContactUsBox';
import VehicleByVehicleTypes from './VehicleByVehicleTypes';
class SpecializedVehicles extends Component {
  componentDidMount = () => {
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Specialized Vehicle Services for Mobility & Transportation solutions in Oman
          </title>
          <meta
            name="description"
            content="Your one stop for budget-friendly business mobility. From acquisition to maintainance and more  - we specialise in providing the complete package for your business needs. "
          ></meta>
          <meta
            property="og:title"
            content="Specialized Vehicle Services for Mobility & Transportation solutions in Oman"
          ></meta>
          <meta property="og:url" content="https://www.dollaroman.com/specialized-vehicles"></meta>
          <meta
            property="og:description"
            content="Your one stop for budget-friendly business mobility. From acquisition to maintainance and more  - we specialise in providing the complete package for your business needs. "
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 bg-grey">
          <section className="py-5 bg-grey">
            <div className="container">
              <div className="border-titlec-content mb-5">
                <h2 className="mb-1">Specialized Vehicles</h2>
                <p className="font-18 text-grey">Fleet Range as Unique as your Brand.</p>
              </div>
              <div className="width-75 py-5 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-5 col-12">
                    <img src={globalpartner} className="rounded-xl" alt={'Long Term Lease'}></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0 pt-4 pt-md-0">
                      <h3 className="font-24">
                        We believe, no road is long with the right company!
                      </h3>
                      <p className="font-18 text-grey pt-3">
                        And that is why, at Dollar Car Rental, we aim to be the leading and
                        innovative provider of mobility & transportation solutions in Oman.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-md-3 pr-0">
                      <p className="font-18 text-grey pt-3">
                        Dollar Car Rental Oman takes pride in being associated with leading brands
                        in Oman as their total transportation solutions partner, by helping them
                        achieve reduced fleet management costs, improved fleet compliance,
                        customized vehicle-builds, and other business-specific goals.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <img
                      src={specializedVehiclePart2}
                      className="rounded-xl"
                      alt={'Commercial Vehicles'}
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-5 col-12">
                    <img
                      src={largestwidest}
                      className="rounded-xl"
                      alt={'Customizable Vehicular'}
                    ></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0 pt-4 pt-md-0">
                      {/* <h3 className="font-24">Largest & Widest</h3> */}
                      <p className="font-18 text-grey pt-3">
                        Customizable vehicular modifications for business-specific requirements are
                        one of our key fortes. Based on the needs of a large number of our clients,
                        we have expanded and increased our focus on ‘specialized vehicles’, targeted
                        towards the transport/logistics/distribution companies and commercial fleet
                        owners in Oman. We now offer these vehicles on a long-term lease as per your
                        unique business-specific requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-md-3 pr-0">
                      {/* <h3 className="font-24">Dollar RED Carpet Services</h3> */}
                      <p className="font-18 text-grey pt-3">
                        Our award-winning services ensure you benefit from expert guidance from our
                        sales consultants, who also have the industry experience, equipment and
                        product knowledge specific to your business needs. With easy access to
                        specialized vehicles, project-based, seasonal or peak demands are concerns
                        long gone. Our fleet portfolio also ranges wide enough to cater to
                        lastminute requirements & prospective opportunities for your business.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <img
                      src={specializedVehiclePart4}
                      className="rounded-xl"
                      alt={'Award Winning'}
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-md-5 col-12">
                    <img src={dollarHunt} className="rounded-xl" alt={'All Modern Vehicles'}></img>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="oddEven-content pl-md-3 pl-0 pt-4 pt-md-0">
                      {/* <h3 className="font-24">Dollar Hunt</h3> */}
                      <p className="font-18 text-grey pt-3">
                        All our vehicles are modern, reliable & adaptable, making them a wise choice
                        for businesses that involve transportation of expensive and perishable
                        goods.!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <BusinessMobilityBox></BusinessMobilityBox>
          <VehicleByVehicleTypes vehicleClassificationID="f2a4bbb4-bfd8-4285-bbfc-df5b00567571"></VehicleByVehicleTypes>
          <ContactUsBox
            subHeading="To know more on specialized vehicles for your business, contact us today."
            heading="We look forward to serving you."
          ></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(SpecializedVehicles);
