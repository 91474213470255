import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll, scroller } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getCurrency, getUserInfo } from '../Common/Util';
import noResultImage from '../assets/images/icons8-spinner.gif';
import sortBy from '../assets/images/sort-by-icon.svg';
import { bookingInstance } from '../axios/axiosInstance';
import IncludePageInline from './MonthlIncludePageInline';
import ListingCard from './MonthlyListingCard';
import ListingPage2 from './MonthlyListingPage2';
import ListingPage3 from './MonthlyListingPage3';
import RentalDetails from './MonthlyRentalDetails';

class MonthlyListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      selectVehicleForBooking: {},
      vehicleTypeDefaultValue: 'Vehicle Type',
      vehicleModels: [],
      isFirstTimeLoad: true,
      allVehicleTypes: [],
      showStickHeader: false,
      selectedVehicleIndex: 9999,
      isActive: 0,
      columnCount: 4,
      showRentalDetails: false,
      step1: 1,
      paySectionValue: '',
      selectedVehicleType: '00000000-0000-0000-0000-000000000000',
      additionalParam: {
        pickupLocationName: '',
        pickupLocationAddress: '',
        pickupLocationMessage: '',
        pickupLocationBranchDescription: '',
        originalVehicleModelID: '00000000-0000-0000-0000-000000000000',
        originalTariffGroupID: '00000000-0000-0000-0000-000000000000',
        originalTariffRateID: '00000000-0000-0000-0000-000000000000',
      },

      searchParam: {
        SubscriptionMonth: 0,
        SubscriptionKm: 0,
        Classifications: [],
        VehicleModelID: '00000000-0000-0000-0000-000000000000',
        CheckOutOn: '2024-09-20T15:51:00',
        DeliveryDetails: {
          Address: '',
          StateName: '',
          StateCode: '',
        },
        CheckOutBranchID: '663cc0d59ce22eb7250a0045',
        ShukranId: '',
        PaymentStatus: 1,
        UpgradeModelId: '00000000-0000-0000-0000-000000000000',
        ModifiedReservationID: '00000000-0000-0000-0000-000000000000',
        ContactID: '00000000-0000-0000-0000-000000000000',
        Daily: false,
      },
      iPAddress: '127.0.0.1',
      customerCountryCode: 'OMR',
      customerCountryName: 'Oman',
      isChecked: false,
      isHeaderActive: true,
      showPayNowLaterBtn: true,
    };
  }

  componentDidMount() {
    this.getIPAddress();
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.update);
    this.update();
    getCurrency();
    setTimeout(() => {
      this.GetVehicleModels();
    }, 1000);
    const userinfo = getUserInfo();
    if (userinfo !== null) this.state.searchParam.ContactID = userinfo.CustomerID;
  }

  update = () => {
    let columnCount = 4;
    if (window.innerWidth <= 530) columnCount = 1;
    if (window.innerWidth > 530 && window.innerWidth <= 767) columnCount = 2;
    if (window.innerWidth > 767 && window.innerWidth <= 1024) columnCount = 3;
    if (window.innerWidth > 991) columnCount = 4;
    this.setState({ columnCount: columnCount });
  };

  GetVehicleModels = () => {
    const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));

    const defaultParams = {
      CheckOutLocationId: '',
      PickUpDate: '',
      ShukranId: '',
      pickupLocationName: '',
      pickupLocationAddress: '',
      pickupLocationMessage: '',
      pickupLocationBranchDescription: '',
      deliveryState: '',
      deliveryCode: '',
      deliveryAddress: '',
      daily: false,
    };

    let params = { ...defaultParams };
    let searchParam = { ...this.state.searchParam };
    let additionalParam = { ...this.state.additionalParam };

    if (BookingInfo && BookingInfo.length) {
      const booking = BookingInfo[0];
      const modifiedReservationID = booking?.ModifiedReservationID;

      if (
        modifiedReservationID &&
        modifiedReservationID !== '00000000-0000-0000-0000-000000000000'
      ) {
        this.setState({ showPayNowLaterBtn: false });
      }

      params = {
        ...params,
        CheckOutLocationId: booking.CheckOutLocationId,
        PickUpDate: booking.PickUpDate,
        pickupLocationName:
          booking.pickupLocationName || `${booking.returnState} ${booking.returnAddress}`,
        ShukranId: booking.ShukranId,
        pickupLocationAddress: booking.pickupLocationAddress,
        pickupLocationBranchDescription: booking.pickupLocationBranchDescription,
        pickupLocationMessage: booking.pickupLocationMessage,
        deliveryState: booking.deliveryState,
        deliveryCode: booking.deliveryCode,
        deliveryAddress: booking.deliveryAddress,
        daily: false,
      };

      searchParam = {
        ...searchParam,
        CheckOutBranchID: params.CheckOutLocationId,
        CheckOutOn: params.PickUpDate,
        ShukranId: params.ShukranId,
        PickupDetails: {
          StateName: params.returnState,
          StateCode: params.returnCode,
          Address: params.returnAddress,
        },
        Daily: params.daily,
      };

      additionalParam = {
        ...additionalParam,
        pickupLocationName: params.dropOffLocationName,
        pickupLocationAddress: params.dropOffLocationAddress,
        pickupLocationMessage: params.dropOffLocationMessage,
        pickupLocationBranchDescription: params.dropOffLocationBranchDescription,
      };

      if (modifiedReservationID) {
        searchParam.ModifiedReservationID = modifiedReservationID;
        localStorage.removeItem('BookingInfo');
        setTimeout(() => {
          this.setState({ showDetails: true });
        }, 1000);
      }
    } else {
      params = {
        ...params,
        CheckOutLocationId: this.state.searchParam.CheckOutBranchID,
        PickUpDate: this.state.searchParam.CheckOutOn,
        daily: this.state.searchParam.Daily,
        pickupLocationName: this.state.additionalParam.pickupLocationName,
      };
    }

    const vehicleTypeParam = localStorage.getItem('VehicleTypeParam');
    if (vehicleTypeParam) {
      searchParam.Classifications = [vehicleTypeParam];
      this.setState({ vehicleTypeDefaultValue: vehicleTypeParam });

      setTimeout(() => {
        this.setState({ showDetails: true });
      }, 1000);
      localStorage.removeItem('VehicleTypeParam');
    }

    this.setState({ searchParam, additionalParam });
    this.fetchVehicleModels(
      params.PickUpDate,
      params.CheckOutLocationId,
      searchParam.Classifications,
      params.ShukranId,
      params.deliveryState,
      params.deliveryCode,
      params.deliveryAddress,
      params.daily,
    );
  };

  onVehicleTypeChange = (e) => {
    let searchParam = this.state.searchParam;
    let classfications = [];

    if (e != '00000000-0000-0000-0000-000000000000') classfications.push(e);

    this.setState({ selectedVehicleType: e });
    searchParam.Classifications = classfications;
    this.setState({ searchParam: searchParam });
    this.setState({ vehicleTypeDefaultValue: e });

    var deliveryState = null;
    var deliveryCode = null;
    var deliveryAddress = null;

    const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));

    if (BookingInfo != null && BookingInfo != undefined) {
      deliveryState = BookingInfo[0].deliveryState;
      deliveryCode = BookingInfo[0].deliveryCode;
      deliveryAddress = BookingInfo[0].deliveryAddress;
    }

    this.fetchVehicleModels(
      this.state.searchParam.CheckOutOn,
      this.state.searchParam.CheckOutBranchID,
      classfications,
      this.state.searchParam.ShukranId,
      deliveryState,
      deliveryCode,
      deliveryAddress,
      this.state.searchParam.Daily,
    );
  };

  onSortChange = (e) => {
    let vehicles;

    if (e.target.value == 'lowhigh') {
      vehicles = this.state.vehicleModels.sort((a, b) =>
        a.RentalCharges.DiscountedRate > b.RentalCharges.DiscountedRate ? 1 : -1,
      );
    } else if (e.target.value == 'highlow') {
      vehicles = this.state.vehicleModels.sort((a, b) =>
        b.RentalCharges.DiscountedRate > a.RentalCharges.DiscountedRate ? 1 : -1,
      );
    } else {
      vehicles = this.state.vehicleModels.sort((a, b) =>
        (a.IsBestSeller ? 1 : 0) > (b.IsBestSeller ? 1 : 0) ? 1 : -1,
      );
    }
    this.setState({ vehicleModels: vehicles });
  };

  fetchVehicleModels = (
    pickupDate,
    checkOutLocationId,
    vehicleType,
    ShukranId,
    deliveryState,
    deliveryCode,
    deliveryAddress,
    daily,
  ) => {
    this.onSelectedVehicleIndexChange(9999, {});
    let paramVehicleType = [];

    if (vehicleType.length > 0 && vehicleType[0] !== 'Vehicle Type') {
      paramVehicleType = [vehicleType[0]];
    }

    let searchParam = {
      CheckOutOn: pickupDate,
      CheckOutBranchID: checkOutLocationId,
      ShukranId: ShukranId,
      ModifiedReservationID: this.state.searchParam.ModifiedReservationID,
      IPLocation: {
        IPAddress: this.state.iPAddress,
        Country_code: this.state.customerCountryCode,
        Country_name: this.state.customerCountryName,
      },
      Daily: daily,
      ...(paramVehicleType.length && { Classifications: paramVehicleType }),
      ...(deliveryCode &&
        deliveryCode.length && {
          DeliveryDetails: {
            Address: deliveryAddress,
            StateName: deliveryState,
            StateCode: deliveryCode,
          },
        }),
    };

    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`reservation?countryCode=${countryCode}`, searchParam)
      .then((response) => {
        const { data } = response.data.result;
        if (data && data.SearchVehicleModels.length > 0) {
          this.setState({
            vehicleModels: data.SearchVehicleModels,
            allVehicleTypes: data.VehicleTypes,
          });
        } else {
          this.setState({ isFirstTimeLoad: false });
        }
      })
      .catch((error) => {
        if (error) this.setState({ isFirstTimeLoad: false });
      });
  };

  getIPAddress = () => {
    var config = {
      method: 'get',
      url: 'https://geolocation-db.com/json/',
      headers: {},
    };

    axios(config)
      .then((res) => {
        this.setState({
          iPAddress: res.data.IPv4,
          customerCountryCode: res.data.country_code,
          customerCountryName: res.data.country_name,
        });
      })
      .catch((error) => {});
  };

  toogleBookNowPopup = (step) => {
    this.setState({ step1: step });
    this.setState({ isActive: 1 });

    if (step == 1) this.setState({ showDetails: !this.state.showDetails });
  };

  onUpgradeModelSelected = (selectedModel, originalVehicleModelID, originalTariffGroupID) => {
    let additionalParam = this.state.additionalParam;
    additionalParam.originalVehicleModelID = originalVehicleModelID;
    additionalParam.originalTariffGroupID = originalTariffGroupID;
    this.setState({ additionalParam: additionalParam });
    this.onVehicleChange(selectedModel, '');
  };

  onVehicleChange = (selectedModel, paySelection, selectedMonth, selectedKM) => {
    this.setState({ selectVehicleForBooking: selectedModel });
    this.setState({ paySectionValue: paySelection });
    let searchParam = this.state.searchParam;
    searchParam.SubscriptionMonth = selectedMonth;
    searchParam.SubscriptionKm = selectedKM;

    if (paySelection == 'paynow' || selectedModel.PaymentStatus == 1) searchParam.PaymentStatus = 1;
    else searchParam.PaymentStatus = 0;

    this.setState({ searchParam: searchParam });
    this.toogleBookNowPopup(4);
    scroll.scrollToTop();
  };

  onContinueClickHandler = (searchParam) => {
    this.setState({ searchParam: searchParam });
    this.toogleBookNowPopup(4);
    scroll.scrollToTop();
  };

  onSelectedVehicleIndexChange = (index, selectedVehicle) => {
    this.setState({
      selectedVehicleIndex: index,
      selectVehicleForBooking: selectedVehicle,
    });

    if (selectedVehicle != null)
      setTimeout(function () {
        scroller.scrollTo(selectedVehicle.VehicleModelID + '_box', {
          smooth: 'easeInOutQuint',
        });
      }, 1000);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let lastScrollY = window.scrollY;

    if (lastScrollY > 100) this.setState({ showStickHeader: true });
    else this.setState({ showStickHeader: false });
  };

  showBookingDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  handleHeaderActive = (index) => {
    if (window.innerWidth > 567.75) this.setState({ isHeaderActive: index });
  };

  render() {
    const { t } = this.props;
    let remaining = this.state.vehicleModels.length % this.state.columnCount;
    return (
      <div className="grey-bg">
        {this.state.isHeaderActive && <ListingHeader></ListingHeader>}
        <div class="minH100  pay-2">
          <section
            className={
              this.state.showStickHeader
                ? 'rental-steps-section rental-steps-sticky'
                : 'rental-steps-section'
            }
          >
            <div className="container-fluid">
              <ul className="rental-steps-wrap new--rental-steps-wrap">
                <li className="active checked" onClick={() => this.toogleBookNowPopup(1)}>
                  <a className="rental-step-dropdown" title="Rental Details" data-steps="1">
                    <span className="rental-steps-title">{t('Location & Vehicle.1')}</span>
                    <div className="rental-steps-content rental-steps-done">
                      <div className="rental-detail-place">
                        <div className="rental-detail-place-left" onClick={this.showBookingDetails}>
                          <span>{this.state.additionalParam.dropOffLocationName}</span>
                        </div>
                        <div
                          className="rental-detail-place-right"
                          onClick={this.showBookingDetails}
                        >
                          <span>{this.state.additionalParam.pickupLocationName}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li
                  className={
                    this.state.step1 == 1 && this.state.showRentalDetails == false
                      ? 'active'
                      : this.state.step1 > 2
                        ? 'active checked rental-step-dropdown'
                        : 'rental-step-dropdown'
                  }
                  onClick={() => this.toogleBookNowPopup(1)}
                >
                  <a className="rental-step-dropdown" title="Vehicle Selection" data-steps="2">
                    <span className="rental-steps-title">{t('Add-Ons & Documents.1')}</span>
                    <div className="rental-steps-content rental-steps-done">
                      <div className="rental-detail-place">
                        <div className="rental-detail-place-left">
                          <span>
                            {this.state.selectVehicleForBooking != null
                              ? this.state.selectVehicleForBooking.Name
                              : ''}
                          </span>
                        </div>
                        <div className="rental-detail-place-right">
                          <span>
                            {this.state.selectVehicleForBooking != null ? (
                              <>{t('or Similar.1')}</>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                  {this.state.selectVehicleForBooking == null ? (
                    <div className="rental-steps-content rental-steps-current">{t('Select.1')}</div>
                  ) : null}
                </li>

                <li className={this.state.step1 == 4 ? 'active' : 'rental-step-dropdown'}>
                  <a className="rental-step-dropdown" title="Rental Details" data-steps="4">
                    <span className="rental-steps-title">{t('Payment & Confirmation.1')}</span>
                  </a>
                  {this.state.step1 == 4 ? (
                    <div className="rental-steps-content rental-steps-current">{t('Select.1')}</div>
                  ) : null}
                </li>
              </ul>
            </div>
          </section>

          {this.state.step1 == 1 ? (
            <div>
              <RentalDetails
                key="rentalDetails1"
                showDetails={this.state.showDetails}
                PickUpDate={this.state.searchParam.CheckOutOn}
                ReturnDate={this.state.searchParam.CheckInOn}
                pickupLocationName={this.state.additionalParam.dropOffLocationName}
                dropOffLocationName={this.state.additionalParam.pickupLocationName}
                ShukranId={this.state.searchParam.ShukranId}
                modifiedReservationID={this.state.searchParam.ModifiedReservationID}
                deliveryState={this.state.searchParam.DeliveryDetails.StateName}
                deliveryCode={this.state.searchParam.DeliveryDetails.StateCode}
                deliveryAddress={this.state.searchParam.DeliveryDetails.Address}
                handleHeaderActive={this.handleHeaderActive}
              />

              <section className="offer-box-section mt-4">
                <div className="container-fluid">
                  <div className="choose-vehicle-title">
                    <h1 className="h4">{t('Choose your wheels.1')}</h1>
                    <div className="vehicle-type-box">
                      <img src={sortBy} />
                      <select
                        name="sort-by"
                        className="custom-filter-box"
                        onChange={this.onSortChange}
                        defaultValue={'asc'}
                      >
                        <option key={1} disabled="" value="asc">
                          {t('Sort by.1')}
                        </option>
                        <option value="lowhigh">{t('Low to High.1')}</option>
                        <option value="highlow">{t('High to Low.1')}</option>
                        <option value="mostpopular">{t('Best Sellers.1')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="offer-filter-wrap">
                    <div
                      className={
                        this.state.selectedVehicleType == '00000000-0000-0000-0000-000000000000'
                          ? 'what--new-box what--new-box-all active'
                          : 'what--new-box what--new-box-all'
                      }
                      value={this.state.vehicleTypeDefaultValue}
                    >
                      <a
                        className="what--new-link"
                        onClick={() => {
                          this.onVehicleTypeChange('00000000-0000-0000-0000-000000000000');
                        }}
                      >
                        <p>{t('All.1')}</p>
                      </a>
                    </div>
                    {this.state.allVehicleTypes.map((type) => (
                      <div
                        className={
                          this.state.selectedVehicleType ==
                          type.VehicleClassification.VehicleClassificationID
                            ? 'what--new-box active'
                            : 'what--new-box'
                        }
                        value={this.state.vehicleTypeDefaultValue}
                      >
                        <a
                          className="what--new-link"
                          onClick={() => {
                            this.onVehicleTypeChange(
                              type.VehicleClassification.VehicleClassificationID,
                            );
                          }}
                        >
                          <p>{type.VehicleClassification.Name}</p>
                          <img
                            src={type.VehicleClassification.Attachment.ImageString}
                            alt={type.VehicleClassification.Name}
                          />
                          <div className="what-new-box-more-content">
                            <p className="mb-0">
                              {t('Starting from.1')}
                              <br></br>{' '}
                              <span>
                                {t('OMR.1')} {(type.MinPrice * 1.05).toFixed(0)}
                              </span>
                            </p>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              <section className="offer-box-section">
                <div className="container-fluid">
                  <div className="offer-box-wrapper">
                    {this.state.searchParam.ShukranId !== '' && this.state.searchParam.ShukranId ? (
                      <div className="row justify-content-end mt-4 w-100 no-gutters pr-3">
                        <div className="col-auto">
                          <div className="promocode-applied-box">
                            {t('Shukran Id applied.1')}:{' '}
                            <span>{this.state.searchParam.ShukranId}</span>{' '}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.vehicleModels.length > 0 ? (
                      this.state.vehicleModels.map((model, index) => {
                        return (index + 1) % this.state.columnCount != 0 ? (
                          <ListingCard
                            key={model.VehicleModelID + '_' + model.ModelYear}
                            showPayNowLaterBtn={this.state.showPayNowLaterBtn}
                            model={model}
                            onUpgradeModelSelected={this.onUpgradeModelSelected}
                            onVehicleChange={this.onVehicleChange}
                            hideInclude={0}
                            hideUpgradeOptions={0}
                            showDiffPrice={0}
                            searchParam={this.state.searchParam}
                            additionalParam={this.state.additionalParam}
                            vehicleIndex={index + 1}
                            onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                            selectedVehicleIndex={this.state.selectedVehicleIndex}
                            days={model.RentalDays}
                          />
                        ) : this.state.selectedVehicleIndex > index + 1 - this.state.columnCount &&
                          this.state.selectedVehicleIndex <= index + 1 ? (
                          <>
                            <ListingCard
                              key={model.VehicleModelID + '_' + model.ModelYear}
                              showPayNowLaterBtn={this.state.showPayNowLaterBtn}
                              onUpgradeModelSelected={this.onUpgradeModelSelected}
                              model={model}
                              onVehicleChange={this.onVehicleChange}
                              hideInclude={0}
                              hideUpgradeOptions={0}
                              showDiffPrice={0}
                              searchParam={this.state.searchParam}
                              additionalParam={this.state.additionalParam}
                              vehicleIndex={index + 1}
                              onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                              selectedVehicleIndex={this.state.selectedVehicleIndex}
                              days={model.RentalDays}
                            />

                            <IncludePageInline
                              id="carTemp1"
                              key={index}
                              model={this.state.selectVehicleForBooking}
                              onVehicleChange={this.onVehicleChange}
                              hideInclude={0}
                              hideUpgradeOptions={0}
                              searchParam={this.state.searchParam}
                              additionalParam={this.state.additionalParam}
                              onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                            ></IncludePageInline>
                          </>
                        ) : (
                          <ListingCard
                            key={model.VehicleModelID + '_' + model.ModelYear}
                            showPayNowLaterBtn={this.state.showPayNowLaterBtn}
                            onUpgradeModelSelected={this.onUpgradeModelSelected}
                            model={model}
                            onVehicleChange={this.onVehicleChange}
                            hideInclude={0}
                            hideUpgradeOptions={0}
                            showDiffPrice={0}
                            searchParam={this.state.searchParam}
                            additionalParam={this.state.additionalParam}
                            vehicleIndex={index + 1}
                            onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                            selectedVehicleIndex={this.state.selectedVehicleIndex}
                            days={model.RentalDays}
                          />
                        );
                      })
                    ) : this.state.isFirstTimeLoad == false ? (
                      <div className="nodata-for-rent-section">
                        <h3>{t('Ohh ! No vehicles available for requested date.1')}</h3>
                        <p>
                          {t(
                            'Please try to search for another date period or contact our customer care at 800 Dollar.1',
                          )}
                        </p>
                      </div>
                    ) : (
                      <div className="nodata-for-rent-section">
                        <h3>{t('Searching for best rates for you, please wait.1')}</h3>
                        <br></br>
                        <br></br>
                        <img
                          src={noResultImage}
                          alt={t('Searching for Best rates for you.1')}
                          style={{ width: '100px', height: '100px' }}
                        />
                      </div>
                    )}
                    {remaining != 0 &&
                    this.state.selectedVehicleIndex >
                      this.state.vehicleModels.length - remaining ? (
                      <IncludePageInline
                        key={remaining}
                        model={this.state.selectVehicleForBooking}
                        onVehicleChange={this.onVehicleChange}
                        hideInclude={0}
                        hideUpgradeOptions={0}
                        searchParam={this.state.searchParam}
                        additionalParam={this.state.additionalParam}
                        onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                      ></IncludePageInline>
                    ) : null}
                  </div>
                </div>
              </section>
            </div>
          ) : null}
          {this.state.step1 == 2 ? <div></div> : null}
          {this.state.step1 == 3 ? (
            <div>
              <ListingPage2
                selectVehicleForBooking={this.state.selectVehicleForBooking}
                searchParam={this.state.searchParam}
                additionalParam={this.state.additionalParam}
                onContinueClick={this.onContinueClickHandler}
              />
            </div>
          ) : null}
          {this.state.step1 == 4 ? (
            <div>
              <ListingPage3
                selectVehicleForBooking={this.state.selectVehicleForBooking}
                searchParam={this.state.searchParam}
                additionalParam={this.state.additionalParam}
                onUpgradeModelSelected={this.onUpgradeModelSelected}
                iPAddress={this.state.iPAddress}
                customerCountryCode={this.state.customerCountryCode}
                customerCountryName={this.state.customerCountryName}
              ></ListingPage3>
            </div>
          ) : null}
        </div>
        <ListingFooter></ListingFooter>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(MonthlyListingPage), MonthlyListingPage);
