import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ContactPopup from '../Common/ContactPopup';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import VehicleClassifications from '../Hire/VehicleClassifications';
import carrental from '../assets/images/Business/Car Rental Program For Businesses.jpg';
import LeasingInquiry from './LeasingInquiry';
import VehicleByVehicleTypes from './VehicleByVehicleTypes';
class ExploreCorporateFleet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  componentDidMount = () => {
    scroll.scrollToTop();
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };
  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental Oman | Best In Class Corporate Fleet Management</title>
          <meta
            name="description"
            content="Dollar Car Rental Oman is a complete rental provider for corporate needs. We cater to every aspect of your requirement and provide modern, exclusive and the ultimate transportation solutions. "
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental Oman | Best In Class Corporate Fleet Management"
          ></meta>
          <meta
            property="og:url"
            content="https://www.dollaroman.com/explore-corporate-fleet"
          ></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental Oman is a complete rental provider for corporate needs. We cater to every aspect of your requirement and provide modern, exclusive and the ultimate transportation solutions. "
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 bg-grey">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a mt-0">
                <div className="">
                  <div className="row  flex-reversea">
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 ">
                      <div className="pickup-location-box">
                        <div class="border-titlec-content mb-4">
                          <h2 class="mb-1">Car Rental Program For Businesses</h2>
                          <p class="font-18 text-grey">Startup or Fortune 500, Rentals for All.</p>
                        </div>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <span>
                              Enjoy discounted corporate rates when you choose Dollar Car Rental
                              Oman. With locations across Oman, including all major airports, we
                              promise a hassle-free travel & uncompromising convenience. Choose from
                              a wide range of fleet portfolio, custom add on personalizations & get
                              easy access to tracking solutions. Our corporate leasing works on a
                              pay-for-usage model, rather than fleet ownership. This allows our
                              clients to use vehicles, based on their requirements with simple
                              straightforward lease rentals, while not having to worry about the
                              hassles of fleet management, servicing, insurance, damage repair,
                              registration rentals, replacement vehicles, and end-of-term sales.
                            </span>
                            <span>Contact us today to know more.</span>
                          </div>
                          {/* <a href="" className="rounded-bttn rounded-bttn-transparent mt-3 mb-3" title="   Book Now">
                                       Submi Inquiry
                                    </a> */}
                          <br />
                        </div>
                        <img
                          src={carrental}
                          alt="Dollar Explore"
                          className="img-fluid rounded-xl"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6  col-md-12 col-sm-12 col-12 mt-4 mt-0">
                      <div className="pickup-location-box">
                        <LeasingInquiry />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <OtherVehicleType otherVehicleTypesModel={this.state.otherVehicleTypesModel} onImageClick={this.onImageClick} /> */}

          {/* <section className="py-5 bg-grey">
                    <div className="container">
                    <div className="border-titlec-content mb-5">
                        <h2 className="mb-1">Our Fleet. Your Keys.</h2>
                        <p className="font-18 text-grey">Choose your wheels from Oman’s Largest & Widest Rental Fleet.</p>
                        </div>
                        <div className="width-75 py-5 mx-auto">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-12">
                                    <img src={globalpartner} className="rounded-xl"></img>
                                </div>
                                <div className="col-md-8 col-12  mt-4 mt-md-0">
                                    <div className="oddEven-content pl-md-5 pl-0">
                                        <h3 className="font-24">Experience only the best at your ultimate car rental destination</h3>
                                        <p className="font-18 text-grey pt-3"> At Dollar Car Rental Oman, it is easy to find a rental car that suits your budget and your style. </p>
                                        <p className="font-18 text-grey pt-3"> Dollar
                                          offers a large selection of high-quality, well-maintained, and latest models of rental vehicles
                                          designed to make getting to your destination comfortable and fun. All vehicles in our fleet are
                                          non-smoking, and each year we add more and more fuel-efficient vehicles, which means even
                                          more savings for you. In Oman, Dollar Car Rental offers a wide range of great rental cars that
                                          includes SUVs, sedans, hatchbacks, economy & luxury rentals. </p> 
                                        <p className="font-18 text-grey pt-3">No matter what kind of vehicle fits your needs, Dollar has just what you’re looking for at a great rate, backed by excellent service.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center pt-5">
                                <div className="col-md-8 col-12 order-1 order-md-0 mt-4 mt-md-0">
                                    <div className="oddEven-content pr-md-5 pr-0">
                                        <p className="font-18 text-grey pt-3">Feel free to reach out to us for the season’s latest competitive rates, offers, and deals from Dollar
Car Rental Oman towards a vehicle of your choice and budget. </p>
<p className="font-18 text-grey pt-3">Not sure which vehicle model to go for? Look for the detail indicators against each vehicle listing
to help understand its seating capacity, luggage capacity, engine specs, auto/manual, and more.
Nevertheless, our representatives would be happy to assist you in finding your best pocket-friendly fit.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <img src={awardwinnig}  className="rounded-xl"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

          <section className="bg-white py-5">
            <div className="container">
              <VehicleClassifications />
            </div>
          </section>

          <div className="paddingBottom">
            <VehicleByVehicleTypes vehicleClassificationID="f2a4bbb4-bfd8-4285-bbfc-df5b00567571"></VehicleByVehicleTypes>

            <OtherVehicleType
              otherVehicleTypesModel={this.state.otherVehicleTypesModel}
              onImageClick={this.onImageClick}
            />

            <div className="container-fluid mb-4">
              <div className="text-center">
                <a
                  onClick={this.onClick}
                  className="primary-bttn primary-invert-bttn text-center mt-xl-auto mt-3"
                  title={t('Book Now.1')}
                >
                  {t('Submit Inquiry.1')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showContact}
          onHide={() => this.toogleContactPopup(false)}
          dialogClassName="modal-dialog modal-xl modal-dialog-centered"
        >
          <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
        </Modal>
        {/* <ContactUsBox subHeading ="" heading ="Do you have further Question?"></ContactUsBox> */}
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(
  withTranslation()(withRouter(ExploreCorporateFleet)),
  ExploreCorporateFleet,
);
//export default CorporateFleets;
