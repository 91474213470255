import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import VehicleClassifications from '../Hire/VehicleClassifications';
import { bookingInstance, branchInstance } from '../axios/axiosInstance';

// Brand details object based on Excel data
const brandDetails = {
  audi: {
    h1: 'Audi Rentals in Muscat, OMN',
    title: 'Audi Rent in Muscat | Best Audi Rental Offers in Muscat | Dollar OMN',
    description:
      'Rent an Audi in Muscat for luxury and performance. Enjoy hassle-free booking, best prices, and 24/7 delivery. Experience the thrill of driving an Audi in the OMN.',
  },
  bmw: {
    h1: 'BMW Rental Muscat, OMN',
    title: 'Rent BMW in Muscat | Best Rates and Easy Booking | Dollar OMN',
    description:
      'Rent a BMW in Muscat at best affordable prices with dollaroman. Get best deals and offers for BMW car rental in Muscat, OMN for daily, weekly or monthly rental as per your needs.',
  },
  chevrolet: {
    h1: 'Rent Chevrolet Car in Muscat, OMN',
    title:
      'Chevrolet Car Rental in Muscat | Beast Deals and Offers to rent Chevrolet in Muscat | Dollar OMN',
    description:
      'Rent Chevrolet car in Muscat at cheap affordable prices with dollaroman. Get best deals and offers to hire a Chevrolet car in Muscat and across OMN for daily, weekly or monthly rental as per your needs.',
  },
  dodge: {
    h1: 'Dodge Car Rent in Muscat, OMN',
    title: 'Rent Dodge in Muscat | Hire Dodge Challenger in Muscat | Dollar OMN',
    description:
      'Rent a Dodge in Muscat with dollaroman for an unforgettable driving experience. Enjoy reliable car hire services and explore the city in style and comfort.',
  },
  ford: {
    h1: 'Ford Rental in Muscat, OMN',
    title: 'Rent Ford Car in Muscat | Best Ford Rental Offers in Muscat | Dollar OMN',
    description:
      'Rent a Ford car in Muscat at best affordable prices with dollaroman. Get best deals and offers for Ford rental in Muscat, OMN for daily, weekly or monthly rental as per your needs.',
  },
  gmc: {
    h1: 'Rent GMC Car in Muscat, OMN',
    title: 'GMC Rental in Muscat | Hire a GMC Car in Muscat | Dollar OMN',
    description:
      'Rent a GMC in Muscat at affordable prices with dollaroman. Get best deals and offers to hire GMC cars in Muscat, OMN for daily, weekly or monthly rental as per your needs.',
  },
  honda: {
    h1: 'Honda Rental in Muscat, OMN',
    title: 'Rent Honda Car in Muscat | Best Honda Car Rental in Muscat | Dollar OMN',
    description:
      'Rent a Honda in Muscat at cheap affordable prices with dollaroman. Get best deals and offers for Honda rental in Muscat and across OMN for daily, weekly or monthly rental as per your needs.',
  },
  hyundai: {
    h1: 'Rent Hyundai in Muscat, OMN',
    title: 'Rent Hyundai in Muscat | Best Hyundai Car Rental in Muscat | Dollar OMN',
    description:
      'Rent Hyundai in Muscat at cheap affordable prices at dollaroman. Get best deals and offers for Hyundai rental in Muscat and across OMN for daily, weekly or monthly rental as per your needs.',
  },
  infiniti: {
    h1: 'Infiniti Rental in Muscat, OMN',
    title: 'Rent Infiniti Car in Muscat | Best Infiniti Rental Offers in Muscat | Dollar OMN',
    description:
      'Rent a Infiniti in Muscat at cheap affordable prices with dollaroman. Get best deals and offers for Infiniti rental in Muscat, OMN for daily, weekly or monthly rental as per your needs.',
  },
  jeep: {
    h1: 'Jeep Rental Muscat, OMN',
    title: 'Rent Jeep in Muscat | Jeep Wrangler Rental in Muscat | Dollar OMN',
    description:
      'Rent a Jeep in Muscat at cheap affordable prices with dollaroman. Get best deals and offers for Jeep Wrangler rental in Muscat, OMN for daily, weekly or monthly rental as per your needs.',
  },
  mazda: {
    h1: 'Mazda Car Rental Muscat, OMN',
    title: 'Rent Mazda in Muscat | Best Rates and Affordable Prices | Dollar OMN',
    description:
      'Looking for Mazda rental in Muscat? Hire your car from dollaroman for the best deals, seamless booking, and reliable service in the OMN.',
  },
  mercedes: {
    h1: 'Mercedes Car Rental Muscat, OMN',
    title: 'Rent Mercedes in Muscat | Beast Deals and Offers | Dollar OMN',
    description:
      'Rent a Mercedes in Muscat with Dollar Oman for unmatched luxury. Enjoy top-tier car hire services and affordable rental rates for a seamless driving experience.',
  },
  mg: {
    h1: 'Rent MG Car in Muscat, OMN',
    title: 'MG Car Rental in Muscat | Flexible and Affordable Prices | Dollar OMN',
    description:
      'Rent an MG car in Muscat with Dollar Oman for unbeatable prices. Hire your ideal car rental today and enjoy a smooth driving experience in the OMN',
  },
  mini: {
    h1: 'Rent Mini Cooper in Muscat, OMN',
    title: 'Mini Cooper Rental in Muscat | Flexible and Affordable Rates | Dollar OMN',
    description:
      'Experience the thrill of a Mini Cooper rental in Muscat with Dollar Oman. Hire your perfect car for the city and enjoy affordable rates with seamless booking.',
  },
  mitsubishi: {
    h1: 'Rent a Mitsubishi in Muscat, OMN',
    title: 'Mitsubishi Rental in Muscat | Best Rates Guaranteed! at Dollar OMN',
    description:
      'Experience luxury with Mitsubishi rental in Muscat. Hire premium cars with Dollar Oman for affordable rates and seamless booking for your next adventure.',
  },
  nissan: {
    h1: 'Nissan Car Rental in Muscat, OMN',
    title: 'Rent Nissan in Muscat | Best Affordable Prices | Dollar OMN',
    description:
      'For reliable Nissan rental in Muscat, hire from Dollar Oman. Experience top-notch service and affordable rates for your car rental needs in the OMN',
  },
  peugeot: {
    h1: 'Rent a Peugeot Car in Muscat, OMN',
    title: 'Peugeot Car Rental in Muscat | Top Car Hire Deals with Dollar OMN',
    description:
      'Rent a Peugeot in Muscat with Dollar Oman for a smooth driving experience. Enjoy affordable hire and rental options with easy booking and reliable service.',
  },
  polestar: {
    h1: 'Rent Polestar in Muscat, OMN',
    title: 'Rent Polestar in Muscat | Best Rates Affordable Prices | Dollar OMN',
    description:
      'Rent a Polestar in Muscat with dollaroman for a premium experience. Hire your electric car today with affordable rental rates and seamless booking options.',
  },
  renault: {
    h1: 'Renault Car Rental in Muscat, OMN',
    title: 'Rent a Renault Car in Muscat | Best Rental Offers | Dollar OMN',
    description:
      'Rent a Renault in Muscat for a smooth ride with dollaroman. Choose the best rental and hire deals with affordable rates and convenient booking options.',
  },
  suzuki: {
    h1: 'Rent a Suzuki Car in Muscat, OMN',
    title: 'Rent Suzuki Car in Muscat | Best Deals and Offers | Dollar OMN',
    description:
      'Experience Suzuki rental in Muscat with Dollar Oman. Hire your ideal Suzuki for comfort and convenience. Explore the city with affordable rates today!',
  },
  toyota: {
    h1: 'Toyota Rental in Muscat, OMN',
    title: 'Rent Toyota in Muscat | Flexible and Affordable Prices | Dollar OMN',
    description:
      'Experience convenience with Toyota rental in Muscat. Hire from Dollar Oman for reliable vehicles and great deals to explore the OMN hassle-free.',
  },
  volkswagen: {
    h1: 'Volkswagen Rental in Muscat, OMN',
    title: 'Rent Volkswagen in Muscat | Best Rates Guaranteed | Dollar OMN',
    description:
      'Volkswagen rental in Muscat offers a range of stylish and reliable vehicles to suit your needs. With competitive rates and excellent service, your journey in the OMN starts here with Dollar Oman.',
  },
  volvo: {
    h1: 'Rent a Volvo Car in Muscat, OMN',
    title: 'Volvo Car Rental in Muscat | Flexible and Affordable Prices | Dollar OMN',
    description:
      'Rent a Volvo in Muscat with Dollar Oman for a premium driving experience. Hire your perfect vehicle today and enjoy seamless rental services across the city!',
  },
};

class CarBrands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicalModels: [],
      PickUpDate: Date().toLocaleString(),
      ReturnDate: Date().toLocaleString(),
      BranchId: '',
      h1: 'Audi Rentals in Muscat, OMN',
      title: 'Audi Rent in Muscat | Best Audi Rental Offers in Muscat | Dollar OMN',
      description:
        'Rent an Audi in Muscat for luxury and performance. Enjoy hassle-free booking, best prices, and 24/7 delivery. Experience the thrill of driving an Audi in the OMN.',
      iPAddress: '127.0.0.1',
      customerCountryCode: 'OMN',
      customerCountryName: 'United_Arab_Emirate',
    };
  }

  componentDidMount() {
    this.getIPAddress();
    this.getAllBranches();
    scroll.scrollToTop();

    const { pathname } = this.props.location;
    const brandSegment = pathname.split('/').pop();
    const brand = brandSegment.split('-')[0] ?? 'audi';

    if (brandDetails[brand]) {
      const { h1, title, description } = brandDetails[brand];
      this.setState({ h1, title, description });
    }

    setTimeout(() => {
      this.getVehicalModels(brand); // Pass brand to fetch vehicle models
    }, 1000);
  }

  getIPAddress = () => {
    axios
      .get('https://geolocation-db.com/json/')
      .then((res) => {
        this.setState({
          iPAddress: res.data.IPv4,
          customerCountryCode: res.data.country_code,
          customerCountryName: res.data.country_name,
        });
      })
      .catch((error) => {});
  };

  getAllBranches = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance
      .get(`all?countryCode=${code}`)
      .then((response) => {
        const allBranches = response.data.result;
        if (allBranches.length > 0) {
          this.setState({
            BranchId: allBranches[0]?._id,
          });
          this.getHoursToReady(allBranches[0]?._id);
        }
      })
      .catch((error) => {});
  };

  getHoursToReady = (timeId) => {
    const isDeliver = this.state?.Address?.length > 0 || false;
    branchInstance
      .get(`/hrsToGetReady?branchId=${timeId}&isDeliverToMe=${isDeliver}`)
      .then((response) => {
        const timeReady = response.data.result;
        let pickupDate = moment
          .tz(timeReady.PickupOn, 'Asia/Muscat')
          .format('MM/DD/YYYY  h:mm:ss A');
        let returnDate = moment
          .tz(timeReady.ReturnOn, 'Asia/Muscat')
          .format('MM/DD/YYYY  h:mm:ss A');
        this.setState({ PickUpDate: pickupDate, ReturnDate: returnDate });
      })
      .catch((error) => {});
  };

  getVehicalModels = (brand) => {
    let searchParam = {
      Classifications: [brand], // Use the brand dynamically in the API request
    };

    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`classificationReservation?countryCode=${countryCode}`, searchParam)
      .then((response) => {
        const { data } = response.data.result;
        this.setState({ vehicalModels: data });
      })
      .catch((error) => {});
  };

  onBookNowHandler = () => {
    const pickupDate = moment(this.state.PickUpDate).format('DD MMM YYYY, h:mm A');
    const returnDate = moment(this.state.ReturnDate).format('DD MMM YYYY, h:mm A');

    const BookingInfo = [
      {
        CheckOutLocationId: this.state.BranchId,
        CheckInLocationId: this.state.BranchId,
        PickUpDate: pickupDate,
        ReturnDate: returnDate,
        IPLocation: {
          IPAddress: this.state.iPAddress,
          Country_code: this.state.customerCountryCode,
          Country_name: this.state.customerCountryName,
        },
      },
    ];

    localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
    window.location = '/booking';
  };

  render() {
    const { t } = this.props;
    const { h1, title, description } = this.state; // Extract dynamic values

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row  flex-reversea align-items-center">
                    <div className="col-lg-4">
                      <div className="pickup-location-box pr-0">
                        <h5 className="font-bold mb-3 text-red">{h1}</h5>
                        <p>{title}</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content ">
                            <p className="text_p text-black"> {description} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="offer-box-section pb-4 grey-bg">
            <div className="container-fluid">
              <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                {this.state.vehicalModels.map((model, index) => {
                  return (
                    <div key={index} className="vehicle-box-design">
                      <h3>{model.Name}</h3>
                      <span className="similar-text">{t('or Similar | Dollar Prestige.1')}</span>
                      <div className="vehicle-box-design-img-wrap">
                        <img
                          src={model.VehicleImages[0]}
                          alt={model.Name}
                          className="vehicle-image-main"
                        />
                      </div>

                      <div className="prestige-car-box-bttn text-center w-100">
                        <a
                          className="vehicle-box-design-book"
                          title="Book Now"
                          onClick={this.onBookNowHandler}
                        >
                          {' '}
                          {t('Book Now.1')}{' '}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <VehicleClassifications />
        </div>
        <br />
        <ListingFooter />{' '}
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(CarBrands)), CarBrands);
