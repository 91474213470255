import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import RentalGuideImge from '../assets/images/Business/1981493672.jfif';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';

class RentalGuide extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader />
        <div>
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">Rental Guide</h2>
                            <p>
                              Your Quick Guide to getting a Hassle-free Car Rental
                              <br />
                              Experience in Oman.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={RentalGuideImge}
                        className="img-fluid rounded-xl"
                        alt={'Dollar Rental Guide'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <h5 className="font-weight-bold">Minimum Age Limit</h5>
                    <p className="text_p mt-4">
                      Dollar Car Rental Oman requires one to be of 22 years old and above for all
                      our car rental plans within the country.
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Reservation Methods</h5>
                    <p className="text_p mt-4">
                      You may make your reservations online at www.dollaroman.com, call our 24x7
                      sales representatives at 800 70011, visit any of our counters at 6+ locations
                      across Oman or email us at{' '}
                      <a
                        href="mailto:reservations@dollaroman.com"
                        className="footer-mobile footer-email"
                        title="Mail"
                      >
                        reservations@dollaroman.com
                      </a>
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Security Deposits</h5>
                    <p className="text_p mt-4">Valid Credit Card with minimum 3 months validity.</p>
                    <br />
                    <h5 className="font-weight-bold">Mode of Payment</h5>
                    <p className="text_p mt-4">
                      Dollar Car Rental Oman accepts all major Credit Cards (MASTER, VISA, JCB,
                      DINERS and AMERICAN EXPRESS). Credit card must be valid for a minimum of 3
                      months from the day of rental and must be physically available at the time of
                      vehicle collection.
                    </p>
                    <br />
                    <h5 className="font-weight-bold">Vehicle Inspection &amp; Add-ons</h5>
                    <p className="text_p mt-4">
                      Once you’ve ensured the vehicle quality is up to your satisfaction, you may
                      proceed to sign the rental contract and complete the payment process. You may
                      also add on amenities like baby/ toddler rear seats, GPS trackers, Mobile
                      WIFI, etc. at this stage.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <div className="p-3 bg_custom mr-6">
                      <h5 className="font-weight-bold">Valid Documents Required</h5>
                      <br />
                      <ul className="round-circle-listing">
                        <li>
                          <span>
                            For Local Residents.
                            <br />
                            <p className="text_p mt-2">
                              • Oman Driving License Copy (Front &amp; Back)
                              <br />
                              • Passport Copy
                              <br />• Visa Copy
                            </p>
                          </span>
                        </li>
                        <li className="mt-3">
                          <span>
                            For Tourists (International).
                            <br />
                            <p className="text_p mt-2">
                              • International Driving License Copy
                              <br />
                              • Passport Copy
                              <br />
                              • Visiting Visa Copy
                              <br />
                            </p>
                          </span>
                        </li>
                        <li className="mt-3">
                          <span>
                            For Tourists (GCC Countries).
                            <br />
                            <p className="text_p mt-2">
                              • Valid GCC Driving License Copy
                              <br />
                              • Passport Copy
                              <br />• Visiting Visa Copy
                            </p>
                          </span>
                        </li>
                        <li className="mt-3">
                          <span>
                            International or National Driving license in any other language other
                            than Arabic and English should be translated into Arabic or English. The
                            translation should be attested by the Embassy.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(RentalGuide);
