import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import padlock from '../assets/images/padlock1.svg';
import usericon from '../assets/images/user-icon3.svg';
import { userInstance } from '../axios/axiosInstance';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import { getLanguage } from '../Common/Util';

class LoginPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      email: '',
      showSignupAndForgetPassword: true,
      errorMessage: '',
      loading: false,
    };
  }

  componentDidMount = () => {};

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSignUpNow = () => {
    this.props.history.push('/register');
  };

  loginClickHandler = () => {
    if (this.validate()) {
      userInstance
        .post('login', {
          email: this.state.emailId,
          password: this.state.password,
        })
        .then((response) => {
          if (
            response.data.result.access_token != null ||
            response.data.result.access_token != undefined
          ) {
            localStorage.setItem('userinfo', JSON.stringify(response.data.result));
            this.setState({
              loading: false,
            });
            window.location = '/profile';
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.statusCode == 401) {
              this.setState({ errorMessage: error.response.data.message });
            } else {
              this.setState({ errorMessage: 'Error while login' });
            }
          } else {
            this.setState({ errorMessage: 'Error while login' });
          }
          this.setState({
            loading: false,
          });
        });
    }
  };

  forgetPasswordClickHandler = () => {
    if (this.validateForgotPassword()) {
      userInstance
        .post('resetPassword', { emailId: this.state.email })
        .then((response) => {
          if (response.data.status == 'success') {
            localStorage.setItem('thanksmessage', response.data.result);
            this.props.history.push('/thankyou');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.statusCode == 404) {
              this.setState({ errorMessage: error.response.data.message });
            } else {
              this.setState({ errorMessage: 'Error while forgot password' });
            }
          } else {
            this.setState({ errorMessage: 'Error while forgot password' });
          }
        });
    }
  };

  operation(value) {
    this.setState({
      errorMessage: '',
    });
    this.setState({
      showSignupAndForgetPassword: !value,
    });
  }

  validate = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });

    let valid = true;
    if (this.state.emailId.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The emailId field is required.',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل اسم المستخدم',
        });
      }

      return false;
    }
    if (this.state.password.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The password field is required.',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل كلمة المرور',
        });
      }
      if (!validator.isEmail(this.state.email)) {
        if (lngParam == 'en') {
          this.setState({
            errorMessage: 'The email field is invalid.',
          });
        } else {
          //ar
          this.setState({
            errorMessage: 'مطلوب حقل البريد الإلكتروني',
          });
        }
        return false;
      }

      return false;
    }
    return valid;
  };

  validateForgotPassword = () => {
    const language = localStorage.getItem('i18nextLng');
    let lngParam = language == 'UAE' ? 'ar' : 'en';
    let valid = true;
    this.setState({
      errorMessage: '',
    });

    if (this.state.email.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          errorMessage: 'The email field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب حقل البريد الإلكتروني',
        });
      }

      return false;
    }

    return valid;
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div className=" login-container" id="LoginFormPopup">
          <div className="row  login-first-row">
            {this.state.showSignupAndForgetPassword ? (
              <div className="col-12 login-card">
                <div className="upgrade-option-modal-content">
                  {this.props.showHeader ? (
                    <Modal.Header closeButton className="p-0 border-0">
                      <h2 class="border-botom">{t('Log In.1')}</h2>
                    </Modal.Header>
                  ) : null}

                  <div className="row pt-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputemailId">
                          <h6 class="font-weight-bold mb-0 text-black">{t('Email Address.1')}</h6>
                        </label>
                        <div className="position-relative">
                          <img src={usericon} className="img-size" alt="Dollar User" />
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputemailId"
                            aria-describedby="emailHelp"
                            name="emailId"
                            placeholder={t('Email Address.1')}
                            onChange={this.onChange}
                            value={this.state.emailId}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword">
                          <h6 class="font-weight-bold mb-0 text-black">{t('Password.1')}</h6>
                        </label>
                        <div className="position-relative">
                          <img src={padlock} className="img-size" alt="Dollar User" />
                          <input
                            type="password"
                            className="form-control tiny"
                            id="exampleInputPassword"
                            aria-describedby="emailHelp"
                            placeholder={t('Enter Password.1')}
                            name="password"
                            onChange={this.onChange}
                            value={this.state.password}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <a
                      className="forgot-pass-text mt-0"
                      onClick={() => {
                        this.operation(true);
                      }}
                    >
                      {t('Forgot Password.1')}?
                    </a>
                  </div>
                  <div className="text-right mt-3">
                    {this.state.loading == false ? (
                      <button
                        type="submit"
                        className="book-vehicle-name-btn mw-100"
                        onClick={this.loginClickHandler}
                      >
                        {t('Login.1')}
                      </button>
                    ) : null}
                  </div>

                  <br></br>
                  {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                    <ErrorAlert errorMessage={this.state.errorMessage} />
                  ) : null}

                  {this.state.loading == true ? (
                    <div style={{ verticalAlign: 'center' }}>
                      <Spinner animation="border" />
                      &nbsp; {t('Logging in.1')}
                    </div>
                  ) : null}

                  <br></br>
                  {/* <div className="row">

                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 span-notreg">
                        <span className="or-login-with-text">{t('Or login with.1')}</span>
                      </div>

                    </div>

                    <div className="row socialbtn-row ">
                      <div className="col-6 col-lg-16 col-md-6 col-sm-6">
                        <div className="socialbtn fb-btn-login">
                          <img src={FaceBook} alt="" />
                          <a href="#">FaceBook</a>

                        </div>
                      </div>

                      <div className="col-6 col-lg-16 col-md-6 col-sm-6">
                        <div className="socialbtn gl-btn-login">
                          <img src={Google} alt="" />
                          <a href="#">{t('Google.1')}</a>
                        </div>
                      </div>

                    </div><br></br> */}

                  <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 span-notreg">
                      <span className="spanstyle-subtext-login">
                        {t('Not registered yet.1')}?{' '}
                        <a style={{ color: '#C8102E' }} onClick={this.onSignUpNow}>
                          {t('Sign Up Now.1')}
                        </a>{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 login-card">
                <div className="upgrade-option-modal-content">
                  {this.props.showHeader ? (
                    <Modal.Header className="p-0 border-0" closeButton>
                      <h2 className="border-botom">{t('Forgot Password.1')}</h2>
                    </Modal.Header>
                  ) : null}

                  <div className="form-group">
                    <label>
                      <h6 class="font-weight-bold mb-0 text-black">{t('Email.1')}</h6>
                    </label>
                    <input
                      type="text"
                      className="email-icon  form-control"
                      id="exampleInputemailId"
                      aria-describedby="emailHelp"
                      name="email"
                      placeholder={t('Enter Email.1')}
                      onChange={this.onChange}
                      value={this.state.email}
                    />
                  </div>
                  <button
                    type="submit"
                    className="book-vehicle-name-btn mw-100"
                    onClick={this.forgetPasswordClickHandler}
                  >
                    {t('Send Recovery  Link.1')}
                  </button>
                  <div className="text-center">
                    {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                      <ErrorAlert errorMessage={this.state.errorMessage} />
                    ) : null}

                    {this.state.loading == true ? (
                      <div style={{ verticalAlign: 'center' }}>
                        <Spinner animation="border" />
                        &nbsp; {t('Logging in.1')}
                      </div>
                    ) : null}
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                      className="forgot-pass-text"
                      onClick={() => {
                        this.operation(false);
                      }}
                    >
                      {t('Sign In.1')}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(LoginPopup)), LoginPopup);
